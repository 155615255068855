import ArchivedOcurrences from '../../../../f5/ocurrences/ArchivedOcurrences';
import React from 'react';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';
import OcurrenceNavbar from '../../../../f5/ocurrences/SharedComponents/OcurrenceNavbar';

const Archived = () => {
  return (
    <PageContainer>
      <OcurrenceNavbar />
      <ArchivedOcurrences />
    </PageContainer>
  );
};

export default Archived;
