import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import axios_instance, { appURL } from '../../../../services/auth/jwt/config';

export default function AnexoImage({ id, mode, local, ...props }) {
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let token = localStorage.getItem('token');
        fetch(`${appURL}/api/agendamento/${mode}/${local}/anexo/agenda/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }).then(res => {
            if (res.status === 200) {
                res.blob().then(blob => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = function () {
                        const base64data = reader.result;
                        setImage(base64data);
                        console.log(base64data)
                        setLoading(false);
                    };
                });
            } else {
                setLoading(false);
            }
        });
    }, [id, mode, local]);

    return (
        <div>
            {loading ? (
                <CircularProgress />
            ) : image ? (
                <img
                    src={image}
                    alt="Anexo"
                    style={{ maxHeight: 100, maxWidth: 100 }}
                    onClick={() => {
                        window.open(image, '_blank');
                    }}
                />
            ) : (
                <p>Nenhum anexo</p>
            )}
        </div>
    );
}
