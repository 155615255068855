import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#ed213d', '#b070d6'];

export default function PieAndTable({ data, name = '', title = '', ...props }) {
  const [empty, setEmpty] = React.useState(false);

  useEffect(() => {
    if (data.length === 0) {
      setEmpty(true);
    }
    let total = data.reduce((partial, item) => partial + item.total, 0);
    if (total === 0) {
      setEmpty(true);
    }
  }, [data]);

  let graph = data;
  if (empty) {
    graph = [{ nome: 'Sem resultados', total: 1, color: 'grey', hide: true }];
  }

  return (
    <Box mt={2}>
      <h3>{title}</h3>
      <Grid container spacing={1} alignItems="center" justifyContent="center">
        <Grid item xs={6}>
          <ResponsiveContainer width="100%" height={200}>
            <PieChart width={200} height={200}>
              <Tooltip />
              <Legend verticalAlign="center" align="right" layout="vertical" />
              <Pie
                data={graph}
                cx="50%"
                cy="50%"
                nameKey={'nome'}
                valueKey={'total'}
                labelLine={false}
                outerRadius={100}
                fill="#8884d8"
                dataKey="total">
                {graph?.map((entry, index) => {
                  if (entry.color) {
                    return <Cell key={`cell-${index}`} fill={entry.color} />;
                  }
                  return <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />;
                })}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Grid>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{name}</TableCell>
                  <TableCell align="right">Quantidade</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(row => {
                  if (row.hide) {
                    return null;
                  }
                  return (
                    <TableRow key={row.nome}>
                      <TableCell component="th" scope="row">
                        {row.nome}
                      </TableCell>
                      <TableCell align="right">{row.total}</TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCell component="th" scope="row">
                    <b>Total</b>
                  </TableCell>
                  <TableCell align="right">
                    <b>
                      {data.reduce((partial, item) => {
                        if (item.hide) {
                          return partial;
                        }
                        return partial + item.total;
                      }, 0)}
                    </b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}
