export const shortName = name => {
    let formatedName;
    const nameSlices = name.split(' ');

    if (nameSlices.length <= 1) {
        return name;
    }

    if (nameSlices[0] === 'Sr.' || nameSlices[0] === 'Srta.') {
        formatedName =
            nameSlices.length >= 3
                ? `${nameSlices[0]} ${nameSlices[1]} ${nameSlices[2].charAt(0)}`
                : `${nameSlices[0]} ${nameSlices[1]}`;
    } else {
        formatedName = `${nameSlices[0]} ${nameSlices[1].charAt(0)}`;
    }
    return formatedName;
};

function isValidDate(date) {
    return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
}

export const differenceInDays = (dateBefore, dateAfter) => {
    try {
        if (isValidDate(dateBefore) && isValidDate(dateAfter)) {
            let difference_In_Time = dateAfter.getTime() - dateBefore.getTime();
            let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
            return difference_In_Days;
        } else {
            throw new Error('Invalid date format.');
        }
    } catch (error) {
        return error.message;
    }
};

export const isValidCPF = cpf => {
    if (typeof cpf !== 'string') return false;
    if (!cpf) return true;
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
    cpf = cpf.split('');
    const validator = cpf.filter((digit, index, array) => index >= array.length - 2 && digit).map(el => +el);
    const toValidate = pop => cpf.filter((digit, index, array) => index < array.length - pop && digit).map(el => +el);
    const rest = (count, pop) => ((toValidate(pop).reduce((soma, el, i) => soma + el * (count - i), 0) * 10) % 11) % 10;
    return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1]);
};

export const isValidCNPJ = value => {
    if (!value) return false;
    const isString = typeof value === 'string';
    const validTypes = isString || Number.isInteger(value) || Array.isArray(value);
    if (!validTypes) return false;
    if (isString) {
        if (value.length > 18) return false;
        const digitsOnly = /^\d{14}$/.test(value);
        const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value);
        // eslint-disable-next-line no-unused-expressions
        if (digitsOnly || validFormat) true;
        else return false;
    }
    const match = value.toString().match(/\d/g);
    const numbers = Array.isArray(match) ? match.map(Number) : [];
    if (numbers.length !== 14) return false;
    const items = [...new Set(numbers)];
    if (items.length === 1) return false;
    const calc = x => {
        const slice = numbers.slice(0, x);
        let factor = x - 7;
        let sum = 0;
        for (let i = x; i >= 1; i--) {
            const n = slice[x - i];
            sum += n * factor--;
            if (factor < 2) factor = 9;
        }
        const result = 11 - (sum % 11);
        return result > 9 ? 0 : result;
    };
    const digits = numbers.slice(12);
    const digit0 = calc(12);
    if (digit0 !== digits[0]) return false;
    const digit1 = calc(13);
    return digit1 === digits[1];
};
