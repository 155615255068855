import React, { useState } from 'react';
import { Box, InputBase, Divider, IconButton, Button, CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { selectOcurrence } from '../../../../redux/actions/f5-actions/ocurrences-actions';
import { makeStyles } from '@material-ui/core/styles';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import SearchIcon from '@material-ui/icons/Search';
import ocurrencesActionService from '../../../../services/f5-services/ocurrencesActionService';

import sweetAlert from '../../../../f5/utils/sweetAlert';
import SharedTitle from './SharedTitle';
import SharedDescription from './SharedDescription';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  inputFrame: {
    border: '1px solid rgba(0, 0, 0, 0.25)',
    borderRadius: '1rem',
    padding: theme.spacing(0, 2),
  },
  buttonRoot: {
    color: 'white',
    backgroundColor: '#1654d9',
    '&:hover': {
      backgroundColor: '#3864f5',
    },
  },
}));

const GroupOcurrence = ({ ocurrence }) => {
  const [loading, setLoading] = useState({ message: '', status: false });
  const [ocurrenceReference, setOcurrenceReference] = useState(null);
  const [protocol, setProtocol] = useState('');
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleSearch = async () => {
    const postData = { modo: 'protocol', valor: protocol };
    const response = await ocurrencesActionService.getOcurrenceByProtocol(postData);
    setOcurrenceReference({
      id: response.ocorrencias[0].ocorrencia_id,
      protocol: response.ocorrencias[0].protocolo,
      date: new Date(response.ocorrencias[0].inicio).toString(),
      applicant: response.ocorrencias[0].solicitante.nome,
      service: response.ocorrencias[0].servico,
      address: response.ocorrencias[0].endereco,
    });
  };

  const handleSubmit = async () => {
    setLoading({ status: true, message: 'Agrupando...' });

    const postData = {
      ouv__principal_id: ocurrence.ocorrencia_id,
      ouv__vinculada_id: ocurrenceReference.id,
    };

    await ocurrencesActionService.sendOcurrenceGroup(postData);

    dispatch(selectOcurrence(ocurrence.ocorrencia_id));
    setLoading({ status: false, message: '' });
    sweetAlert('success', 'Sucesso', 'Ocorrência agrupada com sucesso!');
  };

  const toggleOcurrenceView = () => {
    return ocurrenceReference === null ? (
      <Box>
        <Box fontSize={12} fontWeight={700} mb={1}>
          Busque pela ocorrência que deseja vincular.
        </Box>
        <Box fontSize={12} fontWeight={500} mb={4} fontStyle="italic">
          A Ocorrência atual será considerada como a principal.
        </Box>
      </Box>
    ) : (
      <Box display="flex" flexDirection="column">
        <Box display="flex" mb={1}>
          <Box fontWeight={700}>Protocolo:</Box>
          <Box ml={2} fontWeight={500}>
            {ocurrenceReference.protocol}
          </Box>
        </Box>
        <Box display="flex" mb={1}>
          <Box fontWeight={700}>Data:</Box>
          <Box ml={2} fontWeight={500}>
            {ocurrenceReference.date}
          </Box>
        </Box>
        <Box display="flex" mb={1}>
          <Box fontWeight={700}>Solicitante:</Box>
          <Box ml={2} fontWeight={500}>
            {ocurrenceReference.applicant}
          </Box>
        </Box>
        <Box display="flex" mb={1}>
          <Box fontWeight={700}>Serviço:</Box>
          <Box ml={2} fontWeight={500}>
            {ocurrenceReference.service}
          </Box>
        </Box>
        <Box display="flex" mb={1}>
          <Box fontWeight={700}>Endereço:</Box>
          <Box ml={2} fontWeight={500}>
            {ocurrenceReference.address}
          </Box>
        </Box>
        <Box display="flex" my={2}>
          <Button classes={{ root: classes.buttonRoot }} onClick={handleSubmit}>
            Vincular Ocorrência
          </Button>
        </Box>
      </Box>
    );
  };

  if (loading.status) {
    return (
      <Box
        className={classes.root}
        height={500}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center">
        <CircularProgress />
        <Box mt={4} fontWeight={700}>
          {loading.message}
        </Box>
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <SharedTitle icon={<HorizontalSplitIcon />} title="Agrupar Ocorrência" />
      <SharedDescription
        requester={ocurrence.solicitante.nome}
        date={`${new Date(ocurrence.abertura).toLocaleDateString()} às ${new Date(ocurrence.abertura).toLocaleTimeString()}`}
        service={ocurrence.servico}
        protocol={ocurrence.protocolo}
      />

      <Box width="100%">
        {toggleOcurrenceView()}
        <Box className={classes.inputFrame} mt={4} display="flex" alignItems="center" width="100%">
          <InputBase
            fullWidth
            placeholder="Procurar Protocolo"
            inputProps={{ 'aria-label': 'busca por protocolo' }}
            value={protocol}
            onChange={e => setProtocol(e.target.value)}
          />
          <Divider flexItem orientation="vertical" variant="middle" />
          <IconButton color="primary" aria-label="pesquisar" onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        </Box>
      </Box>
    </div>
  );
};

export default GroupOcurrence;
