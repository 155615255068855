import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import { setDepartmentOmbudsman } from '../../redux/actions/f5-actions/department-ombudsman';

import CustomCard from './CustomCard';

const colors = {
    156: 'rgba(28, 47, 156, 0.85)',
    ouv_geral: 'rgba(156, 137, 28, 0.85)',
    ouv_saude: 'rgba(43, 156, 28, 0.85)',
    ouv_guarda: 'rgba(156, 26, 44, 0.85)',
};

const ChooseSector = ({ sectors }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleClick = office => {
        dispatch(setDepartmentOmbudsman(office));
        history.push('/ocorrencias');
    };

    return (
        <Grid container>
            {sectors ? (
                sectors.map(item => (
                    <Grid item xs={12} md={6} key={item.id}>
                        <CustomCard
                            color={colors[item.interno]}
                            title={item.nome}
                            count={item.count}
                            handleClick={() => handleClick(item.interno)}
                            data={{
                                pending: item.pendente,
                                solved: item.solucionada,
                                ontime: item.dentroPrazo,
                                due: item.aVencer,
                                overdue: item.vencidas,
                            }}
                        />
                    </Grid>
                ))
            ) : (
                <React.Fragment />
            )}
        </Grid>
    );
};

export default ChooseSector;
