import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
    Grid,
    Button,
    CircularProgress,
    Radio,
    FormControlLabel,
    FormLabel,
    FormControl,
    RadioGroup,
    Select,
    InputLabel,
    MenuItem,
} from '@material-ui/core';
import axios_instance from '../../../../services/auth/jwt/config';

export default function FindHorario({ dia, hora, updateData, servico, ...props }) {
    const [horas, setHoras] = useState([]);
    const [loading, setLoading] = useState(false);
    const [locais, setLocais] = useState([]);
    const [finalLocal, setFinalLocal] = useState(0);
    const [servicos, setServicos] = useState([]);
    const [base, setBase] = useState({ mode: '', local: '' });

    const { mode, local } = useParams();

    useEffect(() => {
        setBase({ mode: mode, local: local });
    }, [mode, local]);

    useEffect(() => {
        if (base?.mode && base?.local) {
            setLoading(true);
            axios_instance.get(`api/agendamento/${base.mode}/${base.local}/servicos/list`).then(res => {
                let r = res.data;
                if (r.success) {
                    setServicos(r.items);
                    setLocais(r?.locais);
                    setFinalLocal(r?.local);
                }
                setLoading(false);
            });
        }
    }, [base]);

    useEffect(() => {
        if (finalLocal) {
            setLoading(true);
            axios_instance.get(`api/agendamento/local/${finalLocal}/servicos/list`).then(res => {
                let r = res.data;
                if (r.success) {
                    setServicos(r.items);
                }
                setLoading(false);
            });
        }
    }, [finalLocal]);

    useEffect(() => {
        if (dia !== null && !isNaN(dia.valueOf()) && finalLocal) {
            setLoading(true);
            if (moment(dia).isSameOrBefore(moment().subtract(1, 'day'))) {
                setHoras([]);
                setLoading(false);
            } else {
                let diaSelecionado = moment(dia);
                axios_instance
                    .get(
                        `api/agendamento/local/${finalLocal}/servicos/horario?dia=${diaSelecionado.format(
                            'YYYY-MM-DD',
                        )}&servico=${servico}`,
                    )
                    .then(res => {
                        let r = res.data;
                        if (r.success) {
                            let hold = r?.disponibilidade?.map(item => {
                                return item;
                            });
                            setHoras(hold);
                        }
                        setLoading(false);
                    });
            }
        }
    }, [dia, servico, finalLocal]);

    useEffect(() => {
        if (moment(dia).isSameOrBefore(moment().subtract(1, 'day'))) {
            setHoras([]);
            setLoading(false);
        } else {
            if (finalLocal) {
                let diaSelecionado = moment(dia);
                setLoading(true);
                axios_instance
                    .get(`api/agendamento/local/${finalLocal}/servicos/horario?dia=${diaSelecionado.format('YYYY-MM-DD')}`)
                    .then(res => {
                        let r = res.data;
                        if (r.success) {
                            let hold = r?.disponibilidade?.map(item => {
                                return item;
                            });
                            setHoras(hold);
                        }
                        setLoading(false);
                    });
            }
        }
    }, [dia, finalLocal]);

    return (
        <div>
            {locais.length > 0 ? (
                <FormControl fullWidth margin="normal">
                    <InputLabel>Local</InputLabel>
                    <Select value={finalLocal} onChange={e => setFinalLocal(e.target.value)}>
                        {locais.map(item => (
                            <MenuItem value={item.id}>{item.nome}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : null}
            <FormControl component="fieldset">
                <FormLabel component="legend">Tipo de Serviço</FormLabel>
                {servicos.length > 0 ? (
                    <RadioGroup aria-label="tipo doação" value={servico} onChange={e => updateData(servico, e.target.value)}>
                        {servicos.map(item => (
                            <FormControlLabel
                                value={item.id}
                                control={<Radio checked={servico === item.id} />}
                                label={item.nome}
                            />
                        ))}
                    </RadioGroup>
                ) : (
                    <div>
                        <b>Não há servições no local selecionado</b>
                    </div>
                )}
            </FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                <KeyboardDatePicker
                    margin="normal"
                    fullWidth
                    value={dia}
                    minDate={moment()}
                    emptyLabel="É preciso inserir uma data"
                    invalidDateMessage="A data é inválida"
                    minDateMessage={'A data tem que ser no futuro'}
                    label="Data do agendamento"
                    format="dd/MM/yyyy"
                    onChange={e => updateData(dia, e)}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>

            <div>
                <h3 className="card-title">Horários Disponíveis</h3>
                {loading ? (
                    <div className="text-center">
                        <CircularProgress />
                    </div>
                ) : horas.length > 0 ? (
                    <Grid container spacing={2}>
                        {horas.map(item => {
                            let disable = false;
                            if (item.disabled) {
                                disable = true;
                            }
                            return (
                                <Grid item key={item.id}>
                                    <Button
                                        variant={item.id === hora.id ? 'contained' : 'outlined'}
                                        disabled={disable}
                                        onClick={() => updateData(hora, item)}>
                                        {`${item?.grupo ?? ''}\n`}
                                        {moment(item.comeco, 'HH:mm:ss').format('HH:mm')} às{' '}
                                        {moment(item.fim, 'HH:mm:ss').format('HH:mm')}
                                    </Button>
                                </Grid>
                            );
                        })}
                    </Grid>
                ) : (
                    <h3 className="text-center">Não há horários no dia escolhido, selecione outro dia.</h3>
                )}
            </div>
        </div>
    );
}
