import React from 'react';
import { Paper, Box, Divider, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import ForumIcon from '@material-ui/icons/Forum';

const useStyles = makeStyles(theme => ({
  paperRoot: { height: '100%' },
  cardRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  textGrey: {
    color: 'rgba(0, 0, 0, 0.5)',
  },
}));

const ComunicationCard = ({ messages }) => {
  const classes = useStyles();
  const headers = ['Mensagem enviada', 'Data/Hora', 'Resposta'];
  if (messages.length === 0) return null;
  return (
    <Paper className={classes.paperRoot}>
      <Box className={classes.cardRoot} py={2} px={4}>
        <Box>
          <Box display="flex" alignItems="center" my={2}>
            <ForumIcon fontSize="small" />
            <Box ml={2} fontWeight={500}>
              Mensagens
            </Box>
          </Box>
          <Box mb={2}>
            <Divider />
          </Box>
        </Box>
        <Box mb={2}>
          <TableContainer component={Paper} elevation={2} variant="outlined">
            <Table aria-label="histórico da ocorrência">
              <TableHead>
                <TableRow>
                  {headers.map((item, index) => {
                    return (
                      <TableCell key={index}>
                        <Box fontWeight={700}>{item}</Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {messages.map(item => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell>{item.pedido}</TableCell>
                      <TableCell>
                        {new Date(item.created_at).toLocaleDateString()} às {new Date(item.created_at).toLocaleTimeString()}
                      </TableCell>
                      <TableCell>{item.resposta}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Paper>
  );
};

export default ComunicationCard;
