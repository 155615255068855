import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { appURL } from '../../../services/auth/jwt/config';

interface AuxilioImageProps {
    link?: string;
    id?: string | number | undefined;
    type?: 'anexo' | 'comprovante_doc' | 'certidao_doc' | 'cpf_doc' | 'rg_doc';
}

export default function AuxilioImage({ id, type, link, ...props }: AuxilioImageProps) {
    const [image, setImage] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (id && type) {
            //fetch image from api and save it to state
            fetch(`${appURL}api/edu/auxilio-transporte/anexo/${id}/${type}`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('client_key')}`,
                },
            })
                .then(res => res.blob())
                .then(blob => {
                    if (blob.type === 'application/json') {
                        setImage('');
                    } else {
                        setImage(URL.createObjectURL(blob));
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else if (link) {
            setImage(`${appURL}${link}`);
            setLoading(false);
        }
    }, [id, type, link]);

    if (loading) {
        return <CircularProgress />;
    }
    if (!image) {
        return <div>Sem Anexo</div>;
    }
    return (
        <img
            src={image}
            alt={type}
            {...props}
            style={{ height: 200, width: 200, objectFit: 'contain', cursor: 'pointer' }}
            //baixar imagem
            onClick={() => {
                const a = document.createElement('a');
                a.href = image;
                a.download = `${id}_${type}`;
                a.click();
            }}
        />
    );
}
