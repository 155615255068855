import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectOcurrence } from '../../../../redux/actions/f5-actions/ocurrences-actions';
import ocurrencesActionService from '../../../../services/f5-services/ocurrencesActionService';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';

import sweetAlert from '../../../../f5/utils/sweetAlert';
import SharedTitle from './SharedTitle';
import SharedDescription from './SharedDescription';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  confirmButton: {
    color: 'white',
    backgroundColor: '#21cc37',
    '&:hover': {
      backgroundColor: '#32e33b',
    },
  },
  denyButton: {
    color: 'white',
    backgroundColor: '#d92916',
    '&:hover': {
      backgroundColor: '#f55538',
    },
  },
}));

const CancelOcurrence = ({ ocurrence, closeIt }) => {
  const [loading, setLoading] = useState({ message: '', status: false });

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    setLoading({ status: true, message: 'Cancelando ocorrência...' });

    const postData = {
      ocorrencia_id: ocurrence.ocorrencia_id,
    };

    await ocurrencesActionService.sendCancelOcurrence(postData);

    dispatch(selectOcurrence(ocurrence.ocorrencia_id));
    setLoading({ status: false, message: '' });
    sweetAlert('success', 'Sucesso', 'Ocorrência cancelada com sucesso!');
  };

  if (loading.status) {
    return (
      <Box
        className={classes.root}
        height={500}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center">
        <CircularProgress />
        <Box mt={4} fontWeight={700}>
          {loading.message}
        </Box>
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <SharedTitle icon={<CancelIcon />} title="Cancelar Ocorrência" />
      <SharedDescription
        requester={ocurrence.solicitante.nome}
        date={ocurrence.abertura}
        service={ocurrence.servico}
        protocol={ocurrence.protocolo}
      />

      <Box my={8} fontWeight={500} fontSize={16} textAlign="center">
        Tem certeza que deseja cancelar esta ocorrência?
      </Box>
      <Box mt={2} px={4} display="flex" justifyContent="center">
        <Box mx={2}>
          <Button classes={{ root: classes.confirmButton }} onClick={handleSubmit}>
            Sim
          </Button>
        </Box>
        <Box mx={2}>
          <Button classes={{ root: classes.denyButton }} onClick={() => closeIt()}>
            Não
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default CancelOcurrence;
