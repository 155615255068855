import React, { useState } from 'react';
import { Card, Button, Backdrop } from '@material-ui/core';
import FindUsuario from './steps/FindUsuario';
import moment from 'moment';
import FindHorario from './steps/FindHorario';
import { useParams, useHistory } from 'react-router-dom';
import axios_instance from '../../../services/auth/jwt/config';
import UserForm from './components/UserForm';
import sweetAlert from '../../../f5/utils/sweetAlert';

export default function NovaAgenda() {
    const [data, setData] = useState({
        nome: '',
        cpf: '',
        nasc: null,
        sexo: '',
        sangue: '',
        rua: '',
        numero: '',
        obs: '',
        bairro: '',
        phone: '',
        cidade: '',
        email: '',
        raca: '',
        puerpera: false,
        gestante: false,
    });

    const [step, setStep] = useState(0);
    const [usuarioID, setUsuarioID] = useState(-1);
    const [changed, setChanged] = useState(false);
    const [newUser, setNewUser] = useState(false);
    const [creatingDoador, setCreatingDoador] = useState(false);
    const [hora, setHora] = useState({ id: -1 });
    const [servico, setServico] = useState('1');
    const [dia, setDia] = useState(new Date());
    const { mode, local } = useParams();
    let history = useHistory();

    const handleBack = () => {
        if (step === 0) {
            if (newUser) {
                setNewUser(false);
                setData({
                    nasc: null,
                    sexo: '',
                    sangue: '',
                    rua: '',
                    obs: '',
                    phone: '',
                    numero: '',
                    bairro: '',
                    cidade: '',
                    email: '',
                });
                setUsuarioID(-1);
            } else {
                history.goBack();
            }
        } else {
            setStep(0);
        }
    };

    const onNewUser = () => {
        setCreatingDoador(true);
        let form = {
            nome: data.nome,
            cpf: data.cpf,
            nascimento: data.nasc === null ? null : moment(data.nasc).format('YYYY-MM-DD'),
            sexo: data.sexo,
            sangue: data.sangue,
            obs: data.obs,
            rua: data.rua,
            numero: data.numero,
            bairro: data.bairro,
            cidade: data.cidade,
            email: data.email,
            raca: data.raca,
            mae: data.mae,
            pai: data.pai,
            phone: data.phone,
        };
        axios_instance
            .post(`api/agendamento/${mode}/${local}/usuario`, form)
            .then(res => {
                let r = res.data;
                if (r.success) {
                    setStep(false);
                    setUsuarioID(r.doador.id);
                    setCreatingDoador(false);
                } else {
                    setCreatingDoador(false);
                    sweetAlert('error', 'Erro', r.message);
                }
            })
            .catch(() => {
                setCreatingDoador(false);
                sweetAlert('error', 'Erro', 'Erro ao criar o usuário');
            });
    };

    const onUpdateUser = () => {
        setCreatingDoador(true);
        let form = {
            nome: data.nome,
            cpf: data.cpf,
            datanasc: data.nasc === null ? null : moment(data.nasc).format('YYYY-MM-DD'),
            sexo: data.sexo,
            sangue: data.sangue,
            obs: data.obs,
            rua: data.rua,
            numero: data.numero,
            bairro: data.bairro,
            cidade: data.cidade,
            email: data.email,
            raca: data.raca,
            mae: data.mae,
            pai: data.pai,
            phone: data.phone,
        };
        axios_instance
            .post(`api/agendamento/${mode}/${local}/usuario/atualizar/${usuarioID}`, form)
            .then(res => {
                let r = res.data;
                if (r.success) {
                    setStep(1);
                    setCreatingDoador(false);
                } else {
                    setCreatingDoador(false);
                    sweetAlert('error', 'Erro', r.message);
                }
            })
            .catch(() => {
                setCreatingDoador(false);
                sweetAlert('error', 'Erro', 'Erro ao atualizar o usuário');
            });
    };

    const updateData = values => {
        setNewUser(values.newuser);
        setUsuarioID(values.usuario_id);
        setData({
            nome: values.nome,
            cpf: values.cpf,
            nasc: values.nasc,
            sexo: values.sexo,
            rua: values.rua,
            numero: values.numero,
            bairro: values.bairro,
            cidade: values.cidade,
            email: values.email,
            phone: values.telefone,
            pai: values.pai,
            mae: values.mae,
        });
    };

    const updateForm = (value, place) => {
        setData({
            ...data,
            [place]: value,
        });
        setChanged(true);
    };

    const handleNext = () => {
        if (step === 0) {
            if (newUser && usuarioID === -1) {
                onNewUser();
            } else if (changed) {
                onUpdateUser();
            } else {
                setStep(1);
            }
        } else {
            onSubmit();
        }
    };

    const updateHorario = (tipo, value) => {
        switch (tipo) {
            case 'dia':
                setDia(value);
                break;
            case 'hora':
                setHora(value);
                break;
            case 'servico':
                setServico(value);
                break;
            default:
        }
    };

    const onSubmit = () => {
        setCreatingDoador(true);

        let form = {
            hora: hora.id,
            dia: moment(dia).format('YYYY-MM-DD'),
            usuario_id: usuarioID,
            servico_id: servico,
        };

        axios_instance
            .post(`api/agendamento/${mode}/${local}/agenda/nova`, form)
            .then(res => {
                let r = res.data;
                if (r.success) {
                    setCreatingDoador(false);
                    sweetAlert('success', 'Agendamento salvo', 'Agendamento salvo com sucesso', () => {
                        history.push('/app/agenda');
                    });
                } else {
                    setCreatingDoador(false);
                    sweetAlert('error', 'Erro', r.message);
                }
            })
            .catch(() => {
                setCreatingDoador(false);
                sweetAlert('error', 'Erro', 'Erro ao criar o agendamento');
            });
    };

    let shouldDisableNext = false;
    if (step === 0 && usuarioID === -1 && !newUser) {
        shouldDisableNext = true;
    }
    if (step === 1 && !hora && hora.id > -1 && dia !== null && !isNaN(dia.valueOf())) {
        shouldDisableNext = true;
    }

    return (
        <div className="app-wrapper">
            <Card className="p-3">
                <h3 className="card-title">Cadastro Novo Usuário</h3>
                {step === 0 ? (
                    newUser ? (
                        <div>
                            <UserForm
                                data={data}
                                updateData={(value, place) => updateForm(value, place)}
                                tipos={[
                                    { id: 1, nome: 'A-' },
                                    { id: 2, nome: 'A+' },
                                    { id: 3, nome: 'AB-' },
                                    { id: 4, nome: 'AB+' },
                                    { id: 5, nome: 'B-' },
                                    { id: 6, nome: 'B+' },
                                    { id: 7, nome: 'O-' },
                                    { id: 8, nome: 'O+' },
                                    { id: 9, nome: 'Não definido' },
                                ]}
                            />
                        </div>
                    ) : (
                        <FindUsuario
                            nome={data.nome}
                            cpf={data.cpf}
                            updateData={values => updateData(values)}
                            handleAdd={setNewUser(true)}
                        />
                    )
                ) : (
                    <FindHorario dia={dia} hora={hora} servico={servico} updateData={updateHorario} />
                )}
                <div className="clearfix mt-3">
                    <div className="float-left">
                        <Button className="jr-btn bg-danger text-white" onClick={handleBack}>
                            Voltar
                        </Button>
                    </div>
                    <div className="float-right">
                        <Button
                            className={`jr-btn ${shouldDisableNext ? 'bg-grey' : 'bg-success'} text-white`}
                            disabled={shouldDisableNext}
                            onClick={handleNext}>
                            {step === 0
                                ? (newUser && usuarioID === -1) || changed
                                    ? 'Salvar Usuário'
                                    : 'Próximo'
                                : 'Finalizar'}
                        </Button>
                    </div>
                </div>
            </Card>
            <Backdrop open={creatingDoador} style={{ zIndex: 99 }} />
        </div>
    );
}
