import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';
import axios_instance from '../../../services/auth/jwt/config';
import sweetAlert from '../../../f5/utils/sweetAlert';

interface AuxilioAproveProps {
    id: number;
    nome?: string;
    onClose(reload?: boolean): void;
    open: boolean;
}

export default function AuxilioAprove({ open, onClose, nome, id, ...props }: AuxilioAproveProps) {
    const [periodos, setPeriodos] = useState<any[]>([]);
    const [periodo, setPeriodo] = useState<any>(null);
    const [loading, setLoading] = useState({ is: false, message: '' });

    useEffect(() => {
        axios_instance
            .get<any>(`edu/auxilio-transporte/meses/index`)
            .then(response => {
                let r = response.data;
                if (r.success) {
                    setPeriodos(r.data);
                } else {
                    sweetAlert('error', 'Erro', r.message);
                }
            })
            .catch(() => {
                sweetAlert('error', 'Erro', 'Erro ao carregar períodos!');
            });
    }, []);

    const onSave = () => {
        setLoading({ is: true, message: 'Salvando...' });
        if (!periodo) {
            sweetAlert('warning', 'Atenção', 'Selecione um período!');
            setLoading({ is: false, message: '' });
            return;
        }
        let form = {
            meses: periodo,
        };
        axios_instance
            .post<any>(`edu/auxilio-transporte/accept/${id}`, form)
            .then(response => {
                let r = response.data;
                if (r.success) {
                    sweetAlert('success', 'Sucesso', 'Auxílio aprovado com sucesso!');
                } else {
                    sweetAlert('error', 'Erro', r.message);
                }
                setLoading({ is: false, message: '' });
            })
            .catch(() => {
                sweetAlert('error', 'Erro', 'Erro ao aprovar auxílio!');
                setLoading({ is: false, message: '' });
            });
    };

    return (
        <>
            {loading ? <></> : null}
            <Dialog open={open} onClose={() => onClose(false)} fullWidth maxWidth="lg">
                <DialogTitle>Apovar Auxílio: {nome}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Período de Frequências</InputLabel>
                        <Select value={periodo} onChange={e => setPeriodo(e.target.value)}>
                            {periodos.map((p, i) => (
                                <MenuItem key={i} value={p.id}>
                                    {p.nome}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose(false)} className="bg-danger text-white">
                        Cancelar
                    </Button>
                    <Button onClick={onSave} className={`text-white bg-success`}>
                        Aprovar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
