import React, { useState } from 'react';
import { Box, Button, CircularProgress, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import axios_instance from '../../../../services/auth/jwt/config';
import sweetAlert from '../../../../f5/utils/sweetAlert';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    confirmButton: {
        color: 'white',
        backgroundColor: '#21cc37',
        '&:hover': {
            backgroundColor: '#32e33b',
        },
    },
    denyButton: {
        color: 'white',
        backgroundColor: '#d92916',
        '&:hover': {
            backgroundColor: '#f55538',
        },
    },
}));

export default function FakeOcurrence({ ocurrence, closeIt, ...props }) {
    const [loading, setLoading] = useState({ is: false, message: '' });
    const [motivo, setMotivo] = useState('');
    const classes = useStyles();

    const onBlockUser = () => {
        setLoading({ is: true, message: 'Bloqueando usuário...' });
        let form = {
            motivo,
        };
        axios_instance.post(`ocorrencia/bloquear/${ocurrence.ocorrencia_id}`, form).then(response => {
            let r = response.data;
            if (r.success) {
                sweetAlert('success', 'Sucesso', 'Solicitante bloqueado com sucesso!');
                setLoading({ is: false, message: '' });
                closeIt();
            } else {
                sweetAlert('error', 'Erro', 'Ocorreu um erro ao bloquear o solicitante!');
                setLoading({ is: false, message: '' });
            }
        });
    };

    if (loading.is) {
        return (
            <Box
                className={classes.root}
                height={500}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center">
                <CircularProgress />
                <Box mt={4} fontWeight={700}>
                    {loading.message}
                </Box>
            </Box>
        );
    }

    return (
        <div className={classes.root}>
            <Box display="flex" alignItems="center" mb={4}>
                <ReportProblemIcon fontSize="small" />
                <Box ml={2} className={classes.formTitle}>
                    Marcar como Denúncia falsa
                </Box>
            </Box>
            <Box my={8} fontWeight={500} fontSize={16} textAlign="center">
                Deseja marcar essa denúncia como falsa e bloquear o solicitante?
            </Box>
            <TextField
                fullWidth
                multiline
                minRows={4}
                variant="outlined"
                label="Motivo"
                value={motivo}
                onChange={e => setMotivo(e.target.value)}
            />
            <Box mt={2} px={4} display="flex" justifyContent="center">
                <Box mx={2}>
                    <Button classes={{ root: classes.denyButton }} onClick={closeIt}>
                        Voltar
                    </Button>
                </Box>
                <Box mx={2}>
                    <Button classes={{ root: classes.confirmButton }} onClick={() => onBlockUser()}>
                        Bloquear
                    </Button>
                </Box>
            </Box>
        </div>
    );
}
