import React, { useState } from 'react';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from '@material-ui/core';
import HorarioEditar from './components/HorarioEditar';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import axios_instance from '../../../services/auth/jwt/config';
import sweetAlert from '../../../f5/utils/sweetAlert';

export default function EditarAula({ data, onChange, onDone, onCancel, locais, ...props }) {
    const [loading, setLoading] = useState({ is: false, message: '' });

    const addHorario = () => {
        let hold = { ...data };
        hold.horarios.push({
            id: null,
            nome: '',
            instrucoes: '',
            semana: [],
            hora: [],
            fim: [],
        });
        onChange(hold);
    };

    const onRemoveHorario = index => {
        let hold = { ...data };
        hold.horarios.splice(index, 1);
        onChange(hold);
    };

    const onUpdateHorario = (index, horario) => {
        let hold = { ...data };
        hold.horarios[index] = horario;
        onChange(hold);
    };

    const onSave = () => {
        setLoading({ is: true, message: 'Salvando aula...' });
        axios_instance.post(`aula/aula/save${data.id ? `/${data.id}` : ''}`, data).then(res => {
            let r = res.data;
            if (r.success) {
                sweetAlert('Sucesso', 'Aula salva com sucesso', 'success', () => {
                    onDone(true);
                });
            } else {
                sweetAlert('Erro', 'Erro ao salvar aula', 'error');
            }
            setLoading({ is: false, message: '' });
        });
    };

    return (
        <Paper>
            {loading ? <></> : null}
            <Box p={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <h3>Editar Aula</h3>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Nome"
                            value={data.nome}
                            onChange={e => onChange({ ...data, nome: e.target.value })}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Local</InputLabel>
                            <Select
                                value={data.local_id}
                                onChange={e => {
                                    onChange({ ...data, local_id: e.target.value });
                                }}>
                                <MenuItem value={0}>Selecione um local</MenuItem>
                                {locais.map(l => (
                                    <MenuItem key={l.id} value={l.id}>
                                        {l.nome}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Instruções"
                            value={data.instrucoes}
                            onChange={e => onChange({ ...data, instrucoes: e.target.value })}
                            fullWidth
                            variant="outlined"
                            multiline
                            minRows={3}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <h3>Turmas</h3>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="contained" color="primary" fullWidth onClick={addHorario}>
                            Adicionar Turma
                        </Button>
                    </Grid>
                </Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                    {data.horarios.map((dados, i) => (
                        <HorarioEditar
                            key={i}
                            horario={dados}
                            onChange={horario => onUpdateHorario(i, horario)}
                            onRemove={() => onRemoveHorario(i)}
                            pos={i}
                        />
                    ))}
                </MuiPickersUtilsProvider>
                <Box mt={3}>
                    <Grid container>
                        <Grid item xs={2}>
                            <Button variant="contained" color="secondary" onClick={() => onDone()}>
                                Voltar
                            </Button>
                        </Grid>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" color="primary" onClick={() => onSave()}>
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Paper>
    );
}
