import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Card, Grid } from '@material-ui/core';
import sweetAlert from '../../../f5/utils/sweetAlert';
import { tableIcons, tableLocalization } from '../../../@jumbo/constants/TableConstants';
import { CalendarToday, Check, Delete } from '@material-ui/icons';
import MaterialTable from '@material-table/core';
import axios_instance from '../../../services/auth/jwt/config';

export default function InscricoesList({ ...props }) {
    const [inscritos, setInscritos] = useState([]);
    const [modo, setModo] = useState(null);
    const [actions, setActions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [counters, setCounters] = useState([]);

    const { id } = useParams();

    const loadCounters = useCallback(() => {
        axios_instance.get(`curso/inscritos/counters/${id}`).then(res => {
            let r = res.data;
            if (r.success) {
                setCounters(r.counters);
                setModo(r.counters[0].status);
                setActions(r.counters[0].actions);
            }
        });
    }, [id]);

    const loadData = useCallback(() => {
        setLoading(true);
        axios_instance
            .post(`curso/inscritos/show/${id}`, {
                status: modo,
            })
            .then(res => {
                let r = res.data;
                if (r.success) {
                    setInscritos(r.data);
                }
            })
            .catch(() => { })
            .finally(() => {
                setLoading(false);
            });
    }, [id, modo]);

    useEffect(() => {
        if (id) {
            loadCounters();
        }
    }, [id, loadCounters]);

    useEffect(() => {
        if (id && modo) {
            loadData();
        }
    }, [id, modo, loadData]);

    const onUpdateStatus = oldUpdate => {
        setLoading(true);
        axios_instance
            .post(`curso/inscritos/status/${oldUpdate.id}`, {
                status: oldUpdate.status,
            })
            .then(res => {
                let r = res.data;
                if (r.success) {
                    sweetAlert('success', 'Inscrição atualizada com sucesso');
                    loadData();
                } else {
                    sweetAlert('error', 'Erro ao atualizar inscrição');
                }
            })
            .catch(() => {
                sweetAlert('error', 'Erro ao atualizar inscrição');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div>
            <Box mb={3}>
                <Grid spacing={3} container>
                    {counters.map((item, pos) => (
                        <Grid item key={item.nome} xs={3}>
                            <Card
                                style={{ backgroundColor: item.color, cursor: 'pointer' }}
                                onClick={() => {
                                    setModo(item.status);
                                    setActions(item.actions);
                                }}>
                                <Box m={3}>
                                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                                        <Grid item xs={2}>
                                            {item.icon === 'times' ? (
                                                <Delete fontSize={'large'} style={{ color: 'white' }} />
                                            ) : item.icon === 'clock' ? (
                                                <CalendarToday fontSize={'large'} style={{ color: 'white' }} />
                                            ) : (
                                                <Check fontSize={'large'} style={{ color: 'white' }} />
                                            )}
                                        </Grid>
                                        <Grid item xs={10}>
                                            <h1 style={{ color: 'white' }}>{item.count}</h1>
                                            <p style={{ color: 'white' }}>{item.nome}</p>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <MaterialTable
                icons={tableIcons}
                localization={tableLocalization}
                isLoading={loading}
                data={inscritos}
                title="Inscrições"
                columns={[
                    { title: 'Nome', field: 'nome' },
                    { title: 'CPF', field: 'cpf' },
                    { title: 'RG', field: 'rg' },
                    { title: 'E-mail', field: 'email' },
                    { title: 'Celular', field: 'telefone' },
                    {
                        title: 'Nascimento',
                        field: 'data_nascimento',
                        render: rowData => {
                            return rowData.data_nascimento ? new Date(rowData.data_nascimento).toLocaleDateString() : '';
                        },
                    },
                ]}
                options={{
                    actionsColumnIndex: -1,
                    exportButton: true,
                }}
                actions={actions.map(item => {
                    return rowData => ({
                        icon: () => <Check />,
                        onClick: (_e, row) => {
                            sweetAlert('warning', 'Tem certeza que deseja alterar o status da inscrição?', '', () => {
                                onUpdateStatus({ id: row.id, status: item.status });
                            });
                        },
                        disabled: item.allowed.indexOf(rowData.aprovacao.interno) === -1,
                        tooltip: item.name,
                    });
                })}
            />
        </div>
    );
}
