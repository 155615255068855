import React, { useState, useEffect } from 'react';
import generalService from '../../../services/f5-services/generalService';
import { Box, Paper } from '@material-ui/core';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useHistory } from 'react-router-dom';
import { format, isBefore, isAfter, isSameDay, addDays, isEqual, parseISO } from 'date-fns';

import GeneralCalendarStamp from './GeneralCalendarStamp';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.only('sm')]: {
            flexDirection: 'row',
            justifyContent: 'center',
        },
    },
    labelRoot: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '12px',
        marginBottom: '1rem',
        [theme.breakpoints.only('sm')]: {
            width: '35%',
            alignItems: 'center',
            justifyContent: 'center',
            '& > *': {
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
            },
        },
    },
    calendarRoot: {
        width: '100%',
        border: 'none',
        transform: 'scale(0.9)',
        [theme.breakpoints.only('sm')]: {
            width: '50%',
        },
    },
    tile: {
        position: 'relative',
        // TODO: remove !important
        overflow: 'visible !important',
        '& abbr': {
            zIndex: 5,
            position: 'relative',
        },
    },
    overdueLabel: {
        color: 'rgba(200, 0, 0, 1)',
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.05)',
        '&::before': {
            display: 'inline-block',
            content: '""',
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: 'rgba(255, 0, 0, 0.85)',
            marginRight: '0.5rem',
        },
    },
    dueLabel: {
        color: 'rgba(225, 150, 0, 1)',
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.05)',
        '&::before': {
            display: 'inline-block',
            content: '""',
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: 'rgba(255, 170, 0, 0.85)',
            marginRight: '0.5rem',
        },
    },
    todueLabel: {
        color: 'rgba(0, 0, 200, 1)',
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.05)',
        '&::before': {
            display: 'inline-block',
            content: '""',
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: 'rgba(0, 0, 255, 0.85)',
            marginRight: '0.5rem',
        },
    },
}));

const GeneralCalendarPicker = () => {
    const [date, changeDate] = useState(new Date());
    const [stamps, setStamps] = useState([]);

    const dueDataStamps = [];
    const overdueDataStamps = [];

    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        const getStamps = async () => {
            const stampsData = await generalService.getCalendarStamps();
            setStamps(stampsData.data);
        };

        getStamps();
    }, [setStamps]);

    function tileContent({ date, view }) {
        // Add content to tiles in month view only
        if (view === 'month') {
            const dayData = stamps.find(item => isSameDay(date, parseISO(item.day)));
            if (dayData) {
                if ((isBefore(date, addDays(new Date(), 3)) || isEqual(date, addDays(new Date(), 3))) && isAfter(date, new Date())) {
                    if (dayData.count > 0) {
                        dueDataStamps.push(dayData.day);
                    }
                    return <GeneralCalendarStamp stampStyle="due" count={dayData.count} />;
                } else if (date && (isBefore(date, new Date()) || isEqual(date, new Date()))) {
                    if (dayData.count > 0) {
                        overdueDataStamps.push(dayData.day);
                    }
                    return <GeneralCalendarStamp stampStyle="overdue" count={dayData.count} />;
                } else {
                    return <GeneralCalendarStamp stampStyle="todue" count={dayData.count} />;
                }
            }
        }
    }

    const handleDayClick = value => {
        const formatedTime = format(value, 'yyyy-MM-dd');

        if (overdueDataStamps.includes(formatedTime)) {
            history.push(`/ocorrencias/vencidas?data=${formatedTime}`);
        } else if (dueDataStamps.includes(formatedTime)) {
            history.push(`/ocorrencias/vencer?data=${formatedTime}`);
        }
    };

    return (
        <Paper>
            <Box className={classes.root}>
                <Calendar
                    onChange={changeDate}
                    value={date}
                    tileContent={tileContent}
                    className={classes.calendarRoot}
                    tileClassName={classes.tile}
                    onClickDay={handleDayClick}
                    locale="pt-BR"
                />
                <Box className={classes.labelRoot}>
                    <Box mx={2} mb={2} className={classes.todueLabel} fontWeight={700}>
                        Ocorrências abertas
                    </Box>
                    <Box mx={2} mb={2} className={classes.dueLabel} fontWeight={700}>
                        A vencer
                    </Box>
                    <Box mx={2} className={classes.overdueLabel} fontWeight={700}>
                        Vencidas
                    </Box>
                </Box>
            </Box>
        </Paper>
    );
};

export default GeneralCalendarPicker;
