import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectOcurrence } from '../../../../redux/actions/f5-actions/ocurrences-actions';
import ocurrencesActionService from '../../../../services/f5-services/ocurrencesActionService';
import { Box, CircularProgress, TextField, Button } from '@material-ui/core';
import AddAlarmIcon from '@material-ui/icons/AddAlarm';

import sweetAlert from '../../../../f5/utils/sweetAlert';
import SharedTitle from './SharedTitle';
import SharedDescription from './SharedDescription';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  saveButton: {
    color: 'white',
    backgroundColor: '#21cc37',
    '&:hover': {
      backgroundColor: '#32e33b',
    },
  },
}));

const formattedDate = s => {
  const validDate = new Date(s);
  return `${validDate.getDate()}/${validDate.getMonth()}/${validDate.getFullYear()}`;
};

const ExtendDeadline = ({ ocurrence }) => {
  const [loading, setLoading] = useState({ message: '', status: false });
  const [reason, setReason] = useState('');
  const [deadline, setDeadline] = useState(1);

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    setLoading({ status: true, message: 'Enviando prorrogação...' });

    const postData = {
      motivo: reason,
      data_nova: deadline,
    };

    await ocurrencesActionService.sendExtendDeadline(ocurrence.ocorrencia_id, postData);
    setLoading({ status: false, message: '' });

    dispatch(selectOcurrence(ocurrence.ocorrencia_id));
    setLoading({ status: false, message: '' });
    sweetAlert('success', 'Sucesso', 'Prorrogação realizada com sucesso!');
  };

  if (loading.status) {
    return (
      <Box
        className={classes.root}
        height={500}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center">
        <CircularProgress />
        <Box mt={4} fontWeight={700}>
          {loading.message}
        </Box>
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <SharedTitle icon={<AddAlarmIcon />} title="Solicitar Prorrogação" />
      <SharedDescription
        requester={ocurrence.solicitante.nome}
        date={`${new Date(ocurrence.abertura).toLocaleDateString()} às ${new Date(ocurrence.abertura).toLocaleTimeString()}`}
        service={ocurrence.servico}
        protocol={ocurrence.protocolo}
      />

      <Box width="100%" mb={8}>
        <Box mb={4}>
          <TextField
            label="Motivo da prorrogação"
            multiline
            rows={3}
            variant="outlined"
            fullWidth
            value={reason}
            onChange={e => setReason(e.target.value)}
          />
          <Box mt={1} fontSize={12} fontWeight={500}>
            Prazo atual: {ocurrence.prazo ? formattedDate(ocurrence.prazo) : null}
          </Box>
        </Box>
        <TextField
          required
          label="Adicionar Prazo (n° dias)"
          type="number"
          inputProps={{ min: '1' }}
          value={deadline}
          onChange={e => setDeadline(e.target.value)}
        />
      </Box>
      <Button classes={{ root: classes.saveButton }} onClick={handleSubmit}>
        enviar
      </Button>
    </div>
  );
};

export default ExtendDeadline;
