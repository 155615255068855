import React, { useCallback, useEffect, useState } from 'react';
import MaterialTable from '@material-table/core';
import ServicoEditar from './components/ServicoEditar';
import { useParams } from 'react-router-dom';
import sweetAlert from '../../../f5/utils/sweetAlert';
import { tableIcons, tableLocalization } from '../../../@jumbo/constants/TableConstants';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import axios_instance from '../../../services/auth/jwt/config';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

export default function ServicosLocal() {
    const [selected, setSelected] = useState({
        id: -1,
        nome: '',
        descricao: '',
        disponibilidades: [],
        template: [],
    });
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState({ is: false, message: '' });

    const { modo, local } = useParams();

    const loadServicos = useCallback(() => {
        setLoading({ is: true, message: 'Carregando Serviços...' });
        axios_instance
            .get(`agendamento/${modo}/${local}/servicos`)
            .then(res => {
                let r = res.data;
                if (r.success) {
                    setData(r.items);
                } else {
                    sweetAlert('error', 'Erro', r.message);
                }
                setLoading({ is: false, message: '' });
            })
            .catch(() => {
                sweetAlert('error', 'Erro', 'Erro ao carregar Serviços');
                setLoading({ is: false, message: '' });
            });
    }, [modo, local]);

    useEffect(() => {
        loadServicos();
    }, [modo, local, loadServicos]);

    const loadSelected = id => {
        setLoading({ is: true, message: 'Carregando Serviço...' });
        axios_instance
            .get(`agendamento/${modo}/${local}/servico/${id}`)
            .then(res => {
                let r = res.data;
                if (r.success) {
                    setSelected(r.item);
                    setOpen(true);
                } else {
                    sweetAlert('error', 'Erro', r.message);
                }
                setLoading({ is: false, message: '' });
            })
            .catch(() => {
                sweetAlert('error', 'Erro', 'Erro ao carregar Serviço');
                setLoading({ is: false, message: '' });
            });
    };

    const handleSave = () => {
        let form = {
            nome: selected.nome,
            descricao: selected.descricao,
            disponibilidade: selected.disponibilidades,
            template: selected.template,
        };
        setLoading({ is: true, message: 'Salvando Serviço...' });
        let link = `agendamento/${modo}/${local}/servico/new`;
        if (selected.id > -1) {
            link = `agendamento/${modo}/${local}/servico/${selected.id}/edit`;
        }
        axios_instance
            .post(link, form)
            .then(res => {
                let r = res.data;
                if (r.success) {
                    sweetAlert('success', 'Sucesso', 'Serviço Salvo');
                    setSelected({
                        id: -1,
                        nome: '',
                        descricao: '',
                        disponibilidades: [],
                        template: [],
                    });
                    setOpen(false);
                    loadServicos();
                } else {
                    sweetAlert('error', 'Erro', r.message);
                }
                setLoading({ is: false, message: '' });
            })
            .catch(() => {
                sweetAlert('error', 'Erro', 'Erro ao salvar Serviço');
                setLoading({ is: false, message: '' });
            });
    };

    const clickEdit = (_e, row) => {
        loadSelected(row.id);
    };

    const onChangeStatus = id => {
        axios_instance
            .post(`agendamento/${modo}/${local}/servico/${id}/status`)
            .then(res => {
                let r = res.data;
                if (r.success) {
                    sweetAlert('success', 'Sucesso', 'Status Alterado');
                    loadServicos();
                } else {
                    sweetAlert('error', 'Erro', r.message);
                }
            })
            .catch(() => {
                sweetAlert('error', 'Erro', 'Erro ao alterar Status');
            });
    };

    return (
        <div>
            {!open ? (
                <MaterialTable
                    title="Serviços"
                    icons={tableIcons}
                    localization={tableLocalization}
                    columns={[{ title: 'Nome', field: 'nome' }]}
                    isLoading={loading.is}
                    data={data}
                    options={{
                        exportButton: true,
                        actionsColumnIndex: -1,
                    }}
                    actions={[
                        {
                            icon: () => <EditIcon />,
                            tooltip: 'Editar',
                            onClick: clickEdit,
                        },
                        rowData => ({
                            icon: () => (rowData.enabled ? <VisibilityIcon /> : <VisibilityOffIcon />),
                            tooltip: rowData.enabled ? 'Desabilitar' : 'Habilitar',
                            onClick: (_e, row) => {
                                sweetAlert(
                                    'warning',
                                    'Atenção',
                                    `Deseja ${rowData.enabled ? 'desabilitar' : 'habilitar'} o serviço ${row.nome}?`,
                                    () => {
                                        onChangeStatus(row.id);
                                    },
                                );
                            },
                        }),
                        {
                            icon: () => <AddIcon />,
                            tooltip: 'Novo',
                            isFreeAction: true,
                            onClick: () => {
                                setOpen(true);
                            },
                        },
                    ]}
                />
            ) : (
                <ServicoEditar
                    servico={selected}
                    onChange={setSelected}
                    mode={selected.id > -1 ? 'edit' : 'new'}
                    handleSave={handleSave}
                    onClose={() => {
                        setOpen(false);
                    }}
                />
            )}
        </div>
    );
}
