import { tableIcons, tableLocalization } from '../../../@jumbo/constants/TableConstants';
import MaterialTable from '@material-table/core';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Grid,
    InputLabel,
    Box,
    Button,
    CircularProgress,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import axios_instance from '../../../services/auth/jwt/config';
import TinyEditor from '../Schedule/components/TinyEditor';
import { Save } from '@material-ui/icons';
import sweetAlert from '../../../f5/utils/sweetAlert';

export default function ServiceList({ ...props }) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState({ is: false, message: '' });
    const [edit, setEdit] = useState({ open: false, id: null, data: {} });

    const onLoad = useCallback(() => {
        setLoading({ is: true, message: 'Cargando dados...' });
        axios_instance
            .get(`servicos/index`)
            .then(response => {
                let r = response.data;
                if (r.success) {
                    setData(r.data);
                }
            })
            .finally(() => {
                setLoading({ is: false, message: '' });
            });
    }, []);

    useEffect(() => {
        onLoad();
    }, [onLoad]);

    const onSave = () => {
        setLoading({ is: true, message: 'Salvando dados...' });
        let form = {
            title: edit.data.title,
            descricao: edit.data.descricao,
            content: edit.data.content,
            link: edit.data.link,
        };
        axios_instance
            .post(`servicos/edit${edit.id ? `/${edit.id}` : ''}`, form)
            .then(response => {
                let r = response.data;
                if (r.success) {
                    setEdit({ open: false, id: null, data: {} });
                    onLoad();
                } else {
                    sweetAlert('error', 'Error', r?.message ?? 'Error salvar informação');
                }
            })
            .catch(() => {
                sweetAlert('error', 'Error', 'Error salvar informação');
            })
            .finally(() => {
                setLoading({ is: false, message: '' });
            });
    };

    return (
        <div>
            <Dialog open={edit.open} onClose={() => setEdit({ open: false, id: null, data: {} })} fullWidth maxWidth="lg">
                <DialogTitle>{edit.id ? 'Editar' : 'Adicionar'} Informação</DialogTitle>
                <DialogContent>
                    {loading.is ? (
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <CircularProgress />
                            {loading.message}
                        </Box>
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    value={edit.data.title}
                                    onChange={e => setEdit({ ...edit, data: { ...edit.data, title: e.target.value } })}
                                    label="Nome *"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    value={edit.data.descricao}
                                    onChange={e => setEdit({ ...edit, data: { ...edit.data, descricao: e.target.value } })}
                                    variant="outlined"
                                    fullWidth
                                    label="Descrição"
                                    margin="normal"
                                    multiline
                                    minRows={3}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel>Conteúdo</InputLabel>
                                <TinyEditor
                                    value={edit.data.content}
                                    onChange={e => setEdit({ ...edit, data: { ...edit.data, content: e } })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    value={edit.data.link}
                                    onChange={e => setEdit({ ...edit, data: { ...edit.data, link: e.target.value } })}
                                    label="Link"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Box display="flex" justifyContent="flex-end">
                        <Button onClick={onSave}>
                            <Save />
                            &nbsp;Salvar
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <MaterialTable
                icons={tableIcons}
                localization={tableLocalization}
                data={data}
                isLoading={loading.is}
                title="Informações"
                columns={[{ title: 'Nome', field: 'title' }]}
                actions={[
                    {
                        icon: tableIcons.Edit,
                        tooltip: 'Editar',
                        onClick: (_event, rowData) => {
                            setEdit({ open: true, id: rowData.id, data: rowData });
                        },
                    },
                    /* {
                        icon: tableIcons.Delete,
                        tooltip: 'Eliminar',
                        onClick: (_event, rowData) => { },
                    },
                    {
                        icon: tableIcons.Add,
                        tooltip: 'Adicionar',
                        isFreeAction: true,
                        onClick: () => {
                            setEdit({ open: true, id: null, data: {} });
                        },
                    }, */
                ]}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: 20,
                }}
            />
        </div>
    );
}
