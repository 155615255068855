import React, { useEffect, useState } from 'react';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import MaterialTable from '@material-table/core';
import axios_instance from '../../../services/auth/jwt/config';
import { tableIcons, tableLocalization } from '../../../@jumbo/constants/TableConstants';

interface LastLoginProps {
  
}

type Acesso = {
    id: number,
    nome: string,
    last_login: string,
    last_ip: string

}

export default function LastLogin({...props}:LastLoginProps){
    const [data, setData] = useState<Acesso[]>([]);
    const [success,setSuccess] = useState({has: false, message: ''})
    const [loading,setLoading] = useState({is: false, message: ''})
    const [error,setError] = useState({has: false, message: ''})

    useEffect(() => {
        setLoading({is: true, message: 'Carregando dados...'})
        axios_instance.get<{success: boolean,data: Acesso[]}>('/atendente/access/last').then((response) => {
            if(response.data.success){
                setData(response.data.data)
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setLoading({is: false, message: ''})
        })
    },[])

    return <div className='app-wrapper'>
        <MaterialTable
            title="Últimos Acessos"
            icons={tableIcons}
            localization={tableLocalization}
            columns={[
                { title: 'Usuário', field: 'nome' },
                { title: 'Última Acesso', field: 'last_login', type: 'datetime'},
                { title: 'Último IP', field: 'last_ip' },
            ]}
            data={data}
            isLoading={loading.is}
            options={{
                pageSize: 10,
            }}
        />
    </div>
}