import React from 'react';
import { Dialog, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Document, PDFViewer, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import AgendaDia from './AgendaDia';

export default function RelatorioDia({ open, onClose, start, end, events, servicos, ...props }) {
    const [filtro, setFiltro] = React.useState({ id: null });
    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>
                Agendamentos de {moment(start).format('DD/MM/YYYY')} a {moment(end).format('DD/MM/YYYY')}
            </DialogTitle>
            <DialogContent>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="filtro" shrink={true}>
                        Filtrar por serviço
                    </InputLabel>
                    <Select
                        value={filtro}
                        onChange={e => {
                            if (e.target.value) {
                                setFiltro(servicos.find(i => i.id === e.target.value));
                            } else {
                                setFiltro({ id: null });
                            }
                        }}
                        displayEmpty
                        labelId="filtro"
                        label="Filtrar por serviço"
                        inputProps={{ 'aria-label': 'Without label' }}>
                        <MenuItem value={null}>Todos</MenuItem>
                        {servicos.map((item, index) => (
                            <MenuItem value={item.id} key={index}>
                                {item.nome}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <PDFViewer style={{ minHeight: '80vh', width: '100%', marginTop: 20 }}>
                    <Document>
                        <Page size="A4">
                            <View style={styles.section}>
                                <Text style={styles.title}>
                                    Agendamentos de {moment(start).format('DD/MM/YYYY')} a {moment(end).format('DD/MM/YYYY')}
                                </Text>
                                {filtro.id ? <Text style={styles.title}>Serviço: {filtro.nome}</Text> : null}
                            </View>
                            {events
                                .filter(i => !filtro.id || i.servico_obj.id === filtro.id)
                                .map((item, index) => (
                                    <AgendaDia evento={item} key={index} />
                                ))}
                        </Page>
                    </Document>
                </PDFViewer>
            </DialogContent>
        </Dialog>
    );
}

const styles = StyleSheet.create({
    section: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
    },
    title: {
        fontSize: 12,
        fontWeight: 'bold',
        marginHorizontal: 5,
        textAlign: 'center',
    },
});
