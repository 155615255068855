import React, { useCallback, useEffect, useState } from 'react';
import axios_instance from '../../../services/auth/jwt/config';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../../../@jumbo/constants/TableConstants';
import { Add, ArrowBack, Check, Close, CloudDownload, Edit, Info, SwapHoriz } from '@material-ui/icons';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';
import InscricaoPdf from './components/InscricaoPdf';
import { PDFViewer } from '@react-pdf/renderer';
import sweetAlert from '../../../f5/utils/sweetAlert';
import NovaInscricao from './components/NovaInscricao';

export default function ListInscricoes({ aula, onBack, aulas, ...props }) {
    const [inscricoes, setInscricoes] = useState([]);
    const [printing, setPrinting] = useState({ open: false, inscricao: null });
    const [novaInscricao, setNovaInscricao] = useState({ open: false, inscricao: null, id: 0, data: {} });
    const [ask, setAsk] = useState({ open: false, inscricao: null, message: '', mode: '' });
    const [swap, setSwap] = useState({ open: false, inscricao: null, message: '', novaTurma: null });
    const [loading, setLoading] = useState({ is: false, message: '' });

    const onLoad = useCallback(() => {
        setLoading({ is: true, message: '' });
        axios_instance.get(`aula/aula/inscricoes/${aula.id}`).then(res => {
            let r = res.data;
            if (r.success) {
                setInscricoes(r.data);
            }
            setLoading({ is: false, message: '' });
        });
    }, [aula.id]);

    useEffect(() => {
        if (aula.id) {
            onLoad();
        }
    }, [aula, onLoad]);

    const onDownload = () => {
        axios_instance.get(`aula/aulas/download/${aula.id}`, { responseType: 'blob' }).then(res => {
            let r = res.data;
            if (r) {
                const url = window.URL.createObjectURL(new Blob([r]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `inscricoes-${aula.nome}.xlsx`);
                document.body.appendChild(link);
                link.click();
            }
        });
    };

    const onAsk = toAsk => {
        setLoading({ is: true, message: '' });
        setAsk({ open: false, inscricao: null, message: '', mode: '' });
        axios_instance.post(`aula/aula/atualizar/${toAsk.inscricao.id}`, { status: toAsk.mode }).then(res => {
            if (res.data.success) {
                sweetAlert('success', 'Sucesso!', 'Inscrição atualizada com sucesso!', () => {
                    onLoad();
                });
            }
            setLoading({ is: false, message: '' });
        });
    };

    const onSwap = oldSwap => {
        setLoading({ is: true, message: 'Alterando Turma' });
        let form = {
            nova_turma: oldSwap.novaTurma,
            inscricao: oldSwap.inscricao.id,
        };
        setSwap({ open: false, inscricao: null, message: '', novaTurma: null });
        axios_instance.post(`aula/aula/transferir`, form).then(res => {
            if (res.data.success) {
                sweetAlert('success', 'Sucesso!', 'Inscrição transferida com sucesso!', () => {
                    onLoad();
                });
            }
            setLoading({ is: false, message: '' });
        });
    };

    return (
        <>
            <MaterialTable
                title={`Inscrições na aula ${aula.nome}`}
                columns={[
                    { title: 'Nome', field: 'nome' },
                    { title: 'Telefone', field: 'telefone' },
                    { title: 'Turma', field: 'horario.nome' },
                    { title: 'Status', field: 'status.nome' },
                ]}
                data={inscricoes}
                icons={tableIcons}
                isLoading={loading.is}
                actions={[
                    {
                        isFreeAction: true,
                        icon: () => <ArrowBack />,
                        tooltip: 'Voltar',
                        onClick: () => {
                            onBack();
                        },
                    },
                    {
                        isFreeAction: true,
                        icon: () => <Add />,
                        tooltip: 'Nova inscrição',
                        onClick: () => {
                            setNovaInscricao({ open: true, inscricao: null, id: 0, data: {} });
                        },
                    },
                    {
                        icon: () => <Edit />,
                        tooltip: 'Editar',
                        onClick: (_event, rowData) => {
                            setNovaInscricao({ open: true, inscricao: rowData, id: rowData.id, data: rowData });
                        },
                    },
                    {
                        isFreeAction: true,
                        icon: () => <CloudDownload />,
                        tooltip: 'Baixar lista de inscrições',
                        onClick: () => {
                            onDownload();
                        },
                    },
                    {
                        icon: () => <Check />,
                        tooltip: 'Concluir',
                        onClick: (_event, rowData) => {
                            setAsk({
                                open: true,
                                inscricao: rowData,
                                message: 'Deseja concluir a inscrição?',
                                mode: 'completed',
                            });
                        },
                    },
                    {
                        icon: () => <Close />,
                        tooltip: 'Cancelar',
                        onClick: (_event, rowData) => {
                            setAsk({
                                open: true,
                                inscricao: rowData,
                                message: 'Deseja cancelar a inscrição?',
                                mode: 'canceled',
                            });
                        },
                    },
                    {
                        icon: () => <CloudDownload />,
                        tooltip: 'Baixar ficha de inscrição',
                        onClick: (_event, rowData) => {
                            setPrinting({ open: true, inscricao: rowData });
                        },
                    },
                    {
                        icon: () => <SwapHoriz />,
                        tooltip: 'Transferir',
                        onClick: (_event, rowData) => {
                            setSwap({ open: true, inscricao: rowData, message: '', novaTurma: null });
                        },
                    },
                ]}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: 20,
                    rowStyle: rowData => ({
                        fontWeight: rowData.status.interno === 'pending' ? 'bold' : 'normal',
                        borderLeft: rowData.status.interno === 'pending' ? '5px solid #3f51b5' : 'none',
                        borderRight: rowData.status.interno === 'pending' ? '5px solid #3f51b5' : 'none',
                    }),
                }}
            />
            <Dialog
                open={printing.open}
                onClose={() => setPrinting({ open: false, inscricao: null })}
                fullWidth
                maxWidth="lg">
                <DialogTitle>Ficha de Inscrição</DialogTitle>
                <DialogContent>
                    {printing.inscricao ? (
                        <PDFViewer width="100%" height="100%" style={{ minHeight: '70vh' }}>
                            <InscricaoPdf inscricao={printing.inscricao} />
                        </PDFViewer>
                    ) : null}
                </DialogContent>
            </Dialog>
            <Dialog
                open={swap.open}
                onClose={() => setSwap({ open: false, inscricao: null, message: '', novaTurma: null })}
                fullWidth
                maxWidth="sm">
                <DialogTitle>Transferir inscrição</DialogTitle>
                <DialogContent>
                    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                        <FormControl fullWidth>
                            <InputLabel>Turma</InputLabel>
                            <Select
                                value={swap.novaTurma}
                                onChange={e => setSwap({ ...swap, novaTurma: e.target.value })}
                                label="Turma">
                                {aulas.map(aula => (
                                    <MenuItem key={aula.id} value={aula.id}>
                                        {aula.nome}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => onSwap(swap)}
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={!swap.novaTurma}>
                        Transferir
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={ask.open}
                onClose={() => setAsk({ open: false, inscricao: null, message: '', mode: '' })}
                fullWidth
                maxWidth="sm">
                <DialogContent>
                    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                        <Info color="primary" fontSize="large" />
                        <h3>{ask.message}</h3>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item xs={3}>
                            <Button
                                onClick={() => setAsk({ open: false, inscricao: null, message: '', mode: '' })}
                                fullWidth
                                variant="outlined"
                                color="secondary">
                                Fechar
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button onClick={() => onAsk(ask)} fullWidth variant="contained" color="primary">
                                Sim
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <NovaInscricao
                open={novaInscricao.open}
                aula={aula}
                id={novaInscricao.id}
                edit={novaInscricao.data}
                onClose={() => {
                    setNovaInscricao({ open: false, inscricao: null, id: 0, data: {} });
                    onLoad();
                }}
            />
        </>
    );
}
