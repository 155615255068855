import React, { useCallback, useEffect, useState } from 'react';
import {
    Card,
    Backdrop,
    CircularProgress,
    Modal,
    Grid,
    Box,
    ButtonGroup,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@material-ui/core';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useParams, useHistory } from 'react-router-dom';
import AgendaCard from './components/AgendaCard';
import RelatorioAgendamento from './components/RelatorioAgendamento';
import axios_instance from '../../../services/auth/jwt/config';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import EventIcon from '@material-ui/icons/Event';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ListIcon from '@material-ui/icons/List';
import SettingsIcon from '@material-ui/icons/Settings';
import DescriptionIcon from '@material-ui/icons/Description';
import MaterialTable from '@material-table/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { tableIcons, tableLocalization } from '../../../@jumbo/constants/TableConstants';
import { PDFViewer, Document, Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import AgendaDia from './components/AgendaDia';
import RelatorioDia from './components/RelatorioDia';

const localizer = momentLocalizer(moment);

function Agendas({ ...props }) {
    const [modo, setModo] = useState('total');
    const [view, setView] = useState('list');
    const [events, setEvents] = useState([]);
    const [servicos, setServicos] = useState([]);
    const [start, setStart] = useState(moment().toDate());
    const [end, setEnd] = useState(moment().toDate());
    const [selectedEvent, setSelectedEvent] = useState({});
    const [modal, setModal] = useState(false);
    const [selectedView, setSelectedView] = useState('week');
    const [relatorio, setRelatorio] = useState(false);
    const [openRelatorioDia, setOpenRelatorioDia] = useState(false);
    const [counters, setCounters] = useState({
        pendentes: 0,
        agendados: 0,
        cancelados: 0,
        completos: 0,
        faltas: 0,
        total: 0,
    });
    const [loading, setLoading] = useState(false);

    const { mode, local } = useParams();
    const history = useHistory();

    const loadCounters = useCallback(() => {
        axios_instance.get(`agendamento/${mode}/${local}/counter`).then(res => {
            let r = res.data;
            if (r.success) {
                setCounters({
                    pendentes: r.pendentes,
                    agendados: r.agendados,
                    cancelados: r.cancelados,
                    completos: r.completos,
                    faltas: r.faltas,
                    total: r.total,
                });
            }
        });
    }, [mode, local]);

    const updateGraphic = useCallback(() => {
        setLoading(true);
        axios_instance
            .get(
                `agendamento/${mode}/${local}/agenda/list/${modo}?start=${moment(start).format('YYYY-MM-DD')}&end=${moment(
                    end,
                ).format('YYYY-MM-DD')}`,
            )
            .then(res => {
                let result = res.data;
                let hold = result.items.map(item => {
                    item.start = moment(item.start).toDate();
                    item.end = moment(item.end).toDate();
                    return item;
                });
                setEvents(hold);
                setLoading(false);
            });
    }, [mode, local, modo, start, end]);

    const loadServicos = useCallback(() => {
        axios_instance.get(`agendamento/${mode}/${local}/servicos`).then(res => {
            let result = res.data;
            if (result.success) {
                setServicos(result.items);
            }
        });
    }, [mode, local]);

    useEffect(() => {
        loadCounters();
    }, [mode, local, loadCounters]);

    useEffect(() => {
        loadServicos();
    }, [mode, local, loadServicos]);

    useEffect(() => {
        updateGraphic();
    }, [mode, local, modo, start, end, updateGraphic]);

    const handleColors = (event, start, end, isSelected) => {
        let background = 'bg-blue text-white';
        switch (event.status) {
            case 'Pendente':
                background = 'bg-warning';
                break;
            case 'Falta':
            case 'Cancelado':
                background = 'bg-danger text-white';
                break;
            case 'Completo':
                background = 'bg-success text-white';
                break;
            case 'Aceito':
            default:
        }

        return {
            className: background,
        };
    };

    const handleChangeView = (range, view) => {
        let _start = new Date();
        let _end = new Date();
        let newView = selectedView;
        if (view) {
            newView = view;
        }

        if (newView === 'month') {
            _start = range.start;
            _end = range.end;
        } else {
            _start = range[0];
            _end = range[range.length - 1];
        }
        setStart(_start);
        setEnd(_end);
        setSelectedView(newView);
    };

    const handleEvent = event => {
        setSelectedEvent(event);
        setModal(true);
    };

    const onDone = update => {
        setModal(false);
        setSelectedEvent({});
        if (update) {
            updateGraphic();
            loadCounters();
        }
    };

    const changeModo = modo => {
        setModo(modo);
    };

    let actions = [
        {
            color: '#2d2dc3',
            icon: <CalendarTodayIcon />,
            name: 'Todas',
            count: counters.total,
            id: 'total',
            active: true,
            size: 2,
            action: () => changeModo('total'),
        },
        {
            color: 'orange',
            icon: <EventIcon />,
            name: 'Pendentes',
            count: counters.pendentes,
            id: 'pendente',
            active: true,
            size: 2,
            action: () => changeModo('pendente'),
        },
        {
            color: '#1e7dff',
            icon: <EventAvailableIcon />,
            name: 'Aceitos',
            count: counters.agendados,
            id: 'aceito',
            active: true,
            size: 2,
            action: () => changeModo('aceito'),
        },
        {
            color: '#e41618',
            icon: <EventBusyIcon />,
            name: 'Cancelados',
            count: counters.cancelados,
            id: 'cancelado',
            active: true,
            size: 2,
            action: () => changeModo('cancelado'),
        },
        {
            color: '#24ae27',
            icon: <CheckIcon />,
            name: 'Concluídos',
            count: counters.completos,
            id: 'completo',
            active: true,
            size: 2,
            action: () => changeModo('completo'),
        },
        {
            color: '#9c134b',
            icon: <CloseIcon />,
            name: 'Faltas',
            count: counters.faltas,
            id: 'falta',
            active: true,
            size: 2,
            action: () => changeModo('falta'),
        },
        /* {
            color: '#00a000',
            icon: <AddIcon />,
            name: 'Novo',
            count: -1,
            id: 10,
            active: true,
            size: 2,
            action: () => history.push(`/agendamento/nova/${mode}/${local}`),
        }, */
    ];

    if (mode === 'local') {
        actions.push({
            color: 'forestgreen',
            icon: <SettingsIcon />,
            name: 'Configuração',
            count: -1,
            id: 6,
            active: true,
            size: 2,
            action: () => history.push(`/agendamento/gerenciar/${mode}/${local}`),
        });
        actions.push({
            color: '#2d2dc3',
            icon: <DescriptionIcon />,
            name: 'Relatório',
            count: -1,
            id: 7,
            active: true,
            size: 2,
            action: () => setOpenRelatorioDia(true),
        });
    } else {
        actions.push({
            color: 'forestgreen',
            icon: <DescriptionIcon />,
            name: 'Relatório',
            count: -1,
            id: 7,
            active: true,
            size: 2,
            action: () => setRelatorio(true),
        });
    }
    return (
        <div className="app-wrapper">
            <Grid container spacing={1}>
                {actions.map(item => (
                    <Grid
                        item
                        key={item.id}
                        className={`rounded-top pl-1 pr-1`}
                        xs={item.size}
                        style={{ backgroundColor: item.id === modo ? '#FF751888' : '' }}>
                        <Card
                            onClick={item.action}
                            style={{ cursor: 'pointer', background: item.active ? item.color : 'grey' }}>
                            <Box display="flex" alignItems="center" p={3} color="white">
                                <div className="mr-3">{item.icon}</div>
                                {item.count > -1 ? (
                                    <Box display="flex" flexDirection="column">
                                        <h1>{item.count}</h1>
                                        <p>{item.name}</p>
                                    </Box>
                                ) : (
                                    <Box display="flex">
                                        <h1>{item.name}</h1>
                                    </Box>
                                )}
                            </Box>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={5}>
                        <KeyboardDatePicker
                            margin="normal"
                            fullWidth
                            inputVariant="outlined"
                            value={start}
                            emptyLabel="É preciso inserir uma data"
                            invalidDateMessage="A data é inválida"
                            label="Data inicial"
                            format="dd/MM/yyyy"
                            disabled={view === 'calendar'}
                            onChange={e => {
                                setStart(e);
                                if (moment(e).isAfter(end)) {
                                    setEnd(e);
                                }
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <KeyboardDatePicker
                            margin="normal"
                            fullWidth
                            inputVariant="outlined"
                            value={end}
                            emptyLabel="É preciso inserir uma data"
                            invalidDateMessage="A data é inválida"
                            minDate={start}
                            disabled={view === 'calendar'}
                            minDateMessage={'A data tem que ser após a data inicial'}
                            label="Data final"
                            format="dd/MM/yyyy"
                            onChange={e => setEnd(e)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Box display="flex" justifyContent="end" alignItems="center" mt={2}>
                            <ButtonGroup color="primary" style={{ backgroundColor: 'white' }}>
                                <Button
                                    onClick={() => {
                                        setView('calendar');
                                        setSelectedView('week');
                                        setStart(
                                            moment()
                                                .startOf('week')
                                                .toDate(),
                                        );
                                        setEnd(
                                            moment()
                                                .endOf('week')
                                                .toDate(),
                                        );
                                    }}
                                    variant={view === 'calendar' ? 'contained' : 'outlined'}>
                                    <CalendarTodayIcon />
                                </Button>
                                <Button onClick={() => setView('list')} variant={view === 'list' ? 'contained' : 'outlined'}>
                                    <ListIcon />
                                </Button>
                            </ButtonGroup>
                        </Box>
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
            <Box mt={3}>
                {view === 'list' ? (
                    <MaterialTable
                        data={events}
                        title="Agendamentos"
                        localization={tableLocalization}
                        icons={tableIcons}
                        columns={[
                            {
                                title: 'Usuário',
                                field: 'nome',
                            },
                            {
                                title: 'Serviço',
                                field: 'servico',
                            },
                            {
                                title: 'Dia',
                                field: 'start',
                                render: rowData => {
                                    return moment(rowData.start).format('DD/MM/YYYY');
                                },
                            },
                            {
                                title: 'Horário',
                                field: 'start',
                                render: rowData => {
                                    if (rowData?.hora?.periodo) {
                                        return `${rowData?.hora?.periodo}`;
                                    }
                                    return `${moment(rowData.start).format('HH:mm')} às ${moment(rowData.end).format(
                                        'HH:mm',
                                    )}`;
                                },
                            },
                            {
                                title: 'Status',
                                field: 'status',
                                render: rowData => {
                                    return (
                                        <div className={`rounded pl-1 pr-1`}>
                                            <b>{rowData.status}</b>
                                        </div>
                                    );
                                },
                            },
                        ]}
                        actions={[
                            {
                                icon: () => <VisibilityIcon />,
                                tooltip: 'Visualizar',
                                onClick: (_event, rowData) => handleEvent(rowData),
                            },
                        ]}
                        onRowClick={(_event, rowData) => handleEvent(rowData)}
                    />
                ) : (
                    <Card className="p-2">
                        <Calendar
                            eventPropGetter={handleColors}
                            onRangeChange={handleChangeView}
                            events={events}
                            localizer={localizer}
                            defaultView="week"
                            culture="pt-br"
                            step={5}
                            min={new Date(2000, 1, 1, 6)}
                            max={new Date(2000, 1, 1, 20)}
                            views={{ month: true, week: true, day: true }}
                            messages={{
                                next: 'Próximo',
                                previous: 'Anterior',
                                today: 'Hoje',
                                month: 'Mês',
                                day: 'Dia',
                                week: 'Semana',
                                showMore: number => `(${number}) Exibir mais...`,
                            }}
                            scrollToTime={new Date(2000, 1, 1, 6)}
                            defaultDate={new Date()}
                            onSelectEvent={handleEvent}
                        />
                    </Card>
                )}
            </Box>
            <Backdrop open={loading} style={{ zIndex: 999 }}>
                <CircularProgress />
            </Backdrop>
            <AgendaCard
                open={modal}
                onDone={onDone}
                data={selectedEvent}
                modo={selectedEvent.status}
                local={{ modo: mode, id: local }}
            />
            <Modal open={relatorio} onClose={() => setRelatorio(false)}>
                <RelatorioAgendamento
                    onClose={() => setRelatorio(false)}
                    local={{ modo: mode, id: local }}
                    start={start}
                    end={end}
                />
            </Modal>
            <RelatorioDia
                open={openRelatorioDia}
                onClose={() => setOpenRelatorioDia(false)}
                start={start}
                end={end}
                events={events}
                servicos={servicos}
            />
        </div>
    );
}

export default Agendas;
