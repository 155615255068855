import React, { useState } from 'react';
import { Chip, Input } from '@material-ui/core';

export default function ChipBox({ value, onChange, label, ...props }) {
    const [name, setName] = useState('');

    const handleRequestDelete = pos => {
        onChange(value.filter((_, i) => i !== pos));
    };

    const onValueChange = e => {
        var hold = e.target.value.replace(',', '');
        setName(hold);
    };

    const _handleKeyPress = e => {
        if (e.key === 'Backspace' && e.target.value.length === 0 && value.length > 0) {
            const hold = [...value];
            setName(hold.pop() ?? '');
            onChange(hold);
        }
        if ((e.key === 'Enter' || e.key === ',') && e.target.value.length > 0) {
            onChange([...value, e.target.value]);
        }
    };

    return (
        <div className="manage-margin d-flex flex-wrap">
            {value.map((data, pos) => {
                return <Chip label={data} key={pos} onDelete={() => handleRequestDelete(pos)} className="chip-outline" />;
            })}
            <Input type="text" placeholder={label} onChange={onValueChange} value={name} onKeyUp={_handleKeyPress} />
        </div>
    );
}
