import React, { useState } from 'react';
import { Box, Icon, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        width: 'fit-content',
        margin: '0 auto',
        '& .count': {
            zIndex: 2,
            justifyContent: 'center',
            alignItems: 'center',
            width: '25px',
            height: '25px',
            padding: '0.25rem',
            position: 'absolute',
            top: '-7px',
            right: '7px',
            border: '1px solid rgba(150, 127, 14, 0.25)',
            borderRadius: '50%',
            backgroundColor: 'rgba(230, 230, 16, 1)',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
        },
        '& .count > span': {
            fontWeight: '700',
            fontSize: '11px',
            textAlign: 'center',
            color: 'black',
        },
    },
    customCardRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '0.5rem',
        overflow: 'hidden',
        height: '200px',
        width: '400px',
        margin: '1rem',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    customCardHeader: {
        width: '100%',
        backgroundColor: ({ color }) => color,
        padding: '0.5rem 2rem',
        height: '20%',
        fontSize: '0.95rem',
        fontWeight: 900,
        textTransform: 'uppercase',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    customCardData: {
        width: '100%',
        height: '100%',
        padding: '0.5rem 1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& > *': {
            margin: '0.25rem 0',
        },
    },
    between: {
        width: '100%',
        justifyContent: 'space-between',
    },
}));

const SpecialCard = ({ color, title, handleClick, data }) => {
    const [elevation, setElevation] = useState(2);
    const classes = useStyles({ color });

    return (
        <Box className={classes.root} onClick={handleClick}>
            <Paper
                className={classes.customCardRoot}
                elevation={elevation}
                onMouseOver={() => setElevation(8)}
                onMouseOut={() => setElevation(2)}>
                <Box className={classes.customCardHeader}>
                    <Box>{title}</Box>
                </Box>
                <Box className={classes.customCardData}>
                    <Box display="flex">
                        {data.map(item => (
                            <Box display="flex" alignItems="center">
                                <Icon>{item.icon ?? 'check'}</Icon>
                                <Box fontWeight={500} ml={2}>
                                    {item.title}:
                                </Box>
                                <Box fontWeight={700} ml={2}>
                                    {item.value ? item.value : 0}
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};

export default SpecialCard;
