import React from 'react';
import 'date-fns';
import localeptBR from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

export default function SimpleDatePicker({ date, handleDate, label, tabindex, ...props }) {
    const handleDateChange = newDate => {
        handleDate(newDate);
    };

    return (
        <MuiPickersUtilsProvider locale={localeptBR} utils={DateFnsUtils}>
            <KeyboardDatePicker
                variant="inline"
                format="dd/MM/yyyy"
                margin="dense"
                label={label ? label : ''}
                value={date}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'mudar data',
                }}
                InputAdornmentProps={{ tabIndex: tabindex }}
                {...props}
            />
        </MuiPickersUtilsProvider>
    );
}
