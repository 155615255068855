import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initializeOcurrences } from '../../../redux/actions/f5-actions/ocurrences-list-init';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Button, CircularProgress } from '@material-ui/core';
import GridContainer from '../../../@jumbo/components/GridContainer';
import GeneralStatistics from './GeneralStatistics';
import GeneralStatisticsFull from './GeneralStatisticsFull';
import GeneralDailyGraph from './GeneralDailyGraph';
import GeneralCalendarPicker from './GeneralCalendarPicker';
import GeneralMap from './GeneralMap';
import GeneralMapActions from './GeneralMapActions';
import GeneralOverviewCard from './GeneralOverviewCard';
import CreateIcon from '@material-ui/icons/Create';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import GeneralSecretaryStats from './GeneralSecretaryStats';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  createButtonRoot: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    color: 'white',
    backgroundColor: 'rgba(16, 102, 39, 0.9)',
    '&:hover': {
      backgroundColor: 'rgba(39, 130, 60, 0.9)',
    },
    width: '100%',
  },
  protocolButtonRoot: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    color: 'white',
    backgroundColor: 'rgba(235, 64, 52, 0.9)',
    '&:hover': {
      backgroundColor: 'rgba(237, 102, 64, 1)',
    },
    width: '100%',
  },
}));

const GeneralOcurrences = ({ renderSubs, renderSubsLoader }) => {
  const userAccess = useSelector(({ auth }) => (auth.authUser ? auth.authUser.cargo.interno : null));
  const receivedData = useSelector(({ ocurrences_data }) => ocurrences_data.received.caixa);
  const pendingData = useSelector(({ ocurrences_data }) => ocurrences_data.pending.caixa);
  const ongoingData = useSelector(({ ocurrences_data }) => ocurrences_data.ongoing.caixa);
  const completedData = useSelector(({ ocurrences_data }) => ocurrences_data.completed.caixa);
  const extendData = useSelector(({ ocurrences_data }) => ocurrences_data.extend.caixa);
  const extendAcceptedData = useSelector(({ ocurrences_data }) => ocurrences_data.extend_accepted.caixa);
  const extendRejectedData = useSelector(({ ocurrences_data }) => ocurrences_data.extend_rejected.caixa);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeOcurrences());
  }, [dispatch]);

  const classes = useStyles();
  let history = useHistory();

  const newOcurrenceRedirect = () => {
    history.push('/nova');
  };

  const protocolOcurrenceRedirect = () => {
    history.push('/pesquisar?modo=protocol');
  };

  const graphsData = {
    received: {
      colors: {
        background: 'rgba(85, 148, 171, 0.85)',
        fill: 'rgba(85, 148, 171, 1)',
        stroke: 'rgba(255, 255, 255, 0.15)',
      },
      title: 'Ocorrências Recebidas',
    },
    receivedFull: {
      colors: {
        background: 'rgba(61, 61, 53, 0.85)',
        fill: 'rgba(61, 61, 53, 1)',
        stroke: 'rgba(255, 255, 255, 0.15)',
      },
      title: 'Todas Ocorrências Recebidas',
    },
    pending: {
      colors: {
        background: 'rgba(171, 132, 85, 0.85)',
        fill: 'rgba(171, 132, 85, 1)',
        stroke: 'rgba(255, 255, 255, 0.15)',
      },
      title: 'Ocorrências Pendentes',
    },
    ongoing: {
      colors: {
        background: 'rgba(84, 171, 156, 0.85)',
        fill: 'rgba(84, 171, 156, 1)',
        stroke: 'rgba(255, 255, 255, 0.15)',
      },
      title: ['gestao', 'funcionario', 'departamento', 'sub_departamento'].includes(userAccess)
        ? 'Ocorrências em Análise'
        : 'Ocorrências em Andamento',
    },
    completed: {
      colors: {
        background: 'rgba(84, 171, 100, 0.85)',
        fill: 'rgba(84, 171, 100, 1)',
        stroke: 'rgba(255, 255, 255, 0.15)',
      },
      title: 'Ocorrências Finalizadas',
    },
  };

  const graphsDataMaster = {
    extend: {
      colors: {
        background: 'rgba(68, 141, 156, 0.85)',
        fill: 'rgba(68, 141, 156, 1)',
        stroke: 'rgba(255, 255, 255, 0.15)',
      },
      title: 'Prorrogações Pendentes',
    },
    extend_accepted: {
      colors: {
        background: 'rgba(33, 176, 43, 0.85)',
        fill: 'rgba(33, 176, 43, 1)',
        stroke: 'rgba(255, 255, 255, 0.15)',
      },
      title: 'Prorrogações Aceitas',
    },
    extend_rejected: {
      colors: {
        background: 'rgba(158, 40, 43, 0.85)',
        fill: 'rgba(158, 40, 43, 1)',
        stroke: 'rgba(255, 255, 255, 0.15)',
      },
      title: 'Prorrogações Rejeitadas',
    },
  };

  const adpatedGraphs = () => {
    if (userAccess === 'triagem' || userAccess === 'ouvidor') {
      return (
        <React.Fragment>
          <Grid item xs={12} lg={4}>
            <GeneralStatisticsFull
              page="/ocorrencias/recebidas"
              data={receivedData}
              colors={graphsData.receivedFull.colors}
              title={graphsData.receivedFull.title}
              isFull={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <GeneralStatistics
              page="/ocorrencias/recebidas"
              data={receivedData}
              colors={graphsData.received.colors}
              title={graphsData.received.title}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <GeneralStatistics
              page="/ocorrencias/pendentes"
              data={pendingData}
              colors={graphsData.pending.colors}
              title={graphsData.pending.title}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <GeneralStatistics
              page="/ocorrencias/andamento"
              data={ongoingData}
              colors={graphsData.ongoing.colors}
              title={graphsData.ongoing.title}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <GeneralStatistics
              page="/ocorrencias/finalizadas"
              data={completedData}
              colors={graphsData.completed.colors}
              title={graphsData.completed.title}
            />
          </Grid>
        </React.Fragment>
      );
    } else if (['gestao', 'funcionario', 'departamento', 'sub_departamento'].includes(userAccess)) {
      return (
        <React.Fragment>
          <Grid item xs={12} sm={4}>
            <GeneralStatisticsFull
              page="/ocorrencias/recebidas"
              data={receivedData}
              colors={graphsData.receivedFull.colors}
              title={graphsData.receivedFull.title}
              isFull={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <GeneralStatistics
              page="/ocorrencias/recebidas"
              data={receivedData}
              colors={graphsData.received.colors}
              title={graphsData.received.title}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <GeneralStatistics
              page="/ocorrencias/andamento"
              data={ongoingData}
              colors={graphsData.ongoing.colors}
              title={graphsData.ongoing.title}
            />
          </Grid>
        </React.Fragment>
      );
    } else if (userAccess === 'ouv_master') {
      return (
        <React.Fragment>
          <Grid item xs={12} sm={6} md={3}>
            <GeneralStatisticsFull
              page="/ocorrencias/recebidas"
              data={receivedData}
              colors={graphsData.receivedFull.colors}
              title={graphsData.receivedFull.title}
              isFull={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <GeneralStatistics
              page="/ocorrencias/prorrogacoes"
              data={extendData}
              colors={graphsDataMaster.extend.colors}
              title={graphsDataMaster.extend.title}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <GeneralStatistics
              page="/ocorrencias/prorrogacoes?v=extend_accepted"
              data={extendAcceptedData}
              colors={graphsDataMaster.extend_accepted.colors}
              title={graphsDataMaster.extend_accepted.title}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <GeneralStatistics
              page="/ocorrencias/prorrogacoes?v=extend_rejected"
              data={extendRejectedData}
              colors={graphsDataMaster.extend_rejected.colors}
              title={graphsDataMaster.extend_rejected.title}
            />
          </Grid>
        </React.Fragment>
      );
    }
  };

  const loader = () => {
    return (
      <Box width="100%" height={150} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <CircularProgress />
        <Box fontWeight={700} fontSize={12}>
          Carregando dados de subdepartamentos...
        </Box>
      </Box>
    );
  };

  const adaptedSubDepartment = () => {
    if (renderSubs.length > 0) {
      return (
        <React.Fragment>
          <Grid item xs={12} lg={3}>
            <Box display="flex" flexDirection="column" height="100%">
              <Box mb={2} mx="auto" width="100%">
                <Button
                  classes={{
                    root: classes.createButtonRoot,
                  }}
                  size="large"
                  variant="contained"
                  onClick={newOcurrenceRedirect}
                  children={
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <CreateIcon style={{ fontSize: 28 }} />
                      <Box mt={1}>CADASTRAR NOVA OCORRÊNCIA</Box>
                    </Box>
                  }
                />
              </Box>
              <Box mb={2} mx="auto" width="100%">
                <Button
                  classes={{
                    root: classes.protocolButtonRoot,
                  }}
                  size="large"
                  variant="contained"
                  onClick={protocolOcurrenceRedirect}
                  children={
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <FindInPageIcon style={{ fontSize: 28 }} />
                      <Box mt={1}>PROCURAR POR PROTOCOLO</Box>
                    </Box>
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Grid container spacing={6} item xs={12} lg={9}>
            {adpatedGraphs()}
          </Grid>
          <Grid container item xs={12}>
            <Grid container item xs={12} lg={9}>
              {renderSubs.map(item => (
                <Grid key={item.id} item xs={6} sm={4} md={3}>
                  <GeneralSecretaryStats
                    title={item.nome}
                    positiveValue={item.like}
                    negativeValue={item.dislike}
                    uniqueId={item.id}
                    newTag={item.novas}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12} lg={3}>
              <GeneralCalendarPicker />
            </Grid>
          </Grid>
        </React.Fragment>
      );
    } else {
      // doesn't have subdepartments
      return (
        <React.Fragment>
          <Grid item container spacing={6} xs={12}>
            {adpatedGraphs()}
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box display="flex" flexDirection="column" height="100%">
              <Box mb={2} mx="auto" width="100%">
                <Button
                  classes={{
                    root: classes.createButtonRoot,
                  }}
                  size="large"
                  variant="contained"
                  onClick={newOcurrenceRedirect}
                  children={
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <CreateIcon style={{ fontSize: 28 }} />
                      <Box mt={1}>CADASTRAR NOVA OCORRÊNCIA</Box>
                    </Box>
                  }
                />
              </Box>
              <Box mb={2} mx="auto" width="100%">
                <Button
                  classes={{
                    root: classes.protocolButtonRoot,
                  }}
                  size="large"
                  variant="contained"
                  onClick={protocolOcurrenceRedirect}
                  children={
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <FindInPageIcon style={{ fontSize: 28 }} />
                      <Box mt={1}>PROCURAR POR PROTOCOLO</Box>
                    </Box>
                  }
                />
              </Box>
              <GeneralOverviewCard />
            </Box>
          </Grid>
          <Grid container spacing={6} item xs={12} lg={9}>
            <Grid item xs={12} md={8}>
              <GeneralDailyGraph boxHeight={350} />
            </Grid>
            <Grid item xs={12} md={4}>
              <GeneralCalendarPicker />
            </Grid>
          </Grid>
        </React.Fragment>
      );
    }
  };

  const ouvMasterSubs = () => {
    if (userAccess === 'ouv_master') {
      return renderSubsLoader ? (
        loader()
      ) : (
        <React.Fragment>
          {renderSubs
            .filter(item => item.admin === 0 && item.ouvidoria === 0)
            .map(item => (
              <Grid key={item.id} item xs={6} sm={4} md={3}>
                <GeneralSecretaryStats
                  title={item.nome}
                  positiveValue={item.like}
                  negativeValue={item.dislike}
                  uniqueId={item.id}
                  newTag={item.novas}
                />
              </Grid>
            ))}
        </React.Fragment>
      );
    }
  };

  const adaptedInfo = () => {
    if (['gestao', 'funcionario', 'departamento', 'sub_departamento'].includes(userAccess)) {
      return renderSubsLoader ? loader() : adaptedSubDepartment();
    }

    return (
      <React.Fragment>
        <Grid item container spacing={4} xs={12}>
          {adpatedGraphs()}
        </Grid>
        <Grid item xs={12} lg={3}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box mb={2} mx="auto" width="100%">
              <Button
                classes={{
                  root: classes.createButtonRoot,
                }}
                size="large"
                variant="contained"
                onClick={newOcurrenceRedirect}
                children={
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <CreateIcon style={{ fontSize: 28 }} />
                    <Box mt={1}>CADASTRAR NOVA OCORRÊNCIA</Box>
                  </Box>
                }
              />
            </Box>
            <Box mb={2} mx="auto" width="100%">
              <Button
                classes={{
                  root: classes.protocolButtonRoot,
                }}
                size="large"
                variant="contained"
                onClick={protocolOcurrenceRedirect}
                children={
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <FindInPageIcon style={{ fontSize: 28 }} />
                    <Box mt={1}>PROCURAR POR PROTOCOLO</Box>
                  </Box>
                }
              />
            </Box>
            <GeneralOverviewCard />
          </Box>
        </Grid>
        <Grid container spacing={6} item xs={12} lg={9}>
          <Grid item xs={12} md={8}>
            <GeneralDailyGraph boxHeight={350} />
          </Grid>
          <Grid item xs={12} md={4}>
            <GeneralCalendarPicker />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  // TODO: Corrigir o espaçamento extra sendo causado pelo spacing no grid.
  return (
    <GridContainer>
      {adaptedInfo()}
      <Grid item xs={12} sm={7} md={8}>
        <GeneralMap />
      </Grid>
      <Grid item xs={12} sm={5} md={4}>
        <GeneralMapActions />
      </Grid>
      {ouvMasterSubs()}
    </GridContainer>
  );
};

export default GeneralOcurrences;
