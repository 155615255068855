import React, { useState, useEffect } from 'react';
import {
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Box,
    Backdrop,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import axios_instance from '../../../../services/auth/jwt/config';
import sweetAlert from '../../../../f5/utils/sweetAlert';
import AnexoImage from './AnexoImage';

export default function AgendaCard({ data, modo, local, open, onDone, ...props }) {
    const [date, setDate] = useState(null);
    const [hour, setHour] = useState(null);
    const [place, setPlace] = useState({ id: 0 });
    const [places, setPlaces] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ask, setAsk] = useState({ open: false, title: '', message: '', input: false, mode: '' });
    const [justificativa, setJustificativa] = useState('');

    useEffect(() => {
        if (data) {
            setDate(moment(data.start, 'YYYY-MM-DDThh:mm:ss').toDate());
            setHour(moment(data.start, 'YYYY-MM-DDThh:mm:ss').toDate());
            setPlace(data?.local_obj);
        }
    }, [data]);

    useEffect(() => {
        axios_instance.get(`locais/list/from/${local.modo}/${local.id}`).then(res => {
            let r = res.data;
            if (r.success) {
                setPlaces(r.items);
            }
        });
    }, [local]);

    const updateModo = type => {
        setLoading(true);
        const form = {
            newtype: type,
            justificativa: justificativa,
        };
        setAsk({ open: false });
        axios_instance
            .post(`agendamento/${local.modo}/${local.id}/alterar/${data.id}`, form)
            .then(res => {
                let r = res.data;
                if (r.success) {
                    sweetAlert('success', 'Sucesso', 'Status do agendamento alterado com sucesso', () => {
                        setJustificativa('');
                        onDone(true);
                    });
                } else {
                    sweetAlert('error', 'Erro', r.message);
                }
                setLoading(false);
            })
            .catch(() => {
                sweetAlert('error', 'Erro', 'Erro ao alterar o status do agendamento');
                setLoading(false);
            });
    };

    const updateDate = type => {
        setLoading(true);
        let form = {
            date: moment(date).format('YYYY-MM-DD'),
            hour: moment(hour).format('HH:mm:ss'),
            local: place?.id,
            newtype: type,
        };
        axios_instance
            .post(`agendamento/${local.modo}/${local.id}/alterar/${data.id}`, form)
            .then(res => {
                let r = res.data;
                if (r.success) {
                    sweetAlert('success', 'Sucesso', 'Status do agendamento alterado com sucesso', () => {
                        onDone(true);
                    });
                } else {
                    sweetAlert('error', 'Erro', r.message);
                }
                setLoading(false);
            })
            .catch(() => {
                sweetAlert('error', 'Erro', 'Erro ao alterar o status do agendamento');
                setLoading(false);
            });
    };

    return (
        <div>
            <Backdrop open={loading} style={{ zIndex: 9999 }}>
                <div style={{ color: '#fff' }}>Carregando...</div>
            </Backdrop>
            <Dialog open={open} onClose={onDone} maxWidth="lg" fullWidth>
                <DialogTitle>
                    <h3>
                        <b>Agendamento #{data.id}</b>
                    </h3>
                </DialogTitle>
                <DialogContent>
                    <div>{data.title}</div>
                    <div>
                        <b style={{ marginRight: 5 }}>CPF:</b>
                        {data?.usuario?.cpf ?? 'Não Informado'}
                    </div>
                    <div>
                        <b style={{ marginRight: 5 }}>Celular:</b>
                        {data?.usuario?.celular ?? 'Não Informado'}
                    </div>
                    <div>
                        <b style={{ marginRight: 5 }}>Endereço:</b>
                        {`${data?.usuario?.endereco}, ${data?.usuario?.numero ?? ''} - ${data?.usuario?.bairro_string ??
                            ''}`}
                    </div>

                    {data?.obs?.length > 0 ? (
                        <div>
                            <h4>
                                <b>Outras Informações: </b>
                            </h4>
                            {data?.obs.map((item, pos) => (
                                <div key={pos}>
                                    <b style={{ marginRight: 5 }}>{item.name}: </b>
                                    {item.value}
                                </div>
                            ))}
                        </div>
                    ) : null}

                    {data?.anexos?.length > 0 ? (
                        <div>
                            <h4>
                                <b>Anexos: </b>
                            </h4>
                            {data?.anexos?.map((item, pos) => (
                                <AnexoImage id={item.id} mode={local.modo} local={local.id} key={pos} />
                            ))}
                        </div>
                    ) : null}

                    <TextField value={data.servico} label="Serviço" disabled fullWidth margin="normal" />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Local de Atendimento</InputLabel>
                        <Select value={place?.id} onChange={e => setPlace({ id: e.target.value })}>
                            {places.map(item => (
                                <MenuItem value={item.id}>{item.nome}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                        <KeyboardDatePicker
                            margin="normal"
                            fullWidth
                            value={date}
                            onChange={e => setDate(e)}
                            emptyLabel="É preciso inserir uma data"
                            invalidDateMessage="A data é inválida"
                            minDateMessage={'A data tem que ser no futuro'}
                            label="Data do agendamento"
                            format="dd/MM/yyyy"
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                        <KeyboardTimePicker
                            margin="normal"
                            fullWidth
                            value={hour}
                            onChange={e => setHour(e)}
                            emptyLabel="É preciso inserir um horário"
                            invalidDateMessage="O horário é inválida"
                            label="Horário do agendamento"
                            format="HH:mm"
                            KeyboardButtonProps={{
                                'aria-label': 'change hour',
                            }}
                            ampm={false}
                        />
                    </MuiPickersUtilsProvider>
                </DialogContent>
                <DialogActions>
                    <Box display="flex" justifyContent="space-between" width="100%">
                        {modo !== 'Cancelado' ? (
                            <>
                                <div>
                                    <Button
                                        onClick={() => {
                                            setAsk({
                                                open: true,
                                                title: 'Cancelar Agendamento',
                                                message: 'Tem certeza que deseja cancelar o agendamento?',
                                                input: true,
                                                mode: 'cancela',
                                            });
                                        }}
                                        color="secondary"
                                        variant="contained">
                                        Cancelar
                                    </Button>
                                </div>
                                <div>
                                    <Button onClick={() => updateModo('falta')} color="secondary" variant="contained">
                                        Faltou
                                    </Button>
                                </div>
                            </>
                        ) : null}
                        <div>
                            {modo === 'Aceito' ? (
                                <Button onClick={() => updateDate('aceita')} color="primary" variant="contained">
                                    Atualizar Dados
                                </Button>
                            ) : null}
                        </div>
                        <div>
                            {modo === 'Pendente' ? (
                                <Button onClick={() => updateDate('aceita')} color="primary" variant="contained">
                                    Aceitar Agendamento
                                </Button>
                            ) : modo === 'Aceito' ? (
                                <Button onClick={() => updateModo('confirma')} color="primary" variant="contained">
                                    Confirmar Presença
                                </Button>
                            ) : null}
                        </div>
                    </Box>
                </DialogActions>
            </Dialog>
            <Dialog open={ask.open} onClose={() => setAsk({ open: false })}>
                <DialogTitle>{ask.title}</DialogTitle>
                <DialogContent>
                    {ask.message}
                    {ask.input ? (
                        <div>
                            <TextField
                                value={justificativa}
                                onChange={e => setJustificativa(e.target.value)}
                                label="Mensagem para cancelar"
                                fullWidth
                                margin="normal"
                                multiline
                                minRows={4}
                                variant="outlined"
                            />
                        </div>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Box display="flex" justifyContent="space-between" width="100%">
                        <Button onClick={() => setAsk({ open: false })} color="primary" variant="contained">
                            Voltar
                        </Button>
                        <Button
                            onClick={() => {
                                updateModo(ask.mode);
                            }}
                            color="secondary"
                            variant="contained">
                            Confirmar
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
}
