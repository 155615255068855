import { Editor } from '@tinymce/tinymce-react';
import React from 'react';

export default function TinyEditor({ value, onChange, ...props }) {
    return (
        <Editor
            value={value}
            onEditorChange={content => onChange(content)}
            apiKey="9omwe4lp2opak7m6o9rpkf2fknnd7i0sh3wzl6syj4jl8hjt"
            init={{
                eight: 500,
                menubar: 'edit view insert',
                plugins:
                    'advlist autolink lists link image charmap print preview anchor' +
                    'searchreplace visualblocks code fullscreen' +
                    'insertdatetime media table paste code help wordcount emoticons ',
                toolbar:
                    'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | link image table emoticons ',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                images_upload_url: `/api/ckeditor/upload/imagem`,
                language: 'pt_BR',
            }}
        />
    );
}
