import React, { useCallback, useEffect, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Paper, Select } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import TrashIcon from '@material-ui/icons/Delete';
import ListIcon from '@material-ui/icons/List';
import EditarAula from './EditarAula';
import ListInscricoes from './ListInscricoes';
import MaterialTable from '@material-table/core';
import { tableIcons, tableLocalization } from '../../../@jumbo/constants/TableConstants';
import axios_instance from '../../../services/auth/jwt/config';
import sweetAlert from '../../../f5/utils/sweetAlert';
import { useParams } from 'react-router';

export default function IndexLocais({ ...props }) {
    const [locais, setLocais] = useState([]);
    const [selecionado, setSelecionado] = useState({ id: null, nome: '' });
    const [aulas, setAulas] = useState([]);
    const [loading, setLoading] = useState({ is: false, message: '' });
    const [askDelete, setAskDelete] = useState({ open: false, id: null, message: '' });
    const [editing, setEditing] = useState({ is: false, data: { id: null, nome: '', local_id: null, horarios: [] } });
    const [listing, setListing] = useState({ is: false, data: { id: null, nome: '', local_id: null, horarios: [] } });
    const { id, mode } = useParams();

    useEffect(() => {
        axios_instance.get(`aula/locais/${mode}/${id}`).then(res => {
            let r = res.data;
            if (r.success) {
                setLocais(r.data);
            }
        });
    }, [mode, id]);

    const onLoad = useCallback(() => {
        setLoading({ is: true, message: '' });
        axios_instance.get(`aula/aulas/list/${selecionado.id ? `local/${selecionado.id}` : `${mode}/${id}`}`).then(res => {
            let r = res.data;
            if (r.success) {
                setAulas(r.data);
            }
            setLoading({ is: false, message: '' });
        });
    }, [selecionado, mode, id]);

    useEffect(() => {
        onLoad();
    }, [selecionado, onLoad]);

    const onDelete = hold => {
        setLoading({ is: true, message: '' });
        setAskDelete({ open: false, id: null, message: '' });
        axios_instance.get(`aula/aula/delete/${hold.id}`).then(res => {
            let r = res.data;
            if (r.success) {
                sweetAlert('Sucesso', 'Aula excluída com sucesso!', 'success', () => {
                    onLoad();
                });
            } else {
                sweetAlert('Erro', r.message, 'error');
            }
            setLoading({ is: false, message: '' });
        });
    };

    return (
        <div>
            {aulas ? <></> : null}
            {editing.is ? (
                <EditarAula
                    data={editing.data}
                    onChange={data => setEditing({ ...editing, data })}
                    onDone={(reload = false) => {
                        setEditing({ is: false, data: { id: null, nome: '', local_id: null, horarios: [] } });
                        if (reload) {
                            onLoad();
                        }
                    }}
                    locais={locais}
                />
            ) : listing.is ? (
                <ListInscricoes
                    aula={listing.data} aulas={aulas}
                    onBack={() => setListing({ is: false, data: { id: null, nome: '', local_id: null, horarios: [] } })}
                />
            ) : (
                <Paper>
                    <Box p={3}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Local</InputLabel>
                            <Select
                                value={selecionado.id}
                                onChange={e => {
                                    const id = e.target.value;
                                    const local = locais.find(l => l.id === id);
                                    if (!local) return setSelecionado({ id: null, nome: '' });
                                    setSelecionado(local);
                                }}>
                                <MenuItem value={0}>Selecione um local</MenuItem>
                                {locais.map(l => (
                                    <MenuItem key={l.id} value={l.id}>
                                        {l.nome}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <MaterialTable
                            title={`Aulas${selecionado.nome ? ` - ${selecionado.nome}` : ''}`}
                            columns={[
                                { title: 'Nome', field: 'nome' },
                                { title: 'Local', field: 'local.nome' },
                                { title: 'Inscrições Pendentes', field: 'inscricoes_count' },
                            ]}
                            components={{ Container: props => <div {...props} /> }}
                            data={query =>
                                new Promise((resolve, reject) => {
                                    let url = `aula/aulas/list/${selecionado.id ? `local/${selecionado.id}` : `${mode}/${id}`
                                        }?`;
                                    url += 'per_page=' + query.pageSize;
                                    url += '&page=' + (query.page + 1);
                                    if (query.search !== '') {
                                        url += '&search=' + query.search;
                                    }
                                    axios_instance
                                        .get(`${url}`)
                                        .then(res => {
                                            let r = res.data;
                                            if (r.success) {
                                                resolve({
                                                    data: r.data.data,
                                                    page: r.data.current_page - 1,
                                                    totalCount: r.data.total,
                                                });
                                            } else {
                                                reject(r.message);
                                            }
                                        })
                                        .catch(err => {
                                            reject(err.message);
                                        });
                                })
                            }
                            options={{
                                actionsColumnIndex: -1,
                                pageSize: 20,
                                rowStyle: rowData => ({
                                    fontWeight: rowData.inscricoes_count > 0 ? 'bold' : 'normal',
                                    borderLeft: rowData.inscricoes_count > 0 ? '4px solid #f44336' : 'none',
                                    borderRight: rowData.inscricoes_count > 0 ? '4px solid #f44336' : 'none',
                                }),
                            }}
                            actions={[
                                {
                                    icon: () => <AddIcon />,
                                    tooltip: 'Adicionar aula',
                                    isFreeAction: true,
                                    onClick: () => {
                                        setEditing({
                                            is: true,
                                            data: { id: null, nome: '', local_id: selecionado.id, horarios: [] },
                                        });
                                    },
                                },
                                {
                                    icon: () => <EditIcon />,
                                    tooltip: 'Editar aula',
                                    onClick: (_event, rowData) => {
                                        setEditing({ is: true, data: rowData });
                                    },
                                },
                                {
                                    icon: () => <ListIcon />,
                                    tooltip: 'Listar inscrições',
                                    onClick: (_event, rowData) => {
                                        setListing({ is: true, data: rowData });
                                    },
                                },
                                {
                                    icon: () => <TrashIcon />,
                                    tooltip: 'Excluir aula',
                                    onClick: (_event, rowData) => {
                                        sweetAlert('warning', `Deseja excluir a aula ${rowData.nome}?`, () => {
                                            onDelete(askDelete);
                                        });
                                    },
                                },
                            ]}
                            isLoading={loading.is}
                            icons={tableIcons}
                            localization={tableLocalization}
                        />
                    </Box>
                </Paper>
            )}
        </div>
    );
}
