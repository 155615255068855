import React from 'react';
import { TextField } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MaskedInput from 'react-text-mask';
import RadioCompact from './RadioCompact';

const CPFMask = props => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => inputRef(ref ? ref.inputElement : null)}
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
        />
    );
};

function UserForm({ updateData, data, tipos, ...props }) {
    const { nome, cpf, nasc, sexo, rua, numero, bairro, cidade, email, obs, phone, pai, mae } = data;
    return (
        <div>
            <h3>Dados pessoais</h3>
            <TextField
                label="Nome"
                name="nome"
                onChange={evt => updateData(evt.target.value, 'nome')}
                fullWidth
                value={nome}
                required
                margin="normal"
            />

            <TextField
                label="CPF"
                name="cpf"
                onChange={evt => updateData(evt.target.value, 'cpf')}
                fullWidth
                value={cpf}
                required
                InputProps={{ inputComponent: CPFMask }}
                margin="normal"
            />

            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                <KeyboardDatePicker
                    margin="normal"
                    fullWidth
                    value={nasc}
                    minDate={null}
                    label="Data de nascimento"
                    format="dd/MM/yyyy"
                    required
                    onChange={e => updateData(e, 'nasc')}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
            <RadioCompact
                updateInfo={updateData}
                name="Sexo *"
                variable="sexo"
                current={sexo}
                options={{ Masculino: 'Masculino', Feminino: 'Feminino' }}
                vertical
            />
            <h3 className="mt-3">Endereço</h3>
            <div className="row">
                <div className="col-9">
                    <TextField
                        label="Rua"
                        name="rua"
                        onChange={evt => updateData(evt.target.value, 'rua')}
                        fullWidth
                        required
                        value={rua}
                        margin="normal"
                    />
                </div>
                <div className="col-3">
                    <TextField
                        label="Número"
                        name="numero"
                        onChange={evt => updateData(evt.target.value, 'numero')}
                        fullWidth
                        required
                        value={numero}
                        margin="normal"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-9">
                    <TextField
                        label="Bairro"
                        name="bairro"
                        onChange={evt => updateData(evt.target.value, 'bairro')}
                        fullWidth
                        required
                        value={bairro}
                        margin="normal"
                    />
                </div>
                <div className="col-3">
                    <TextField
                        label="Cidade"
                        name="cidade"
                        onChange={evt => updateData(evt.target.value, 'cidade')}
                        fullWidth
                        required
                        value={cidade}
                        margin="normal"
                    />
                </div>
            </div>
            <div>
                <TextField
                    label="Complemento"
                    name="obs"
                    onChange={evt => updateData(evt.target.value, 'obs')}
                    fullWidth
                    value={obs}
                    margin="normal"
                />
            </div>
            <h3 className="mt-3">Informações Complementares</h3>
            <div className="row">
                <div className="col-6">
                    <TextField
                        label="Email"
                        name="email"
                        onChange={evt => updateData(evt.target.value, 'email')}
                        fullWidth
                        value={email}
                        margin="normal"
                    />
                </div>
                <div className="col-6">
                    <TextField
                        label="Telefone"
                        name="phone"
                        onChange={evt => updateData(evt.target.value, 'phone')}
                        fullWidth
                        value={phone}
                        margin="normal"
                    />
                </div>
            </div>
            <TextField
                label="Nome Completo da mãe"
                name="mae"
                onChange={evt => updateData(evt.target.value, 'mae')}
                fullWidth
                value={mae}
                margin="normal"
            />
            <TextField
                label="Nome Completo do Pai"
                name="pai"
                onChange={evt => updateData(evt.target.value, 'pai')}
                fullWidth
                value={pai}
                margin="normal"
            />
        </div>
    );
}

export default UserForm;
