import { Box, Button, ButtonGroup, Divider, Grid, TextField } from '@material-ui/core';
import { KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import React from 'react';

const diasSemana = {
    1: 'Segunda',
    2: 'Terça',
    3: 'Quarta',
    4: 'Quinta',
    5: 'Sexta',
    6: 'Sábado',
    7: 'Domingo',
};

export default function HorarioEditar({ horario, onChange, onRemove, pos, ...props }) {
    return (
        <>
            <Box my={3}>
                <Divider />
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    <h4>Turma #{pos + 1}</h4>
                </Grid>
                <Grid item xs={4}>
                    <Button onClick={onRemove} className="jr-btn bg-danger text-white">
                        Remover
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Nome"
                        fullWidth
                        variant="outlined"
                        value={horario.nome}
                        onChange={e => onChange({ ...horario, nome: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ButtonGroup color="primary">
                        <Button
                            variant={horario.semana.includes(1) ? 'contained' : 'outlined'}
                            onClick={() =>
                                onChange({
                                    ...horario,
                                    semana: horario.semana.includes(1)
                                        ? horario.semana.filter(v => v !== 1)
                                        : [...horario.semana, 1].sort(),
                                })
                            }>
                            Segunda
                        </Button>
                        <Button
                            variant={horario.semana.includes(2) ? 'contained' : 'outlined'}
                            onClick={() =>
                                onChange({
                                    ...horario,
                                    semana: horario.semana.includes(2)
                                        ? horario.semana.filter(v => v !== 2)
                                        : [...horario.semana, 2].sort(),
                                })
                            }>
                            Terça
                        </Button>
                        <Button
                            variant={horario.semana.includes(3) ? 'contained' : 'outlined'}
                            onClick={() =>
                                onChange({
                                    ...horario,
                                    semana: horario.semana.includes(3)
                                        ? horario.semana.filter(v => v !== 3)
                                        : [...horario.semana, 3].sort(),
                                })
                            }>
                            Quarta
                        </Button>
                        <Button
                            variant={horario.semana.includes(4) ? 'contained' : 'outlined'}
                            onClick={() =>
                                onChange({
                                    ...horario,
                                    semana: horario.semana.includes(4)
                                        ? horario.semana.filter(v => v !== 4)
                                        : [...horario.semana, 4].sort(),
                                })
                            }>
                            Quinta
                        </Button>
                        <Button
                            variant={horario.semana.includes(5) ? 'contained' : 'outlined'}
                            onClick={() =>
                                onChange({
                                    ...horario,
                                    semana: horario.semana.includes(5)
                                        ? horario.semana.filter(v => v !== 5)
                                        : [...horario.semana, 5].sort(),
                                })
                            }>
                            Sexta
                        </Button>
                        <Button
                            variant={horario.semana.includes(6) ? 'contained' : 'outlined'}
                            onClick={() =>
                                onChange({
                                    ...horario,
                                    semana: horario.semana.includes(6)
                                        ? horario.semana.filter(v => v !== 6)
                                        : [...horario.semana, 6].sort(),
                                })
                            }>
                            Sábado
                        </Button>
                        <Button
                            variant={horario.semana.includes(7) ? 'contained' : 'outlined'}
                            onClick={() =>
                                onChange({
                                    ...horario,
                                    semana: horario.semana.includes(7)
                                        ? horario.semana.filter(v => v !== 7)
                                        : [...horario.semana, 7].sort(),
                                })
                            }>
                            Domingo
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
            {horario.semana.map((dia, i) => (
                <Grid container spacing={3} key={i} alignItems="center" justifyContent="center">
                    <Grid item xs={2}>
                        Horários de {diasSemana[dia]}
                    </Grid>
                    <Grid item xs={5}>
                        <KeyboardTimePicker
                            label="Início"
                            inputVariant="outlined"
                            fullWidth
                            value={horario.hora[i] ? moment(horario.hora[i], 'HH:mm').toDate() : new Date()}
                            onChange={e => {
                                let hold = [...horario.hora];
                                hold[i] = moment(e).format('HH:mm');
                                onChange({ ...horario, hora: hold });
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                            ampm={false}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <KeyboardTimePicker
                            label="Fim"
                            inputVariant="outlined"
                            fullWidth
                            value={horario.fim[i] ? moment(horario.fim[i], 'HH:mm').toDate() : new Date()}
                            onChange={e => {
                                let hold = [...horario.fim];
                                hold[i] = moment(e).format('HH:mm');
                                onChange({ ...horario, fim: hold });
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                            ampm={false}
                        />
                    </Grid>
                </Grid>
            ))}
        </>
    );
}
