import React, { useState } from 'react';
import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import SimpleCrud from '../../../f5/SimpleCrud';
import { ListAlt } from '@material-ui/icons';

interface PeriodoAuxilioProps {}

export default function PeriodoAuxilio({ ...props }: PeriodoAuxilioProps) {
    const [editing, setEditing] = useState({
        nome: '',
        start: '',
        end: '',
        file: null,
        aberto: false,
    });
    const history = useHistory();

    return (
        <SimpleCrud
            columns={[
                { title: 'Período', field: 'nome' },
                { title: 'Data Início', field: 'start', type: 'date' },
                { title: 'Data Fim', field: 'end', type: 'date' },
            ]}
            titles={{
                modal: 'Período Auxílio',
                table: 'Períodos Auxílio',
                success: 'Período Auxílio salvo com sucesso!',
                loading: 'Carregando períodos auxílio...',
                toDelete: 'Período Auxílio',
            }}
            link={{
                load: 'edu/periodo-auxilio/index',
                save: 'edu/periodo-auxilio/update',
                delete: 'edu/periodo-auxilio/delete',
            }}
            object={editing}
            onLoad={object => {
                setEditing({ ...object, file: null });
            }}
            onFinish={() => {
                setEditing({
                    nome: '',
                    start: '',
                    end: '',
                    file: null,
                    aberto: false,
                });
            }}
            onNew={() => {
                setEditing({
                    nome: '',
                    start: '',
                    end: '',
                    file: null,
                    aberto: false,
                });
            }}
            extraActions={[
                {
                    icon: () => <ListAlt />,
                    tooltip: 'Pedidos',
                    onClick: (_event, rowData) => {
                        history.push(`/auxilio/transporte/${rowData.id}`);
                    },
                },
            ]}
            formComponent={
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                label="Período"
                                value={editing.nome}
                                onChange={e => setEditing({ ...editing, nome: e.target.value })}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControlLabel
                                control={<Checkbox />}
                                label="Período Aberto"
                                checked={editing.aberto}
                                onChange={() => setEditing({ ...editing, aberto: !editing.aberto })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <KeyboardDatePicker
                                margin="normal"
                                fullWidth
                                value={moment(editing.start, 'YYYY-MM-DD').toDate()}
                                minDate={null}
                                label="Data Inicial"
                                format="dd/MM/yyyy"
                                onChange={e => {
                                    setEditing({ ...editing, start: moment(e).format('YYYY-MM-DD') });
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <KeyboardDatePicker
                                margin="normal"
                                fullWidth
                                value={moment(editing.end, 'YYYY-MM-DD').toDate()}
                                minDate={null}
                                label="Data Final"
                                format="dd/MM/yyyy"
                                onChange={e => {
                                    setEditing({ ...editing, end: moment(e).format('YYYY-MM-DD') });
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <TextField
                                label="Edital"
                                fullWidth
                                type="file"
                                onChange={e => setEditing({ ...editing, file: e.target.files[0] })}
                            />
                        </Grid> */}
                    </Grid>
                </MuiPickersUtilsProvider>
            }
        />
    );
}
