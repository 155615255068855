import React from 'react';
import { Paper, Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

const useStyles = makeStyles(theme => ({
    paperRoot: { height: '100%' },
    cardRoot: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    textGrey: {
        color: 'rgba(0, 0, 0, 0.5)',
    },
}));

const NovaCard = ({ movement }) => {
    const classes = useStyles();
    if (movement.remetente_type !== 'App\\Geral\\Ouvidoria\\Ouv_Funcionario') {
        return (
            <Paper className={classes.paperRoot}>
                <Box className={classes.cardRoot} py={2} px={4}>
                    <Box>
                        <Box display="flex" alignItems="center" my={2}>
                            <NoteAddIcon fontSize="small" />
                            <Box ml={2} fontWeight={500}>
                                Entrada da Ocorrência
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        );
    }
    return (
        <Paper className={classes.paperRoot}>
            <Box className={classes.cardRoot} py={2} px={4}>
                <Box>
                    <Box display="flex" alignItems="center" my={2}>
                        <BorderColorIcon fontSize="small" />
                        <Box ml={2} fontWeight={500}>
                            Resposta ao Recurso
                        </Box>
                    </Box>
                    <Box mb={2}>
                        <Divider />
                    </Box>
                </Box>
                <Box mb={2} display="flex" alignItems="center">
                    <Box fontWeight={700} fontSize={14}>
                        Razão da Decisão:
                    </Box>
                </Box>
                <Box ml={2} mb={2}>
                    {movement.descricao}
                </Box>
                <Box mb={2} display="flex" alignItems="center">
                    <Box fontWeight={700} fontSize={14}>
                        Observação:
                    </Box>
                    <Box ml={2}>{movement.observacao}</Box>
                </Box>
            </Box>
        </Paper>
    );
};

export default NovaCard;
