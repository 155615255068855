import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import AuxilioImage from './AuxilioImage';
import AuxilioEdit from './AuxilioEdit';
import AuxilioAprove from './AuxilioAprove';
import MaterialTable from '@material-table/core';
import { tableIcons, tableLocalization } from '../../../@jumbo/constants/TableConstants';
import { useParams } from 'react-router-dom';
import axios_instance from '../../../services/auth/jwt/config';
import { Check, CloudDownload, Delete, Edit, Description, AssignmentInd, Close, Info } from '@material-ui/icons';
import { PDFViewer } from '@react-pdf/renderer';
import AuxilioCarteira from './AuxilioCarteira';
import AuxilioDocument from './AuxilioDocument';
import sweetAlert from '../../../f5/utils/sweetAlert';

interface AuxlioTransporteProps {}

export type Auxilio = {
    id: number;
    nome?: string;
    nascimento?: string;
    cpf?: string;
    rg?: string;
    endereco?: string;
    telefone?: string;
    celular?: string;
    email?: string;
    instituicao?: string;
    curso?: string;
    rm?: string;
    enderecoInstituicao?: string;
    approved_at?: string;
    ended_at?: string;
    bairro?: string;
    conclusao_mes?: string;
    utilizou?: string;
    desde?: string;
    conclusao_ano?: string;
    duracao?: string;
    modalidade?: string;
    nivel?: string;
    semestre?: string;
    turno?: string;
    hora_entrada?: string;
    hora_saida?: string;
    inicio?: string;
    dias_semana?: Array<string>;
    bairroInstituicao?: string;
    telefoneInstituicao?: string;
    anexos?: Array<{
        id: string;
        nome: string;
        link: string;
        tipo_interno: string;
    }>;
};

type DialogData = { open: boolean; data: Auxilio };

export default function AuxlioTransporte({ ...props }: AuxlioTransporteProps) {
    const [showing, setShowing] = useState<DialogData>({ open: false, data: { id: 0 } });
    const [editing, setEditing] = useState<DialogData>({ open: false, data: { id: 0 } });
    const [askDelete, setAskDelete] = useState<DialogData>({ open: false, data: { id: 0 } });
    const [accepting, setAccepting] = useState<DialogData>({ open: false, data: { id: 0 } });
    const [openCarteirinha, setOpenCarteirinha] = useState<DialogData>({
        open: false,
        data: { id: 0 },
    });
    const [openDocument, setOpenDocument] = useState<DialogData>({ open: false, data: { id: 0 } });
    const [loading, setLoading] = useState({ is: false, message: '' });
    const { id } = useParams<{ id: string }>();

    const tableRef = React.createRef<any>();

    const onDownload = () => {
        setLoading({ is: true, message: 'Exportando...' });
        axios_instance
            .get<any>(`/edu/auxilio-transporte/export`, {
                responseType: 'blob',
            })
            .then(r => {
                let a = document.createElement('a');
                a.href = URL.createObjectURL(r.data);
                a.download = 'auxilio-transporte-' + moment().format('YYYY-MM-DD') + '.xlsx';
                a.click();
                setLoading({ is: false, message: '' });
            });
    };

    /* const onDone = (reload = false) => {
        setSuccess({ has: false, message: '' });
        setError({ has: false, message: '' });
        if (reload) {
            setEditing({ open: false, data: { id: 0 } });
            tableRef.current && tableRef.current.onQueryChange();
        }
    }; */

    const onDelete = (toDelete: DialogData) => {
        setLoading({ is: true, message: 'Excluindo...' });
        setAskDelete({ open: false, data: { id: 0 } });
        axios_instance
            .get<any>('edu/auxilio-transporte/delete/' + toDelete.data.id)
            .then(response => {
                let r = response.data;
                if (r.success) {
                    sweetAlert('success', 'Sucesso', 'Auxílio excluído com sucesso!', () => {
                        tableRef.current && tableRef.current.onQueryChange();
                    });
                } else {
                    sweetAlert('error', 'Erro', r.data.message);
                }
                setLoading({ is: false, message: '' });
            })
            .catch(() => {
                sweetAlert('error', 'Erro', 'Erro ao excluir auxílio!');
                setLoading({ is: false, message: '' });
            });
    };

    const onSaveEdit = () => {
        setLoading({ is: true, message: 'Salvando...' });
        let form = {
            data: editing.data,
        };
        axios_instance
            .post<any>(`edu/auxilio-transporte/update/${editing.data.id}`, form)
            .then(response => {
                let r = response.data;
                if (r.success) {
                    sweetAlert('success', 'Sucesso', 'Auxílio atualizado com sucesso!');
                } else {
                    sweetAlert('error', 'Erro', r.message);
                }
                setLoading({ is: false, message: '' });
            })
            .catch(() => {
                sweetAlert('error', 'Erro', 'Erro ao atualizar auxílio!');
                setLoading({ is: false, message: '' });
            });
    };

    return (
        <div className="app-wrapper">
            <MaterialTable
                localization={tableLocalization}
                icons={tableIcons}
                isLoading={loading.is}
                title="Auxílio Transporte"
                tableRef={tableRef}
                data={query =>
                    new Promise((resolve, reject) => {
                        let url = `edu/auxilio-transporte/index`;
                        url += '?per_page=' + query.pageSize;
                        url += '&page=' + (query.page + 1);
                        if (query.search) {
                            url += '&search=' + query.search;
                        }
                        if (query.orderByCollection.length > 0) {
                            url += '&order_by=' + query.orderByCollection[0].orderBy;
                            url += '&order=' + query.orderByCollection[0].orderDirection;
                        }
                        if (id) {
                            url += '&periodo=' + id;
                        }
                        axios_instance
                            .get<any>(url)
                            .then(response => {
                                let r = response.data;
                                let data = [];
                                if (r.success) {
                                    data = r?.data?.data;
                                    resolve({
                                        data: data,
                                        page: query.page,
                                        totalCount: r?.data?.total ?? 0,
                                    });
                                }
                            })
                            .catch(() => {
                                reject();
                            });
                    })
                }
                options={{
                    actionsColumnIndex: -1,
                }}
                columns={[
                    { title: 'Nome', field: 'nome' },
                    { title: 'CPF', field: 'cpf' },
                    { title: 'Aberto em', field: 'created_at', type: 'date' },
                    {
                        title: 'Status',
                        field: 'status.nome',
                    },
                ]}
                onRowClick={(_event, rowData: any) => {
                    setShowing({ open: true, data: rowData });
                }}
                actions={[
                    {
                        icon: () => <AssignmentInd />,
                        tooltip: 'Carteirinha',
                        onClick: (_event, rowData: any) => {
                            setOpenCarteirinha({ open: true, data: rowData });
                        },
                    },
                    {
                        icon: () => <Description />,
                        tooltip: 'Documento',
                        onClick: (_event, rowData: any) => {
                            setOpenDocument({ open: true, data: rowData });
                        },
                    },
                    {
                        icon: () => <Info />,
                        tooltip: 'Visualizar',
                        onClick: (_event, rowData: any) => {
                            setShowing({ open: true, data: rowData });
                        },
                    },
                    {
                        icon: () => <Edit />,
                        tooltip: 'Editar',
                        onClick: (_event, rowData: any) => {
                            setEditing({ open: true, data: rowData });
                        },
                    },
                    (row: any) => ({
                        icon: () => <Check />,
                        tooltip: 'Aceitar',
                        disabled: row.status.interno === 'aprovado',
                        onClick: (_event, rowData: any) => {
                            setAccepting({ open: true, data: rowData });
                        },
                    }),
                    {
                        icon: () => <CloudDownload />,
                        tooltip: 'Exportar',
                        isFreeAction: true,
                        onClick: () => {
                            onDownload();
                        },
                    },
                    {
                        icon: () => <Delete />,
                        tooltip: 'Excluir',
                        onClick: (_event, rowData: any) => {
                            setAskDelete({ open: true, data: rowData });
                        },
                    },
                ]}
            />
            <AuxilioEdit
                open={editing.open}
                onClose={() => setEditing({ open: false, data: { id: 0 } })}
                onSave={onSaveEdit}
                data={editing.data}
                onChange={data => setEditing({ open: true, data: data })}
            />
            <AuxilioAprove
                open={accepting.open}
                onClose={() => setAccepting({ open: false, data: { id: 0 } })}
                id={accepting.data.id}
                nome={accepting.data.nome}
            />
            <Dialog open={showing.open} onClose={() => setShowing({ open: false, data: { id: 0 } })} maxWidth="lg" fullWidth>
                <DialogTitle>Visualizar Auxílio</DialogTitle>
                <DialogContent>
                    <div>
                        <div className="row">
                            <div className="col-6">
                                <h4>Dados Pessoais</h4>
                                <div>
                                    <b>Nome:</b> {showing.data?.nome}
                                </div>
                                <div>
                                    <b>Nascimento:</b> {showing.data?.nascimento}
                                </div>
                                <div>
                                    <b>CPF:</b> {showing.data?.cpf}
                                </div>
                                <div>
                                    <b>RG:</b> {showing.data?.rg}
                                </div>
                                <div>
                                    <b>Endereço:</b> {showing.data?.endereco}
                                </div>
                                <div>
                                    <b>Telefone:</b> {showing.data?.telefone}
                                </div>
                                <div>
                                    <b>Celular:</b> {showing.data?.celular}
                                </div>
                                <div>
                                    <b>E-mail:</b> {showing.data?.email}
                                </div>
                                <div>
                                    <b>Bairro:</b> {showing.data?.bairro}
                                </div>
                                <div>
                                    <b>Conclusão do Ensino Médio:</b>
                                    {showing.data?.conclusao_mes}/{showing.data?.conclusao_ano}
                                </div>
                                <div>
                                    <b>Utilizou o Auxílio Transporte:</b> {showing.data?.utilizou ? 'Sim' : 'Não'}
                                </div>
                                <div>
                                    <b>Desde:</b> {showing.data?.desde}
                                </div>
                            </div>
                            <div className="col-6">
                                <h4 className="mt-3">Dados Institucionais</h4>
                                <div>
                                    <b>Instituição:</b> {showing.data?.instituicao}
                                </div>
                                <div>
                                    <b>Curso:</b> {showing.data?.curso}
                                </div>
                                <div>
                                    <b>RM:</b> {showing.data?.rm}
                                </div>
                                <div>
                                    <b>Endereço Instituição:</b> {showing.data?.enderecoInstituicao}
                                </div>
                                <div>
                                    <b>Bairro Instituição:</b> {showing.data?.bairroInstituicao}
                                </div>
                            </div>
                        </div>
                        <h4>Anexos (clique para baixar):</h4>
                        <Box mt={3}>
                            <Grid container spacing={3}>
                                {showing?.data?.anexos?.map(anexo => (
                                    <Grid xs={3} key={anexo.id}>
                                        <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
                                            <AuxilioImage link={anexo.link} />
                                            <p>{anexo.nome}</p>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                open={openCarteirinha.open}
                fullWidth
                maxWidth="lg"
                onClose={() => setOpenCarteirinha({ open: false, data: { id: 0 } })}>
                <DialogTitle>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        Documento
                        <IconButton onClick={() => setOpenCarteirinha({ open: false, data: { id: 0 } })}>
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent style={{ height: '80vh' }}>
                    <PDFViewer width="100%" height="100%">
                        <AuxilioCarteira data={openCarteirinha.data} />
                    </PDFViewer>
                </DialogContent>
            </Dialog>
            <Dialog
                open={openDocument.open}
                fullWidth
                maxWidth="lg"
                onClose={() => setOpenDocument({ open: false, data: { id: 0 } })}>
                <DialogTitle>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        Documento
                        <IconButton onClick={() => setOpenDocument({ open: false, data: { id: 0 } })}>
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent style={{ height: '80vh' }}>
                    <PDFViewer width="100%" height="100%">
                        <AuxilioDocument data={openDocument.data} />
                    </PDFViewer>
                </DialogContent>
            </Dialog>
            <Dialog
                open={askDelete.open}
                onClose={() => setAskDelete({ open: false, data: { id: 0 } })}
                maxWidth="xs"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Excluir Auxílio</DialogTitle>
                <DialogContent>
                    <div id="alert-dialog-description">Deseja realmente excluir o auxílio?</div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setAskDelete({ open: false, data: { id: 0 } })}>
                        Cancelar
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => onDelete(askDelete)}>
                        Excluir
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
