import React, { useEffect, useState } from 'react';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import {
    Grid,
    Paper,
    Box,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    DialogActions,
    Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import GridContainer from '../../../@jumbo/components/GridContainer';
import { useParams } from 'react-router';
import axios from '../../../services/auth/jwt/config';
import PieAndTable from './PieAndTable';
import BarAndTable from './BarAndTable';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../../../@jumbo/constants/TableConstants';
import { useSelector } from 'react-redux';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import { startOfMonth, endOfMonth, format } from 'date-fns';
import localeptBR from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';

const _titles = {
    geral: 'Geral',
    entradas: 'por Entradas',
    classificacao: 'por Classificação',
    regiao: 'por Região',
    tipo: 'por Tipo',
    pendentes: 'Pendentes',
    vencidas: 'Vencidas',
};

const _link = {
    geral: '/relatorio/mensalglobal',
    entradas: '/relatorio/mensalglobal',
    classificacao: '/relatorio/mensalglobal',
    regiao: '/relatorio/mensalglobal',
    tipo: '/relatorio/mensalglobal',
    pendentes: '/relatorio/pendentes',
    vencidas: '/relatorio/vencidas',
};

const _mode = {
    geral: '',
    entradas: '&mode=entradas',
    classificacao: '&mode=classificacao',
    regiao: '&mode=regiao',
    tipo: '&mode=tipo',
    pendentes: '',
    vencidas: '',
};

const _has = {
    geral: { month: true, year: true, departamento: true, external: true },
    entradas: { month: true, year: true, departamento: false, external: false },
    classificacao: { month: true, year: true, departamento: false, external: false },
    regiao: { month: true, year: true, departamento: false, external: false },
    tipo: { month: true, year: true, departamento: false, external: false },
    pendentes: { month: false, year: false, departamento: false, external: false },
    vencidas: { month: false, year: false, departamento: false, external: false },
};

const Report = () => {
    const [start, setStart] = useState(startOfMonth(new Date()));
    const [end, setEnd] = useState(endOfMonth(new Date()));
    const [departamento, setDepartamento] = useState(null);
    const [modal, setModal] = useState(false);
    const [obs, setObs] = useState('');
    const [data, setData] = useState(null);
    const departmentList = useSelector(({ ocurrences_map }) => ocurrences_map.departments);
    const { mode } = useParams();

    useEffect(() => {
        setData(null);
    }, [mode]);

    const openRelatorio = () => {
        let link = `https://ribasdigital.cidadeinteligente.app.br/api/ouvidoria/web${_link[mode]}`;
        axios
            .get(
                `${link}?start=${format(start, 'yyyy-MM-dd')}&end=${format(end, 'yyyy-MM-dd')}${_mode[mode]}&json=true${departamento ? `&departamento=${departamento}` : ''
                }`,
            )
            .then(r => {
                setData(r.data);
            });
    };
    const openPrintRelatorio = () => {
        let link = `https://ribasdigital.cidadeinteligente.app.br/ouvidoria${_link[mode]}`;
        window.open(
            `${link}?start=${format(start, 'yyyy-MM-dd')}&end=${format(end, 'yyyy-MM-dd')}${_mode[mode]}${departamento ? `&departamento=${departamento}` : ''
            }`,
            '_blank',
        );
    };

    const openGeralRelatorio = () => {
        let link = `https://ribasdigital.cidadeinteligente.app.br/api/ouvidoria/web${_link[mode]}`;
        let token = localStorage.getItem('token');
        fetch(
            `${link}?start=${format(start, 'yyyy-MM-dd')}&end=${format(end, 'yyyy-MM-dd')}${_mode[mode]}${departamento ? `&departamento=${departamento}` : ''
            }`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({ description: obs }),
            },
        )
            .then(r => r.blob())
            .then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.target = '_blank';
                a.click();
            });
    };

    return (
        <PageContainer>
            <GridContainer>
                <Grid item xs={12}>
                    <Paper>
                        <Box p={2}>
                            <h2>Relatório {_titles[mode]}</h2>
                            <Box mt={2}>
                                <MuiPickersUtilsProvider locale={localeptBR} utils={DateFnsUtils}>
                                    <Grid container spacing={1} alignItems="center" justifyContent="center">
                                        {_has[mode].month ? (
                                            <Grid item xs={3}>
                                                <Box width="100%" ml={4}>
                                                    <KeyboardDatePicker
                                                        fullWidth
                                                        format="dd/MM/yyyy"
                                                        value={start}
                                                        onChange={date => setStart(date)}
                                                        label="Data Inicial"
                                                        inputVariant="outlined"
                                                    />
                                                </Box>
                                            </Grid>
                                        ) : null}
                                        {_has[mode].year ? (
                                            <Grid item xs={3}>
                                                <Box width="100%" ml={4}>
                                                    <KeyboardDatePicker
                                                        fullWidth
                                                        format="dd/MM/yyyy"
                                                        value={end}
                                                        onChange={date => setEnd(date)}
                                                        label="Data Final"
                                                        inputVariant="outlined"
                                                    />
                                                </Box>
                                            </Grid>
                                        ) : null}
                                        {_has[mode].departamento ? (
                                            <Grid item xs={3}>
                                                <Box width="100%" ml={4}>
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel>Departamento</InputLabel>
                                                        <Select
                                                            label="Departamento"
                                                            value={departamento}
                                                            onChange={event => setDepartamento(event.target.value)}>
                                                            <MenuItem value={null}>Todos os Departamentos</MenuItem>
                                                            {departmentList.map((dep, index) => (
                                                                <MenuItem key={index} value={dep.id}>
                                                                    {dep.nome}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                        ) : null}
                                        <Grid item xs={3}>
                                            <Box width="100%" ml={4} justifyContent="center" display="flex">
                                                <Button variant="contained" color="primary" onClick={openRelatorio}>
                                                    Visualizar
                                                </Button>
                                                {_has[mode].external ? (
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => setModal(true)}>
                                                        Exportar
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={openPrintRelatorio}>
                                                        Versão para Imprimir
                                                    </Button>
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                {data ? (
                    <Grid xs={12}>
                        {mode === 'geral' ? (
                            <Paper>
                                <Box p={2}>
                                    <h2>
                                        Relatório {_titles[mode]} de {format(start, 'dd/MM/yyyy')} até{' '}
                                        {format(end, 'dd/MM/yyyy')}
                                    </h2>
                                    <PieAndTable
                                        data={data.entradas}
                                        title="1. Ocorrências por Meio (Entrada)"
                                        name="Entrada"
                                    />
                                    <PieAndTable
                                        data={data.gravidade}
                                        title="2. Ocorrências por Gravidade"
                                        name="Gravidade"
                                    />
                                    <BarAndTable
                                        data={data.departamento}
                                        title="3. Ocorrências por Departamentos"
                                        name="Departamento"
                                    />
                                    <BarAndTable data={data.servicos} title="4. Ocorrências por Serviços" name="Serviço" />
                                </Box>
                            </Paper>
                        ) : mode === 'pendentes' || mode === 'vencidas' ? (
                            data.ocorrencias.map(dep => (
                                <MaterialTable
                                    data={dep?.ocorrencias}
                                    icons={tableIcons}
                                    title={`Departamento: ${dep.departamento}`}
                                    columns={[
                                        { title: 'Protocolo', field: 'protocolo' },
                                        { title: 'Solicitante', field: 'solicitante' },
                                        { title: 'Serviço', field: 'servico' },
                                        {
                                            title: 'Abertura',
                                            field: 'abertura',
                                            render: data => new Date(data.abertura).toLocaleDateString(),
                                        },
                                        {
                                            title: 'Prazo',
                                            field: 'prazo',
                                            render: data => new Date(data.prazo).toLocaleDateString(),
                                        },
                                    ]}
                                />
                            ))
                        ) : null}
                    </Grid>
                ) : null}
            </GridContainer>
            <Dialog open={modal} onClose={() => setModal(false)} fullWidth>
                <DialogTitle>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={3}>
                            <h4>Exportar Relatório</h4>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton onClick={() => setModal(false)}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Box width="100%" ml={4}>
                        <Typography>Insira aqui as considerações gerais da ouvidoria municipal</Typography>
                        <TextField
                            multiline
                            minRows={4}
                            fullWidth
                            variant="outlined"
                            label="Considerações gerais"
                            value={obs}
                            onChange={e => setObs(e.target.value)}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={openGeralRelatorio}>
                        Exportar
                    </Button>
                </DialogActions>
            </Dialog>
        </PageContainer>
    );
};

export default Report;
