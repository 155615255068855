export const CityCenter = {
  lat: -20.452202385587075,
  lng: -53.76010319999856,
};

export const CityBorder = [
  { lng: -54.215, lat: -20.63 },
  { lng: -54.2, lat: -20.615 },
  { lng: -54.205, lat: -20.575 },
  { lng: -54.215, lat: -20.56 },
  { lng: -54.205, lat: -20.455 },
  { lng: -54.16, lat: -20.425 },
  { lng: -54.14, lat: -20.43 },
  { lng: -54.115, lat: -20.415 },
  { lng: -54.05, lat: -20.4 },
  { lng: -54.04, lat: -20.385 },
  { lng: -54.02, lat: -20.375 },
  { lng: -53.95, lat: -20.36 },
  { lng: -53.975, lat: -20.335 },
  { lng: -54.005, lat: -20.325 },
  { lng: -54.015, lat: -20.275 },
  { lng: -54.035, lat: -20.24 },
  { lng: -54.07, lat: -20.215 },
  { lng: -54.085, lat: -20.185 },
  { lng: -54.085, lat: -20.16 },
  { lng: -54.115, lat: -20.13 },
  { lng: -54.12, lat: -20.11 },
  { lng: -54.1, lat: -20.085 },
  { lng: -54.05, lat: -20.055 },
  { lng: -53.985, lat: -20.03 },
  { lng: -53.965, lat: -19.98 },
  { lng: -53.92, lat: -19.975 },
  { lng: -53.96, lat: -19.88 },
  { lng: -53.98, lat: -19.875 },
  { lng: -53.995, lat: -19.82 },
  { lng: -53.99, lat: -19.815 },
  { lng: -54.01, lat: -19.775 },
  { lng: -54.015, lat: -19.72 },
  { lng: -54, lat: -19.705 },
  { lng: -53.95, lat: -19.685 },
  { lng: -53.92, lat: -19.69 },
  { lng: -53.885, lat: -19.525 },
  { lng: -53.875, lat: -19.51 },
  { lng: -53.83, lat: -19.515 },
  { lng: -53.78, lat: -19.565 },
  { lng: -53.75, lat: -19.62 },
  { lng: -53.71, lat: -19.645 },
  { lng: -53.695, lat: -19.665 },
  { lng: -53.64, lat: -19.685 },
  { lng: -53.595, lat: -19.75 },
  { lng: -53.575, lat: -19.75 },
  { lng: -53.55, lat: -19.765 },
  { lng: -53.515, lat: -19.76 },
  { lng: -53.49, lat: -19.775 },
  { lng: -53.405, lat: -19.765 },
  { lng: -53.37, lat: -19.805 },
  { lng: -53.365, lat: -19.84 },
  { lng: -53.355, lat: -19.845 },
  { lng: -53.35, lat: -19.865 },
  { lng: -53.325, lat: -19.865 },
  { lng: -53.295, lat: -19.89 },
  { lng: -53.265, lat: -19.955 },
  { lng: -53.24, lat: -19.98 },
  { lng: -53.235, lat: -19.99 },
  { lng: -53.24, lat: -19.995 },
  { lng: -53.22, lat: -20 },
  { lng: -53.16, lat: -20.045 },
  { lng: -53.145, lat: -20.105 },
  { lng: -53.12, lat: -20.135 },
  { lng: -53.1, lat: -20.145 },
  { lng: -53.075, lat: -20.185 },
  { lng: -53.07, lat: -20.2 },
  { lng: -53.075, lat: -20.24 },
  { lng: -53.065, lat: -20.25 },
  { lng: -53.065, lat: -20.27 },
  { lng: -53.055, lat: -20.275 },
  { lng: -53.055, lat: -20.285 },
  { lng: -53.02, lat: -20.32 },
  { lng: -53, lat: -20.32 },
  { lng: -52.99, lat: -20.335 },
  { lng: -52.945, lat: -20.355 },
  { lng: -52.93, lat: -20.385 },
  { lng: -52.905, lat: -20.405 },
  { lng: -52.905, lat: -20.415 },
  { lng: -52.895, lat: -20.415 },
  { lng: -52.885, lat: -20.43 },
  { lng: -52.86, lat: -20.435 },
  { lng: -52.825, lat: -20.505 },
  { lng: -52.8, lat: -20.53 },
  { lng: -52.805, lat: -20.565 },
  { lng: -52.88, lat: -20.63 },
  { lng: -52.935, lat: -20.645 },
  { lng: -52.955, lat: -20.665 },
  { lng: -53.055, lat: -20.68 },
  { lng: -53.075, lat: -20.7 },
  { lng: -53.105, lat: -20.7 },
  { lng: -53.12, lat: -20.71 },
  { lng: -53.155, lat: -20.705 },
  { lng: -53.16, lat: -20.725 },
  { lng: -53.145, lat: -20.75 },
  { lng: -53.155, lat: -20.83 },
  { lng: -53.22, lat: -20.895 },
  { lng: -53.245, lat: -20.905 },
  { lng: -53.26, lat: -20.94 },
  { lng: -53.275, lat: -20.955 },
  { lng: -53.225, lat: -20.99 },
  { lng: -53.2, lat: -21.035 },
  { lng: -53.17, lat: -21.06 },
  { lng: -53.165, lat: -21.09 },
  { lng: -53.12, lat: -21.135 },
  { lng: -53.115, lat: -21.2 },
  { lng: -53.105, lat: -21.225 },
  { lng: -53.11, lat: -21.245 },
  { lng: -53.095, lat: -21.26 },
  { lng: -53.09, lat: -21.31 },
  { lng: -53.08, lat: -21.315 },
  { lng: -53.08, lat: -21.33 },
  { lng: -53.06, lat: -21.37 },
  { lng: -53.045, lat: -21.38 },
  { lng: -53.03, lat: -21.41 },
  { lng: -53.035, lat: -21.43 },
  { lng: -53.03, lat: -21.46 },
  { lng: -53.02, lat: -21.48 },
  { lng: -53.005, lat: -21.49 },
  { lng: -52.995, lat: -21.555 },
  { lng: -52.98, lat: -21.59 },
  { lng: -52.985, lat: -21.605 },
  { lng: -52.975, lat: -21.62 },
  { lng: -52.98, lat: -21.645 },
  { lng: -53, lat: -21.655 },
  { lng: -53.03, lat: -21.635 },
  { lng: -53.055, lat: -21.635 },
  { lng: -53.1, lat: -21.605 },
  { lng: -53.115, lat: -21.615 },
  { lng: -53.135, lat: -21.605 },
  { lng: -53.15, lat: -21.615 },
  { lng: -53.165, lat: -21.61 },
  { lng: -53.19, lat: -21.615 },
  { lng: -53.205, lat: -21.595 },
  { lng: -53.24, lat: -21.62 },
  { lng: -53.265, lat: -21.61 },
  { lng: -53.305, lat: -21.61 },
  { lng: -53.315, lat: -21.62 },
  { lng: -53.355, lat: -21.615 },
  { lng: -53.365, lat: -21.6 },
  { lng: -53.39, lat: -21.6 },
  { lng: -53.405, lat: -21.585 },
  { lng: -53.46, lat: -21.595 },
  { lng: -53.485, lat: -21.62 },
  { lng: -53.505, lat: -21.62 },
  { lng: -53.515, lat: -21.61 },
  { lng: -53.565, lat: -21.63 },
  { lng: -53.585, lat: -21.63 },
  { lng: -53.62, lat: -21.61 },
  { lng: -53.64, lat: -21.585 },
  { lng: -53.64, lat: -21.56 },
  { lng: -53.63, lat: -21.55 },
  { lng: -53.64, lat: -21.52 },
  { lng: -53.63, lat: -21.5 },
  { lng: -53.66, lat: -21.47 },
  { lng: -53.66, lat: -21.45 },
  { lng: -53.64, lat: -21.405 },
  { lng: -53.64, lat: -21.37 },
  { lng: -53.655, lat: -21.35 },
  { lng: -53.665, lat: -21.3 },
  { lng: -53.7, lat: -21.245 },
  { lng: -53.705, lat: -21.19 },
  { lng: -53.715, lat: -21.185 },
  { lng: -53.72, lat: -21.17 },
  { lng: -53.73, lat: -21.17 },
  { lng: -53.745, lat: -21.155 },
  { lng: -53.745, lat: -21.14 },
  { lng: -53.77, lat: -21.115 },
  { lng: -53.775, lat: -21.095 },
  { lng: -53.81, lat: -21.095 },
  { lng: -53.865, lat: -21.055 },
  { lng: -53.87, lat: -21.01 },
  { lng: -53.88, lat: -21 },
  { lng: -53.895, lat: -21 },
  { lng: -53.92, lat: -20.98 },
  { lng: -53.98, lat: -20.975 },
  { lng: -54.025, lat: -20.93 },
  { lng: -54.025, lat: -20.9 },
  { lng: -54.04, lat: -20.87 },
  { lng: -54.075, lat: -20.855 },
  { lng: -54.105, lat: -20.815 },
  { lng: -54.145, lat: -20.805 },
  { lng: -54.17, lat: -20.79 },
  { lng: -54.19, lat: -20.765 },
  { lng: -54.195, lat: -20.735 },
  { lng: -54.225, lat: -20.7 },
  { lng: -54.225, lat: -20.68 },
  { lng: -54.215, lat: -20.63 },
];
