import { Box, Button, CircularProgress, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import axios from '../../../../services/auth/jwt/config';
import SharedTitle from './SharedTitle';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import sweetAlert from '../../../utils/sweetAlert';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
}));

export default function CheckSigilo({ ocurrence, ...props }) {
    const [data, setData] = useState({});
    const [password, setPassword] = useState('');
    const [mode, setMode] = useState('password');
    const [loading, setLoading] = useState({ is: false, message: '' });

    const classes = useStyles();

    const checkUserInformation = () => {
        setLoading({ is: true, message: 'Verificando...' });
        let form = {
            password: password,
            ocorrencia_id: ocurrence.ocorrencia_id,
        };

        axios
            .post('/ouvidoria/solicitante/sigilo', form)
            .then(response => {
                let r = response.data;
                if (r.success) {
                    setData(r.solicitante);
                    setMode('data');
                } else {
                    sweetAlert('error', 'Erro', r.message);
                }
            })
            .catch(error => {
                setLoading({ is: false, message: '' });
            })
            .finally(() => {
                setLoading({ is: false, message: '' });
            });
    };

    if (loading.is) {
        return (
            <Box
                className={classes.root}
                height={500}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center">
                <CircularProgress />
                <Box mt={4} fontWeight={700}>
                    {loading.message}
                </Box>
            </Box>
        );
    }

    if (mode === 'data' && data) {
        const formattedData = [
            { field: 'Nome:', value: data.nome },
            { field: 'CPF:', value: data.cpf },
            { field: 'Data de Nascimento:', value: data.data_nascimento },
            {
                field: 'Estado Civil:',
                value: data.estado_civil ? data.estado_civil.descricao : '',
            },
            {
                field: 'Escolaridade:',
                value: data.escolaridade ? data.escolaridade.descricao : '',
            },
            { field: 'E-mail:', value: data.email },
            { field: 'Telefone:', value: data.telefone },
            { field: 'Celular:', value: data.celular },
            { field: 'Rua:', value: data.rua },
            { field: 'N°:', value: data.numero },
            { field: 'Bairro:', value: data.bairro },
            { field: 'Cidade:', value: data.cidade },
            { field: 'CEP:', value: data.cep },
            { field: 'Complemento:', value: data.complemento },
        ];
        return (
            <div className={classes.root}>
                <SharedTitle icon={<PermIdentityIcon />} title="Dados do Solicitante" />
                <Box display="flex" flexDirection="column" px={8} mb={4}>
                    {formattedData.map((item, index) => {
                        return (
                            <Box display="flex" fontSize={12} mb={1} key={index}>
                                <Box fontWeight={700}>{item.field}</Box>
                                <Box fontWeight={500} ml={1}>
                                    {item.value}
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </div>
        );
    }
    return (
        <div className={classes.root}>
            <SharedTitle icon={<PermIdentityIcon />} title="Dados do Solicitante" />
            <div>
                Ao acessar essa área você visualizará os dados do solicitante que pediu sigilo em suas informações, seja
                prudente com esses dados. O Sigilo é um direito do cidadão é só deve ser quebrado em necessidade extrema
                mediante a solicitação judicial. Digite sua senha para prosseguir:
            </div>
            <TextField
                fullWidth
                label="Senha"
                margin="normal"
                onChange={e => setPassword(e.target.value)}
                type="password"
                value={password}
            />
            <Box mt={2} px={4} display="flex" justifyContent="end">
                <Button classes={{ root: classes.saveButton }} onClick={checkUserInformation}>
                    Visualizar
                </Button>
            </Box>
        </div>
    );
}
