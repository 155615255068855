import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { GoogleMap, HeatmapLayer, Marker, Polygon } from '@react-google-maps/api';
import { Box, Paper } from '@material-ui/core';
import ExploreIcon from '@material-ui/icons/Explore';
import { parseISO, isBefore, isAfter, isEqual, addDays } from 'date-fns';
import { CityBorder, CityCenter } from '../../../f5/constants/MapData';
import { useMainContext } from '../../../redux/MainContext';

const containerStyle = {
    width: '100%',
    height: '400px',
};

const isPending = value => {
    return ['pendente', 'ouv-pendente'].includes(value);
};

const isOngoing = value => {
    return ['andamento', 'ouv-andamento'].includes(value);
};

const GeneralMap = () => {
    const [google, setGoogle] = useState(null);
    const mapMode = useSelector(({ ocurrences_map }) => ocurrences_map.mode);
    const filtersData = useSelector(({ ocurrences_map }) => ocurrences_map.filters);
    const { dashboard } = useMainContext();
    const mapData = dashboard.ocorrencias;

    if (!mapData) return null;

    const filteredMapData = () => {
        let filtered = [...mapData];

        if (filtersData.department !== 0) {
            filtered = filtered.filter(item => item.departamento_id === filtersData.department);
        }

        filtered = filtered.reduce((acc, item) => {
            if (filtersData.sectors[item.setor_id]) {
                acc.push(item);
            } else if (item.setor_id === null) {
                acc.push(item);
            }
            return acc;
        }, []);

        return filtered;
    };

    const getLocationData = style => {
        if (style === 'heat') {
            return filteredMapData().map(item => {
                return new google.maps.LatLng(item.lat, item.lng);
            });
        } else if (style === 'marker') {
            return filteredMapData().map(item => {
                let imgPath = '/156/images/f5-assets/156_verde.png';
                const deadline = parseISO(item.prazo);
                if (isPending(item.passo_interno)) {
                    imgPath = '/156/images/f5-assets/156_azul.png';
                } else if (isOngoing(item.passo_interno)) {
                    if (
                        deadline &&
                        (isBefore(deadline, addDays(new Date(), 3)) || isEqual(deadline, addDays(new Date(), 3))) &&
                        isAfter(deadline, new Date())
                    ) {
                        imgPath = '/156/images/f5-assets/156_amarelo.png';
                    } else if (deadline && (isBefore(deadline, new Date()) || isEqual(deadline, new Date()))) {
                        imgPath = '/156/images/f5-assets/156_vermelho.png';
                    } else {
                        imgPath = '/156/images/f5-assets/156_azul.png';
                    }
                }
                if ((item.lat === 0 && item.lng === 0) || (item.lat === null && item.lng === null)) {
                    return { img: imgPath, pos: new google.maps.LatLng(CityCenter) };
                }
                return { img: imgPath, pos: new google.maps.LatLng(item.lat, item.lng) };
            });
        }
    };

    const renderData = () => {
        if (mapMode === 'marker') {
            return getLocationData(mapMode).map((item, index) => <Marker icon={item.img} key={index} position={item.pos} />);
        } else if (mapMode === 'heat') {
            return <HeatmapLayer data={getLocationData(mapMode)} />;
        }
    };

    return (
        <Paper>
            <Box p={2} fontWeight={700} display="flex" alignItems="center" height={50}>
                <ExploreIcon />
                <Box ml={2}>Representação Geográfica de Ocorrências por Região</Box>
            </Box>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={CityCenter}
                zoom={13}
                onLoad={() => {
                    setGoogle(window.google);
                }}>
                {google && renderData()}
                <Polygon
                    path={CityBorder}
                    options={{
                        strokeColor: '#ff0000',
                        fillColor: '#ff000055',
                    }}
                />
            </GoogleMap>
        </Paper>
    );
};

export default GeneralMap;
