import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const generalAlert = withReactContent(Swal);

const sweetAlert = async (type, title, text, handleConfirm) => {
    const result = await generalAlert.fire({
        icon: type,
        title: title,
        text: text,
        confirmButtonText: 'Confirmar',
        denyButtonText: 'Cancelar',
        cancelButtonText: 'Fechar',
    });

    if (result.isConfirmed) {
        if (handleConfirm) {
            handleConfirm();
        }
    }
};

export default sweetAlert;
