import {
    TextField,
    Grid,
    FormControlLabel,
    Switch,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Button,
    DialogContent,
    DialogTitle,
    DialogActions,
    CircularProgress,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import sweetAlert from '../../../f5/utils/sweetAlert';
import TinyEditor from '../Schedule/components/TinyEditor';
import axios_instance from '../../../services/auth/jwt/config';

let title = {
    add: 'Adicionar Curso',
    edit: 'Editar Curso',
    none: 'Cursos',
};

export default function CursoEdit({ onClose, id = 0, mode, ...props }) {
    const [nome, setNome] = useState(null);
    const [vagas, setVagas] = useState(0);
    const [alvo, setAlvo] = useState(null);
    const [cover, setCover] = useState(null);
    const [dono, setDono] = useState(null);
    const [description, setDescription] = useState('');
    const [ilimitado, setIlimitado] = useState(false);
    const [limiteCadastro, setLimiteCadastro] = useState(null);
    const [inicio, setInicio] = useState(null);
    const [fim, setFim] = useState(null);
    const [info, setInfo] = useState(null);
    const [externa, setExterna] = useState(false);
    const [link, setLink] = useState(null);
    const [modo, setModo] = useState(null);
    const [status, setStatus] = useState(1);
    const [optionsStatus, setOptionsStatus] = useState([]);
    const [optionsModo, setOptionsModo] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        axios_instance.get(`curso/show${id > 0 ? `/${id}` : ''}`).then(res => {
            let r = res.data;
            if (r.success) {
                if (r.data) {
                    setNome(r.data.nome);
                    setVagas(r.data.vagas ?? 0);
                    setDono(r.data.dono);
                    setDescription(r.data.description);
                    setIlimitado(r.data.ilimitado);
                    setLimiteCadastro(r.data.limite_cadastro ? moment(r.data.limiteCadastro).toDate() : null);
                    setInicio(r.data.inicio ? moment(r.data.inicio).toDate() : null);
                    setFim(r.data.fim ? moment(r.data.fim).toDate() : null);
                    setAlvo(r.data.alvo);
                    setInfo(r.data.info);
                    setExterna(r.data.externa);
                    setLink(r.data.link);
                    setModo(r.data.modo_id);
                    setStatus(r.data.status_id);
                }
                setOptionsStatus(r.options.status);
                setOptionsModo(r.options.modos);
            }
            setLoading(false);
        });
    }, [id]);

    const onSubmit = () => {
        setLoading(true);
        let files = new FormData();
        if (cover) {
            files.append('cover', cover);
        }
        files.append('nome', nome);
        files.append('vagas', vagas);
        files.append('alvo', alvo);
        files.append('dono', dono);
        files.append('description', description);
        files.append('ilimitado', ilimitado ? 1 : 0);
        files.append('limite_cadastro', limiteCadastro ? moment(limiteCadastro).toISOString() : moment().toISOString());
        files.append('inicio', moment(inicio).toISOString());
        files.append('fim', moment(fim).toISOString());
        files.append('info', info);
        files.append('externa', externa ? 1 : 0);
        files.append('link', link);
        files.append('modo_id', modo);
        files.append('status_id', status);

        axios_instance
            .post(`curso${id > 0 ? `/edit/${id}` : '/new'}`, files)
            .then(res => {
                let r = res.data;
                if (r.success) {
                    sweetAlert('success', 'Sucesso', r.message);
                    onClose(true);
                } else {
                    sweetAlert('error', 'Erro', r.message);
                }
                setLoading(false);
            })
            .catch(() => {
                sweetAlert('error', 'Erro', 'Ocorreu um erro ao salvar o curso!');
            })
            .finally(() => {
                setLoading(false);
            });
    };
    if (loading)
        return (
            <DialogContent>
                <CircularProgress />
                Carregando...
            </DialogContent>
        );

    return (
        <>
            <DialogTitle>{title[mode]}</DialogTitle>
            <DialogContent>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={6}>
                            <TextField
                                label="Título do Curso"
                                required
                                value={nome}
                                onChange={e => setNome(e.target.value)}
                                fullWidth
                                margin="dense"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Realizado por"
                                value={dono}
                                onChange={e => setDono(e.target.value)}
                                fullWidth
                                margin="dense"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Foto de capa"
                                type="file"
                                fullWidth
                                margin="dense"
                                inputProps={{ accept: 'image/*' }}
                                onChange={e => setCover(e.target.files[0])}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Descrição"
                                multiline
                                minRows={4}
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                                fullWidth
                                margin="dense"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                label="Público Alvo"
                                value={alvo}
                                onChange={e => setAlvo(e.target.value)}
                                fullWidth
                                margin="dense"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                label="Vagas"
                                type="number"
                                disabled={ilimitado}
                                value={vagas.toString()}
                                onChange={e => setVagas(parseInt(e.target.value))}
                                fullWidth
                                margin="dense"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={
                                    <Switch checked={ilimitado} onChange={() => setIlimitado(!ilimitado)} name="ilimitado" />
                                }
                                label="Vagas Ilimitadas"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <KeyboardDatePicker
                                margin="dense"
                                fullWidth
                                disabled={externa}
                                value={limiteCadastro}
                                onChange={e => {
                                    setLimiteCadastro(e);
                                }}
                                label="Data Final de Matricula"
                                format="dd/MM/yyyy"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                label="Link de matrícula"
                                disabled={!externa}
                                value={link}
                                onChange={e => setLink(e.target.value)}
                                fullWidth
                                margin="dense"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={<Switch checked={externa} onChange={() => setExterna(!externa)} name="externa" />}
                                label="Matrícula Externa"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <KeyboardDatePicker
                                margin="dense"
                                fullWidth
                                value={inicio}
                                onChange={e => {
                                    setInicio(e);
                                }}
                                label="Data Inicial"
                                format="dd/MM/yyyy"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <KeyboardDatePicker
                                margin="dense"
                                fullWidth
                                value={fim}
                                onChange={e => {
                                    setFim(e);
                                }}
                                label="Data Final"
                                format="dd/MM/yyyy"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="modelo-label">Modelo</InputLabel>
                                <Select value={modo} onChange={e => setModo(e.target.value)} labelId="modelo-label">
                                    {optionsModo.map(item => (
                                        <MenuItem value={item.id}>{item.nome}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="status-label">Status</InputLabel>
                                <Select value={status} onChange={e => setStatus(e.target.value)} labelId="status-label">
                                    {optionsStatus.map(item => (
                                        <MenuItem value={item.id}>{item.nome}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel className="pb-1 mt-2">Informações</InputLabel>
                            <TinyEditor
                                value={info}
                                onChange={content => {
                                    setInfo(content);
                                }}
                            />
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
            </DialogContent>
            <DialogActions>
                <div className="d-flex justify-content-end mt-3">
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        Salvar
                    </Button>
                </div>
            </DialogActions>
        </>
    );
}
