import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { cleanDepartmentOmbudsman } from '../../../redux/actions/f5-actions/department-ombudsman';
import generalService from '../../../services/f5-services/generalService';

import ChooseSector from '../../../f5/sectors/ChooseSector';
import ChooseSpecial from '../../../f5/sectors/ChooseSpecial';
import axios_instance from '../../../services/auth/jwt/config';

const DepartmentOmbudsman = () => {
    const [sectors, setSectors] = useState(null);
    const [special, setSpecial] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(cleanDepartmentOmbudsman());
    }, [dispatch]);

    useEffect(() => {
        axios_instance.get(`sectors/special`).then(res => {
            let r = res.data;
            if (r.success) {
                setSpecial(r.special);
            }
        });
    }, []);

    useEffect(() => {
        const getSectors = async () => {
            const res = await generalService.getSectorsCounter();

            setSectors(res.counters);
        };

        getSectors();
    }, [setSectors]);

    const loader = (text = 'Carregando dados...') => {
        return (
            <Box width="100%" height={150} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <CircularProgress />
                <Box fontWeight={700} fontSize={12}>
                    {text}
                </Box>
            </Box>
        );
    };

    return (
        <>
            {sectors ? <ChooseSector sectors={sectors} /> : loader('Carregando setores...')}
            {special ? <ChooseSpecial items={special} /> : loader('Carregando módulos...')}
        </>
    );
};

export default DepartmentOmbudsman;
