import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { Auxilio } from './AuxlioTransporte';
import moment from 'moment';
import { appURL } from '../../../services/auth/jwt/config';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        fontSize: '0.5rem',
    },
    section: {
        flexGrow: 1,
    },
    image: {
        width: '100%',
    },
    name: {
        position: 'absolute',
        fontSize: 8,
        left: '75px',
        top: '70rem',
    },
    ensino: {
        position: 'absolute',
        fontSize: 8,
        left: '195px',
        top: '70rem',
    },
    nascimento: {
        position: 'absolute',
        fontSize: 8,
        left: '95px',
        top: '97rem',
    },
    curso: {
        position: 'absolute',
        fontSize: 8,
        left: '195px',
        top: '97rem',
    },
    cpf: {
        position: 'absolute',
        fontSize: 8,
        left: '87px',
        top: '122.7rem',
    },
    validade: {
        position: 'absolute',
        fontSize: 8,
        left: '178px',
        top: '122.7rem',
    },
    turno: {
        position: 'absolute',
        fontSize: 8,
        left: '214px',
        top: '122.7rem',
    },
    ano: {
        position: 'absolute',
        left: '168px',
        top: '148.5rem',
        fontSize: 8,
        color: '#fff',
        fontWeight: 'bold',
    },
    foto: {
        position: 'absolute',
        left: 6,
        top: 68,
        width: 55.5,
        height: 65,
        objectFit: 'cover',
    },
});

interface AuxilioCarteiraProps {
    data: Auxilio;
}

// Cria o componente do documento
const AuxilioCarteira = ({ data }: AuxilioCarteiraProps) => {
    const getFotoUrl = () => {
        let url = '';
        if (data.anexos) {
            data.anexos.forEach(anexo => {
                if (anexo.tipo_interno === 'foto') {
                    url = `${appURL}${anexo.link}`;
                }
            });
        }
        return url;
    };

    {/* @ts-ignore */}
    return <Document>
        {/* @ts-ignore */}
            <Page size="EXECUTIVE" orientation="portrait">
                <View>
                    <Image style={styles.image} src={require('../../../asset/carteirinha.png')} />
                    {/* @ts-ignore */}
                    {getFotoUrl() ? <Image style={styles.foto} source={{ uri: getFotoUrl() }} /> : null}
                    <Text style={styles.name}>{data.nome}</Text>
                    <Text style={styles.ensino}>{data.instituicao}</Text>
                    <Text style={styles.nascimento}>{moment(data.nascimento, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Text>
                    <Text style={styles.curso}>{data.curso}</Text>
                    <Text style={styles.cpf}>{data.cpf}</Text>
                    <Text style={styles.validade}>2023</Text>
                    <Text style={styles.turno}>{data.turno}</Text>
                    <Text style={styles.ano}>{moment().year()}</Text>
                </View>
            </Page>
        </Document>
};

export default AuxilioCarteira;
