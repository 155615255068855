import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { Auxilio } from './AuxlioTransporte';
import moment from 'moment';
import { appURL } from '../../../services/auth/jwt/config';
// Create styles
const styles = StyleSheet.create({
    page: {
        marginTop: '2rem',
    },
    logo: {
        width: '20%',
    },
    title: {
        fontSize: 9,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    subtitle: {
        fontSize: 9,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    section: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    flex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 30,
    },
    foto: {
        width: 55.5,
        height: 65,
        objectFit: 'cover',
    },
    transporte: {
        fontSize: 9,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 10,
        textDecoration: 'underline',
    },
    requerimento: {
        fontSize: 9,
        fontWeight: 'bold',
        textAlign: 'center',
        letterSpacing: 7,
    },
    semestre: {
        fontSize: 9,
        textAlign: 'center',
    },
    table: {
        border: '1px solid #999999',
        backgroundColor: '#999999',
    },
    tabletitle: {
        fontSize: 6,
        fontWeight: 'bold',
        paddingTop: 5,
        paddingLeft: 4,
        color: '#fff',
    },
    tablecampo: {
        fontSize: 5,
        fontWeight: 'bold',
        paddingTop: 5,
        paddingLeft: 4,
    },
    tablebodyLeft: {
        borderBottom: '1px solid #999999',
        borderLeft: '1px solid #999999',
        borderRight: '1px solid #999999',
        display: 'flex',
    },
    tablebodyRight: {
        borderBottom: '1px solid #999999',
        borderRight: '1px solid #999999',
        display: 'flex',
    },
    doublerow: {
        display: 'flex',
        flexDirection: 'row',
    },
    date: {
        fontSize: 12,
        paddingTop: 10,
        color: '#999999',
    },
    contato: {
        fontSize: 12,
        paddingTop: 10,
        paddingBottom: 2,
        color: '#999999',
    },
    complemento: {
        fontSize: 9,
        color: '#999999',
    },
    infotitle: {
        fontSize: 6,
        fontWeight: 'bold',
        textDecoration: 'underline',
        marginBottom: 5,
    },
    list: {
        fontSize: 6,
        fontWeight: 'bold',
        marginLeft: 20,
    },
    aviso: {
        fontStyle: 'italic',
        textAlign: 'right',
        fontSize: 7,
        marginTop: 10,
    },
    text: {
        fontSize: 8,
        fontStyle: 'justify',
        lineHeight: 2,
    },
    margin: {
        marginLeft: 60,
        marginRight: 60,
        marginTop: 20,
        marginBottom: 20,
    },
    citacao: {
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: 8,
        marginTop: 30,
        marginBottom: 30,
        lineHeight: 2,
    },
    assinatura: {
        textAlign: 'center',
        fontSize: 8,
    },
    rowassinatura: {
        marginTop: 50,
    },
    footer: {
        fontSize: 6,
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        marginLeft: 60,
    },
    color: {
        color: '#4545c5',
    },
});

interface AuxilioDocumentProps {
    data: Auxilio;
}

// Cria o componente do documento
const AuxilioDocument = ({ data }: AuxilioDocumentProps) => {
    const getFotoUrl = () => {
        let url = '';
        if (data.anexos) {
            data.anexos.forEach(anexo => {
                if (anexo.tipo_interno === 'foto') {
                    url = `${appURL}${anexo.link}`;
                }
            });
        }
        return url;
    };
    {/* @ts-ignore */}
    return <Document>
        {/* @ts-ignore */}
            <Page size="A4" orientation="portrait" style={styles.page}>
                <View style={styles.margin}>
                    <View style={styles.section}>
                        <Image style={styles.logo} src={require('../../../asset/logo.png')} />
                        <View style={styles.flex}>
                            <Text style={styles.title}>SECRETARIA MUNICIPAL DE EDUCAÇÃO</Text>
                            <Text style={styles.subtitle}>Departamento de Transporte Escolar</Text>
                        </View>
                        {/* @ts-ignore */}
                        {getFotoUrl() ? <Image style={styles.foto} source={{ uri: getFotoUrl() }} /> : null}
                    </View>
                    <View style={{ marginBottom: 20 }}>
                        <Text style={styles.transporte}>TRANSPORTE DE UNIVERSITÁRIOS</Text>
                        <Text style={styles.requerimento}>REQUERIMENTO</Text>
                        <Text style={styles.semestre}>2º Semestre/ 2023</Text>
                    </View>
                    <View style={{ ...styles.table, paddingBottom: 10 }}>
                        <Text style={styles.tabletitle}>Dados do Estudante</Text>
                    </View>
                    <View style={styles.doublerow}>
                        <View style={{ ...styles.tablebodyLeft, width: '100%' }}>
                            <Text style={styles.tablecampo}>Nome Completo:</Text>
                            <Text style={styles.date}>{data.nome}</Text>
                        </View>
                        <View style={{ ...styles.tablebodyRight, width: '30%' }}>
                            <Text style={styles.tablecampo}>Data de Nascimento:</Text>
                            <Text style={styles.date}>{moment(data.nascimento, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Text>
                        </View>
                    </View>
                    <View style={styles.doublerow}>
                        <View style={{ ...styles.tablebodyLeft, width: '100%' }}>
                            <Text style={styles.tablecampo}>Endereço:</Text>
                            <Text style={styles.date}>{data.endereco}</Text>
                        </View>
                        <View style={{ ...styles.tablebodyRight, width: '30%' }}>
                            <Text style={styles.tablecampo}>Bairro:</Text>
                            <Text style={styles.date}>{data.bairro}</Text>
                        </View>
                    </View>
                    <View style={styles.doublerow}>
                        <View style={{ ...styles.tablebodyLeft, width: '100%' }}>
                            <Text style={styles.tablecampo}>E-mail:</Text>
                            <Text style={styles.date}>{data.email}</Text>
                        </View>
                        <View style={{ ...styles.tablebodyRight, width: '60%' }}>
                            <Text style={styles.tablecampo}>Telefone:</Text>
                            <Text style={styles.date}>{data.telefone}</Text>
                        </View>
                    </View>
                    <View style={styles.doublerow}>
                        <View style={{ ...styles.tablebodyLeft, width: '100%' }}>
                            <Text style={styles.tablecampo}>RG:</Text>
                            <Text style={styles.date}>{data.rg ?? 'Não informado'}</Text>
                        </View>
                        <View style={{ ...styles.tablebodyRight, width: '60%' }}>
                            <Text style={styles.tablecampo}>CPF</Text>
                            <Text style={styles.date}>{data.cpf}</Text>
                        </View>
                    </View>
                    <View style={styles.doublerow}>
                        <View style={{ ...styles.tablebodyLeft, width: '50%' }}>
                            <Text style={styles.tablecampo}>Nº da Matrícula ou Registro Acadêmico (RA):</Text>
                            <Text style={styles.date}>{data.rm}</Text>
                        </View>
                        <View style={{ ...styles.tablebodyLeft, width: '50%' }}>
                            <Text style={styles.tablecampo}>Previsão de Conclusão do Curso:</Text>
                            <Text style={styles.date}>
                                {data.conclusao_mes}/{data.conclusao_ano}
                            </Text>
                        </View>
                        <View style={{ ...styles.tablebodyRight, width: '100%' }}>
                            <Text style={styles.tablecampo}>Desde que ano utiliza o Transporte Universitário?</Text>
                            <Text style={styles.date}>{data.utilizou ? data.desde : 'Não utilizou'}</Text>
                        </View>
                    </View>
                    <View style={{ ...styles.tablebodyLeft, paddingBottom: 10, backgroundColor: '#999999' }}>
                        <Text style={styles.tabletitle}>Dados do Curso</Text>
                    </View>
                    <View style={styles.doublerow}>
                        <View style={{ ...styles.tablebodyLeft, width: '100%' }}>
                            <Text style={styles.tablecampo}>Curso:</Text>
                            <Text style={styles.date}>{data.curso}</Text>
                        </View>
                        <View style={{ ...styles.tablebodyRight, width: '30%' }}>
                            <Text style={styles.tablecampo}>Duração do Curso:</Text>
                            <Text style={styles.date}>{data.duracao}</Text>
                        </View>
                    </View>
                    <View style={styles.doublerow}>
                        <View style={{ ...styles.tablebodyLeft, width: '60%' }}>
                            <Text style={styles.tablecampo}>MODALIDADE</Text>
                        </View>
                        <View style={{ ...styles.tablebodyRight, width: '100%' }}>
                            <Text style={styles.tablecampo}>MODALIDADE DE ENSINO:</Text>
                            <Text style={styles.date}>{data.modalidade}</Text>
                        </View>
                    </View>

                    <View style={styles.doublerow}>
                        <View style={{ ...styles.tablebodyLeft, width: '50%' }}>
                            <Text style={styles.tablecampo}>Semestre atual:</Text>
                            <Text style={styles.date}>{data.semestre}</Text>
                        </View>
                        <View style={{ ...styles.tablebodyLeft, width: '50%' }}>
                            <Text style={styles.tablecampo}>Turno:</Text>
                            <Text style={styles.date}>{data.turno}</Text>
                        </View>
                        <View style={{ ...styles.tablebodyRight, width: '100%' }}>
                            <Text style={styles.tablecampo}>Horários:</Text>
                            <Text style={styles.date}>
                                {data.hora_entrada} - {data.hora_saida}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.doublerow}>
                        <View style={{ ...styles.tablebodyLeft, width: '100%' }}>
                            <Text style={styles.tablecampo}>Início das aulas:</Text>
                            <Text style={styles.date}>{data.inicio}</Text>
                        </View>
                        <View style={{ ...styles.tablebodyRight, width: '100%' }}>
                            <Text style={styles.tablecampo}>Dias letivos:</Text>
                            <Text style={styles.date}>{data.dias_semana?.join(', ')}</Text>
                        </View>
                    </View>

                    <View style={styles.doublerow}>
                        <View style={{ ...styles.tablebodyLeft, width: '100%' }}>
                            <Text style={styles.tablecampo}>Instituição de Ensino:</Text>
                            <Text style={styles.date}>{data.instituicao}</Text>
                        </View>
                        <View style={{ ...styles.tablebodyRight, width: '40%' }}>
                            <Text style={styles.tablecampo}>Telefone da Instituição:</Text>
                            <Text style={styles.date}>{data.telefoneInstituicao}</Text>
                        </View>
                    </View>
                    <View style={styles.doublerow}>
                        <View style={{ ...styles.tablebodyLeft, width: '100%', marginBottom: 20 }}>
                            <Text style={styles.tablecampo}>Endereço:</Text>
                            <Text style={styles.date}>{data.enderecoInstituicao}</Text>
                        </View>
                        <View style={{ ...styles.tablebodyRight, width: '30%', marginBottom: 20 }}>
                            <Text style={styles.tablecampo}>Bairro:</Text>
                            <Text style={styles.date}>{data.bairroInstituicao}</Text>
                        </View>
                    </View>

                    <View>
                        <Text style={styles.infotitle}>
                            Entregar anexo a este requerimento, devidamente preenchido, cópias dos seguintes documentos:{' '}
                        </Text>
                        <Text style={styles.list}>• Carteira de Identidade (RG);</Text>
                        <Text style={styles.list}>• CPF;</Text>
                        <Text style={styles.list}>• Comprovante de residência (atualizado);</Text>
                        <Text style={styles.list}>• Declaração de matrícula do curso;</Text>
                        <Text style={styles.list}>• 02 Fotos 3X4;</Text>
                        <Text style={styles.list}>• Calendário letivo ou programação do curso.</Text>
                    </View>
                    <Text style={styles.aviso}>
                        *O cadastro deverá ser efetuado, preferencialmente, até o final do mês de janeiro de 2023.
                    </Text>
                </View>

                <View style={styles.footer} fixed>
                    <Text style={styles.color}>Secretaria Municipal de Educação</Text>
                    <Text>Av.: Aureliano Moura Brandão nº. 325 - Centro</Text>
                    <Text>Tel.: (67) 3238-3166 - CEP: 79.180-000</Text>
                    <Text>E-mail: educacao@ribasdoriopardo.ms.gov.br / transporte.educacao@ribasdoriopardo.ms.gov.br</Text>
                </View>
            </Page>
    {/* @ts-ignore */}
            <Page size="A4" orientation="portrait">
                <View style={{ ...styles.margin, marginTop: 60 }}>
                    <Text style={styles.title}>DECLARAÇÃO</Text>
                    <Text style={{ ...styles.text, marginTop: 40, textAlign: 'justify' }}>
                        De acordo que todas as informações fornecidas são verdadeiras, DECLARO estar ciente que sou
                        responsável por estes dados, em caso de distorções dos fatos e danos causados, responderei
                        CRIMINALMENTE caso comprovem-se falsificações, conforme Artigo 299 do Decreto Lei nº 2.848 de 07 de
                        dezembro de 1940, Art. 299.
                    </Text>
                    <Text style={{ ...styles.text, textAlign: 'justify' }}>
                        Declaro ainda que tenho ciência que é proibido a utilização de bebida alcoólica, entorpecentes,
                        cigarros, e afins dentro do veículo destinado ao transporte dos estudantes, respondendo pessoalmente
                        por danos causados aos terceiros ou ao veículo, bem como eventual ônus quanto a limpeza durante o uso
                        do transporte universitário.
                    </Text>
                    <Text style={{ ...styles.text, textAlign: 'justify' }}>
                        Estou informado que a Carteirinha do Estudante é um documento válido de uso pessoal, intransferível e
                        de minha inteira responsabilidade.
                    </Text>
                    <Text style={{ ...styles.text, textAlign: 'justify' }}>
                        Autorizo ainda, o Departamento de Transporte Escolar a efetuar, sempre que necessário e em dia
                        inopinado, a averiguação e a confirmação das informações fornecidas.
                    </Text>
                    <View style={{ ...styles.citacao, paddingLeft: '30%' }}>
                        <Text style={{ textAlign: 'justify' }}>
                            Conforme a LEI MUNICIPAL Nº. 1.244, DE 17 DE MARÇO DE 2022, Art. 2º, as vagas do transporte
                            escolar universitário são de preferência aos alunos regularmente matriculados em cursos de nível
                            de graduação, cursos profissionalizantes e/ou cursos técnicos regulares, devidamente reconhecidos
                            e autorizados pelo Ministério de Educação (MEC).
                        </Text>
                    </View>
                    <View style={{ ...styles.citacao, paddingLeft: '30%' }}>
                        <Text>Ribas do Rio Pardo/MS,______de _______________________de 2023.</Text>
                    </View>
                    <View style={styles.rowassinatura}>
                        <Text style={styles.assinatura}>________________________________________________</Text>
                        <Text style={styles.assinatura}>Assinatura do estudante ou responsável</Text>
                    </View>
                    <View style={styles.rowassinatura}>
                        <Text style={styles.assinatura}>________________________________________________</Text>
                        <Text style={styles.assinatura}>Diretor(a) de Transporte Escolar</Text>
                    </View>
                </View>
                <View style={styles.footer} fixed>
                    <Text style={styles.color}>Secretaria Municipal de Educação</Text>
                    <Text>Av.: Aureliano Moura Brandão nº. 325 - Centro</Text>
                    <Text>Tel.: (67) 3238-3166 - CEP: 79.180-000</Text>
                    <Text>E-mail: educacao@ribasdoriopardo.ms.gov.br / transporte.educacao@ribasdoriopardo.ms.gov.br</Text>
                </View>
            </Page>
        </Document>
};

export default AuxilioDocument;
