import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';

const styles = StyleSheet.create({
    page: {
        marginTop: '2rem',
    },
    logo: {
        width: '30%',
    },
    section: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20,
    },
    title: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    subtitle: {
        fontSize: 9,
        fontWeight: 'bold',
        textAlign: 'center',
    },

    flex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 30,
    },

    margin: {
        marginLeft: 30,
        marginRight: 30,
        marginTop: 10,
        marginBottom: 10,
    },

    tabletitle: {
        fontSize: 9,
        fontWeight: 'bold',
        paddingTop: 5,
        paddingLeft: 4,
        paddingRight: 4,
    },

    tablecampo: {
        fontSize: 8,
        fontWeight: 'bold',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 4,
        paddingRight: 4,
        textAlign: 'center',
    },

    tablecamporesposta: {
        fontSize: 8,
        fontWeight: 'bold',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 4,
        paddingRight: 4,
    },
    tabletitleLeft: {
        borderBottom: '1px solid #999999',
        borderLeft: '1px solid #999999',
        borderRight: '1px solid #999999',
        backgroundColor: '#999999',
        color: '#fff',
        display: 'flex',
    },

    tabletitleRight: {
        borderBottom: '1px solid #999999',
        borderRight: '1px solid #999999',
        backgroundColor: '#999999',
        color: '#fff',
        display: 'flex',
    },

    tablebodyLeft: {
        borderBottom: '1px solid #999999',
        borderLeft: '1px solid #999999',
        borderRight: '1px solid #999999',
        display: 'flex',
    },

    tablebodyRight: {
        borderBottom: '1px solid #999999',
        borderRight: '1px solid #999999',
        display: 'flex',
    },

    doublerow: {
        display: 'flex',
        flexDirection: 'row',
    },

    table: {
        borderBottom: '1px solid #999999',
        borderLeft: '1px solid #999999',
        borderRight: '1px solid #999999',
    },
});

// Cria o componente do documento
export default function AgendaDia({ evento, ...props }) {
    return (
        <View style={styles.margin} break={false} wrap={false}>
            <View style={styles.doublerow}>
                <View style={[styles.tabletitleLeft, { width: '100%' }]}>
                    <Text style={styles.tablecampo}>NOME</Text>
                </View>
                <View style={[styles.tabletitleRight, { width: '100%' }]}>
                    <Text style={styles.tablecampo}>DIA</Text>
                </View>
                <View style={[styles.tabletitleRight, { width: '100%' }]}>
                    <Text style={styles.tablecampo}>HORA</Text>
                </View>
                <View style={[styles.tabletitleRight, { width: '100%' }]}>
                    <Text style={styles.tablecampo}>SERVIÇO</Text>
                </View>
                <View style={[styles.tabletitleRight, { width: '100%' }]}>
                    <Text style={styles.tablecampo}>LOCAL</Text>
                </View>
            </View>
            <View style={styles.doublerow}>
                <View style={[styles.tablebodyLeft, { width: '100%' }]}>
                    <Text style={styles.tablecampo}>{evento.usuario.nome}</Text>
                </View>
                <View style={[styles.tablebodyRight, { width: '100%' }]}>
                    <Text style={styles.tablecampo}>{moment(evento.start).format('DD/MM/YYYY')}</Text>
                </View>
                <View style={[styles.tablebodyRight, { width: '100%' }]}>
                    <Text style={styles.tablecampo}>{moment(evento.start).format('HH:mm')}</Text>
                </View>
                <View style={[styles.tablebodyRight, { width: '100%' }]}>
                    <Text style={styles.tablecampo}>{evento.servico}</Text>
                </View>
                <View style={[styles.tablebodyRight, { width: '100%' }]}>
                    <Text style={styles.tablecampo}>{evento.local}</Text>
                </View>
            </View>
            <View style={styles.table}>
                <Text style={styles.tabletitle}>Outras Informações:</Text>
                <Text style={styles.tablecamporesposta}>
                    {evento.obs.map((obs, index) => {
                        return (
                            <Text key={index} style={{ paddingVertical: 5 }}>
                                <Text style={{ fontWeight: 'bold' }}>{obs.name}:</Text> {obs.value} {`\n`}
                            </Text>
                        );
                    })}
                </Text>
            </View>
        </View>
    );
}
