import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectOcurrence } from '../../../../redux/actions/f5-actions/ocurrences-actions';
import ocurrencesActionService from '../../../../services/f5-services/ocurrencesActionService';
import { Box, TextField, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/Email';

import sweetAlert from '../../../../f5/utils/sweetAlert';
import SharedTitle from './SharedTitle';
import SharedDescription from './SharedDescription';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  saveButton: {
    color: 'white',
    backgroundColor: '#21cc37',
    '&:hover': {
      backgroundColor: '#32e33b',
    },
  },
}));

const SendEmail = ({ ocurrence }) => {
  const [email, setEmail] = useState('');
  const [note, setNote] = useState('');
  const [loading, setLoading] = useState({ message: '', status: false });

  const dispatch = useDispatch();
  const classes = useStyles();

  const handleSubmit = async () => {
    setLoading({ status: true, message: 'Enviando e-mail...' });

    const postData = {
      observacao: note,
      destinatario: email,
      ocorrencia_id: ocurrence.ocorrencia_id,
    };

    await ocurrencesActionService.sendEmailCopy(postData);

    dispatch(selectOcurrence(ocurrence.ocorrencia_id));
    setLoading({ status: false, message: '' });
    sweetAlert('success', 'Sucesso', 'E-mail enviado com sucesso!');
  };

  if (loading.status) {
    return (
      <Box
        className={classes.root}
        height={500}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center">
        <CircularProgress />
        <Box mt={4} fontWeight={700}>
          {loading.message}
        </Box>
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <SharedTitle icon={<EmailIcon />} title="Enviar Cópia por Email" />
      <SharedDescription
        requester={ocurrence.solicitante.nome}
        date={`${new Date(ocurrence.abertura).toLocaleDateString()} às ${new Date(ocurrence.abertura).toLocaleTimeString()}`}
        service={ocurrence.servico}
        protocol={ocurrence.protocolo}
      />

      <Box width="100%">
        <Box mb={8}>
          <TextField type="email" label="Email" required fullWidth value={email} onChange={e => setEmail(e.target.value)} />
        </Box>
        <TextField
          label="Observação"
          multiline
          rows={2}
          variant="outlined"
          fullWidth
          value={note}
          onChange={e => setNote(e.target.value)}
        />
      </Box>
      <Box mt={2} px={4} display="flex" justifyContent="end">
        <Button classes={{ root: classes.saveButton }} onClick={handleSubmit}>
          enviar
        </Button>
      </Box>
    </div>
  );
};

export default SendEmail;
