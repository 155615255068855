import React from 'react';
import { Box, Grid } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

import ServiceFields from './OcurrenceDataComponents/ServiceFields';
import LocalFields from './OcurrenceDataComponents/LocalFields';

import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import SimpleDatePicker from './RequesterComponents/SimpleDatePicker';

const useStyles = makeStyles(theme => ({
    sectionText: {
        color: 'rgba(0, 0, 0, 0.5)',
    },
}));

const OcurrenceData = ({
    formFieldReference,
    createOcurrenceForm,
    handleFormChange,
    setUseAddressReference,
    useAddressReference,
    validationErrors,
}) => {
    const userAccess = useSelector(({ auth }) => (auth.authUser ? auth.authUser.cargo.interno : null));
    const classes = useStyles();

    const validateMe = field => {
        if (validationErrors.checked && validationErrors.step === 'data' && validationErrors.list.hasOwnProperty(field)) {
            return {
                isError: true,
                message: validationErrors.list[field] === 'required' ? 'Campo requirido.' : 'Campo inválido.',
            };
        } else {
            return { isError: false, message: '' };
        }
    };

    return (
        <Box display="flex" flexDirection="column">
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth required error={validateMe('ocurrenceDataCategory').isError}>
                        <InputLabel id="category-input-label">Categoria</InputLabel>
                        <Select
                            inputProps={{ tabIndex: 200 }}
                            labelId="category-input-label"
                            id="ocurrence-category-input"
                            name="ocurrenceDataCategory"
                            value={createOcurrenceForm.ocurrenceDataCategory}
                            onChange={handleFormChange}
                            label="Categoria">
                            <MenuItem disabled value="">
                                <em>Selecione uma opção:</em>
                            </MenuItem>
                            {formFieldReference.categorias.map(item => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.nome}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{validateMe('ocurrenceDataCategory').message}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth variant="outlined" required error={validateMe('ocurrenceDataSector').isError}>
                        <InputLabel id="sector-input-label">Setor</InputLabel>
                        <Select
                            inputProps={{ tabIndex: 202 }}
                            labelId="sector-input-label"
                            id="ocurrence-sector-input"
                            name="ocurrenceDataSector"
                            value={createOcurrenceForm.ocurrenceDataSector}
                            onChange={handleFormChange}
                            label="Setor">
                            <MenuItem disabled value="">
                                <em>Selecione uma opção:</em>
                            </MenuItem>
                            {formFieldReference.setores.map(item => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.nome}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{validateMe('ocurrenceDataSector').message}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth required error={validateMe('ocurrenceDataEntry').isError}>
                        <InputLabel id="entry-input-label">Entrada</InputLabel>
                        <Select
                            inputProps={{ tabIndex: 201 }}
                            labelId="entry-input-label"
                            id="ocurrence-entry-input"
                            name="ocurrenceDataEntry"
                            value={createOcurrenceForm.ocurrenceDataEntry}
                            onChange={handleFormChange}
                            label="Entrada">
                            <MenuItem disabled value="">
                                <em>Selecione uma opção:</em>
                            </MenuItem>
                            {formFieldReference.entradas.map(item => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.nome}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{validateMe('ocurrenceDataEntry').message}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth variant="outlined" required error={validateMe('ocurrenceDataGravity').isError}>
                        <InputLabel id="gravity-input-label">Gravidade</InputLabel>
                        <Select
                            inputProps={{ tabIndex: 203 }}
                            labelId="gravity-input-label"
                            id="ocurrence-gravity-input"
                            name="ocurrenceDataGravity"
                            value={createOcurrenceForm.ocurrenceDataGravity}
                            onChange={handleFormChange}
                            label="Gravidade">
                            <MenuItem disabled value="">
                                <em>Selecione uma opção:</em>
                            </MenuItem>
                            {formFieldReference.gravidades.map(item => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.nome}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{validateMe('ocurrenceDataGravity').message}</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
            {userAccess === 'ouvidor' ? (
                <Box my={4}>
                    <Box className={classes.sectionText} fontWeight={700} fontSize={12} mb={1}>
                        OUTROS DADOS
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <SimpleDatePicker
                                handleDate={createOcurrenceForm.ocurrenceDataCreationDate}
                                handleDateChange={date =>
                                    handleFormChange(null, {
                                        ocurrenceDataCreationDate: date,
                                    })
                                }
                                label="Data de abertura da ocorrência"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Box>
            ) : null}
            <Box mb={4}>
                <Box className={classes.sectionText} fontWeight={700} fontSize={12} mb={1}>
                    SERVIÇO
                </Box>
                <ServiceFields
                    formFieldReference={formFieldReference}
                    createOcurrenceForm={createOcurrenceForm}
                    handleFormChange={handleFormChange}
                    validateMe={validateMe}
                />
            </Box>
            <Box mb={4}>
                <Box className={classes.sectionText} fontWeight={700} fontSize={12} mb={1}>
                    LOCAL DA OCORRÊNCIA
                </Box>
                <LocalFields
                    createOcurrenceForm={createOcurrenceForm}
                    handleFormChange={handleFormChange}
                    setUseAddressReference={setUseAddressReference}
                    useAddressReference={useAddressReference}
                    validateMe={validateMe}
                />
            </Box>
        </Box>
    );
};

export default OcurrenceData;
