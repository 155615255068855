import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectOcurrence } from '../../../../redux/actions/f5-actions/ocurrences-actions';
import ocurrencesActionService from '../../../../services/f5-services/ocurrencesActionService';
import { Box, FormControl, InputLabel, Select, MenuItem, TextField, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import ReplyIcon from '@material-ui/icons/Reply';
import CreateIcon from '@material-ui/icons/Create';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';

import FeedbackAlert from './FeedbackAlert';
import SharedTitle from './SharedTitle';
import SharedDescription from './SharedDescription';
import sweetAlert from '../../../../f5/utils/sweetAlert';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  textTitle: {
    fontWeight: 700,
  },
  formControl: {
    padding: theme.spacing(0, 2),
    minWidth: '50%',
    boxSizing: 'border-box',
  },
  saveButton: {
    color: 'white',
    backgroundColor: '#21cc37',
    '&:hover': {
      backgroundColor: '#32e33b',
    },
  },
}));

const QuickAnswer = ({ triage, ocurrence }) => {
  const [expanded, setExpanded] = useState('panel1');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState({ message: '', status: false });
  const [category, setCategory] = useState(ocurrence.categoria_info ? ocurrence.categoria_info : triage.categorias[0].id);
  const [gravity, setGravity] = useState(ocurrence.gravidade_info ? ocurrence.gravidade_info : triage.gravidades[0].id);
  const [entry, setEntry] = useState(ocurrence.entrada_info ? ocurrence.entrada_info : triage.entradas[0].id);
  const [sector, setSector] = useState(ocurrence.setor_info ? ocurrence.setor_info : triage.setores[0].id);
  const [inputFile, setInputFile] = useState('');
  const [inputFileData, setInputFileData] = useState(null);
  const [solution, setSolution] = useState('');
  const [note, setNote] = useState('');
  const [deadline, setDeadline] = useState(0);

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleCategoryChange = event => {
    setCategory(event.target.value);
  };

  const handleGravityChange = event => {
    setGravity(event.target.value);
  };

  const handleEntryChange = event => {
    setEntry(event.target.value);
  };

  const handleSectorChange = event => {
    setSector(event.target.value);
  };

  const handleFileChange = event => {
    setInputFileData(event.target.files[0]);
    setInputFile(event.target.files[0].name);
  };

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // TODO: Melhorar validações de formulário
  const validateForm = () => {
    if (!solution || !deadline) {
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      let formData = new FormData();
      if (inputFileData) {
        setLoading({ status: true, message: 'Fazendo upload de arquivo...' });
        formData.append('anexo', inputFileData);
        formData.append('ocorrencia_id', ocurrence.ocorrencia_id);
        formData.append('file_observacao', note);
        await ocurrencesActionService.sendActionFile(formData);
      }

      setLoading({ status: true, message: 'Enviando resposta...' });

      const postData = {
        descricao: solution,
        prazo: deadline,
        observacao: note,
        ocorrencia_id: ocurrence.ocorrencia_id,
        ouv__categoria_id: ocurrence.categoria_info,
        ouv__entrada_id: ocurrence.entrada_info,
        ouv__gravidade_id: ocurrence.gravidade_info,
        ouv__setor_id: ocurrence.setor_info,
      };

      await ocurrencesActionService.sendQuickAnswer(postData);

      dispatch(selectOcurrence(ocurrence.ocorrencia_id));
      setLoading({ status: false, message: '' });
      sweetAlert('success', 'Sucesso', 'Resposta enviada com sucesso!');
    } else {
      setAlertOpen(true);
      setLoading({ status: false, message: '' });
    }
  };

  if (loading.status) {
    return (
      <Box
        className={classes.root}
        height={500}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center">
        <CircularProgress />
        <Box mt={4} fontWeight={700}>
          {loading.message}
        </Box>
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <SharedTitle icon={<ReplyIcon />} title="Resposta Rápida ao Munícipe" />
      <SharedDescription
        requester={ocurrence.solicitante.nome}
        date={`${new Date(ocurrence.abertura).toLocaleDateString()} às ${new Date(ocurrence.abertura).toLocaleTimeString()}`}
        service={ocurrence.servico}
        protocol={ocurrence.protocolo}
      />

      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" alignItems="center">
            <CheckIcon />
            <Box ml={2} className={classes.textTitle}>
              Dados da Triagem
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            <Box display="flex" width="100%" mb={2}>
              <FormControl className={classes.formControl}>
                <InputLabel id="quick-category">Categoria</InputLabel>
                <Select labelId="quick-category" id="quick-category-input" value={category} onChange={handleCategoryChange}>
                  {triage.categorias.map(item => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.nome}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel id="quick-gravity">Gravidade</InputLabel>
                <Select labelId="quick-gravity" id="quick-gravity-input" value={gravity} onChange={handleGravityChange}>
                  {triage.gravidades.map(item => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.nome}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box display="flex" width="100%" mb={2}>
              <FormControl className={classes.formControl}>
                <InputLabel id="quick-entry">Entrada</InputLabel>
                <Select labelId="quick-entry" id="quick-entry-input" value={entry} onChange={handleEntryChange}>
                  {triage.entradas.map(item => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.nome}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel id="quick-sector">Setor</InputLabel>
                <Select labelId="quick-sector" id="quick-sector-input" value={sector} onChange={handleSectorChange}>
                  {triage.setores.map(item => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.nome}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" alignItems="center">
            <CreateIcon />
            <Box ml={2} className={classes.textTitle}>
              Resposta Rápida
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box width="100%">
            <Box mb={2}>
              <TextField
                label="Solução"
                required
                multiline
                rows={3}
                variant="outlined"
                fullWidth
                value={solution}
                onChange={e => setSolution(e.target.value)}
              />
            </Box>
            <Box display="flex" justifyContent="center">
              <TextField
                required
                label="Prazo (n° dias)"
                type="number"
                inputProps={{ min: '0' }}
                value={deadline}
                onChange={e => setDeadline(e.target.value)}
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" alignItems="center">
            <AddToPhotosIcon />
            <Box ml={2} className={classes.textTitle}>
              Conteúdo Extra
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box ml={2} display="flex" flexDirection="column" width="100%">
            <Box mb={4} display="flex" alignItems="center">
              <Button variant="contained" component="label">
                Anexar Arquivo
                <input
                  id="quick-answer-file"
                  type="file"
                  hidden
                  onChange={handleFileChange}
                  accept=".pdf,.doc,.docx,.jpg,.jpeg,.gif,.png,.xls,.xlsx,.csv"
                />
              </Button>
              <Box ml={2} fontWeight={700} fontSize={12}>
                {inputFile}
              </Box>
            </Box>
            <Box width={'100%'}>
              <TextField
                label="Observação"
                fullWidth
                variant="outlined"
                size="small"
                value={note}
                multiline
                rows={3}
                onChange={e => setNote(e.target.value)}
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box mt={2} px={4} display="flex" justifyContent="end">
        <Button classes={{ root: classes.saveButton }} onClick={handleSubmit}>
          enviar
        </Button>
      </Box>
      <FeedbackAlert message="Por favor, preencha os campos obrigatórios." open={alertOpen} setOpen={setAlertOpen} />
    </div>
  );
};

export default QuickAnswer;
