import React, { useState } from 'react';
import accountService from '../../services/f5-services/accountService';
import { Box, TextField, Button } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import AccountAlerts from './AccountAlerts';

const useStyles = makeStyles(theme => ({
  saveButton: {
    fontSize: 12,
    color: 'white',
    backgroundColor: '#0bb511',
    '&:hover': {
      backgroundColor: '#1fc225',
    },
  },
}));

const ChangePassword = ({ updateUser }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('');

  const classes = useStyles();

  const handleUserPassword = async () => {
    const formData = {
      new: newPassword,
      old: oldPassword,
    };

    if (newPassword === confirmPassword && oldPassword) {
      const res = await accountService.changeUserPassword(formData);
      if (res.success) {
        updateUser();
        setAlertMessage('Senha alterada com successo.');
        setAlertSeverity('success');
        setAlert(true);
      } else {
        setAlertMessage('Falha no envio de informações. Checar campos obrigatórios.');
        setAlertSeverity('error');
        setAlert(true);
      }
    }
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box fontWeight={700} fontSize={12} mb={8}>
        Redifinir Senha
      </Box>
      <Box mb={4} px={4}>
        <TextField
          required
          type="password"
          variant="outlined"
          fullWidth
          size="small"
          id="user-old-password-input"
          label="Senha Atual"
          value={oldPassword}
          onChange={e => setOldPassword(e.target.value)}
          helperText="Digite sua senha atual."
        />
      </Box>
      <Box mb={4} px={4}>
        <TextField
          required
          type="password"
          variant="outlined"
          fullWidth
          size="small"
          id="user-new-password-input"
          label="Senha Nova"
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
          helperText="A senha deve ter no minímo 6 digitos."
        />
      </Box>
      <Box mb={4} px={4}>
        <TextField
          required
          type="password"
          variant="outlined"
          fullWidth
          size="small"
          id="user-confirm-password-input"
          label="Confirmar Senha"
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
          helperText="Digite a senha novamente."
        />
      </Box>
      <Box mt={2} display="flex" justifyContent="end">
        <Button
          disabled={!oldPassword && !newPassword && !confirmPassword}
          variant="contained"
          classes={{ root: classes.saveButton }}
          size="small"
          onClick={handleUserPassword}>
          Salvar
        </Button>
      </Box>
      <AccountAlerts message={alertMessage} open={alert} setOpen={setAlert} severity={alertSeverity} />
    </Box>
  );
};

export default ChangePassword;
