import React, { useState, useEffect } from 'react';
import configurationService from '../../../../services/f5-services/configurationService';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';

import sweetAlert from '../../../../f5/utils/sweetAlert';

import OcurrenceNavbar from '../../../../f5/ocurrences/SharedComponents/OcurrenceNavbar';
import ConfigTablePage from '../../../../f5/configuration/ConfigTablePage';
import NewOrUpdate from '../shared/NewOrUpdate';
import SharedActions from '../../../../f5/configuration/SharedActions';

import ServiceCustomForm from './ServiceCustomForm';

const Subject = () => {
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [newOrUpdate, setNewOrUpdate] = useState({
    mode: 'none',
    id: '',
    name: '',
    subject: '',
    subjectId: '',
    duetime: '',
    tags: '',
  });

  const validator = () => {
    if (newOrUpdate.name && newOrUpdate.subjectId) {
      return {
        nome: newOrUpdate.name,
        id: newOrUpdate.id,
        ouv__assunto_id: newOrUpdate.subjectId,
        dias_prazo: newOrUpdate.duetime,
        tags: newOrUpdate.tags.split(', '),
      };
    }

    return false;
  };

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      const results = await configurationService.getConfigParameter('servico');
      setServices(
        results.items.map(item => ({
          name: item.nome,
          id: item.id,
          subject: item.assunto.nome,
          subjectId: item.assunto.id,
          duetime: item.dias_prazo,
          tags: '',
        })),
      );
      setSubjects(results.fills.assunto);
      setLoading(false);
    };
    getData();
  }, [setLoading, setSubjects]);

  const resetNewOrUpdate = () => {
    setNewOrUpdate({ mode: 'none' });
  };

  const handleDelete = async id => {
    setLoading(true);
    const deleteResult = await configurationService.deleteConfigParameter(id, 'servico');
    const results = await configurationService.getConfigParameter('servico');
    setServices(
      results.items.map(item => ({
        name: item.nome,
        id: item.id,
        subject: item.assunto.nome,
        subjectId: item.assunto.id,
        duetime: item.dias_prazo,
        tags: '',
      })),
    );
    setLoading(false);
    if (deleteResult.success) {
      sweetAlert('success', 'Sucesso', 'Serviço foi deletada com sucesso!', () => {
        resetNewOrUpdate();
      });
    } else {
      sweetAlert('error', 'Falha', 'Não foi possível deletar serviço!', () => {
        resetNewOrUpdate();
      });
    }
  };

  const handleCreate = async data => {
    setLoading(true);
    const addResult = await configurationService.addConfigParameter('servico', data);
    const results = await configurationService.getConfigParameter('servico');
    setServices(
      results.items.map(item => ({
        name: item.nome,
        id: item.id,
        subject: item.assunto.nome,
        subjectId: item.assunto.id,
        duetime: item.dias_prazo,
        tags: '',
      })),
    );
    setLoading(false);
    if (addResult.success) {
      sweetAlert('success', 'Sucesso', 'Serviço foi adicionada com sucesso!', () => {
        resetNewOrUpdate();
      });
    } else {
      sweetAlert('error', 'Falha', 'Não foi possível adicionar serviço!', () => {
        resetNewOrUpdate();
      });
    }
  };

  const handleEdit = async data => {
    setLoading(true);
    const addResult = await configurationService.editConfigParameter('servico', data);
    const results = await configurationService.getConfigParameter('servico');
    setServices(
      results.items.map(item => ({
        name: item.nome,
        id: item.id,
        subject: item.assunto.nome,
        subjectId: item.assunto.id,
        duetime: item.dias_prazo,
        tags: '',
      })),
    );
    setLoading(false);
    if (addResult.success) {
      sweetAlert('success', 'Sucesso', 'Serviço foi editada com sucesso!', () => {
        resetNewOrUpdate();
      });
    } else {
      sweetAlert('error', 'Falha', 'Não foi possível editar serviço!', () => {
        resetNewOrUpdate();
      });
    }
  };

  const columns = [
    {
      title: 'Nome',
      field: 'name',
    },
    {
      title: 'Assunto',
      field: 'subject',
    },
    {
      title: 'Prazo (em dias)',
      field: 'duetime',
    },
    {
      title: 'Ações',
      field: 'actions',
      width: '20%',
      render: rowData => (
        <SharedActions
          editCallback={() =>
            setNewOrUpdate({
              mode: 'edit',
              name: rowData.name,
              id: rowData.id,
              subjectId: rowData.subjectId,
              duetime: rowData.duetime,
              tags: '',
            })
          }
          deleteCallback={() => handleDelete(rowData.id)}
        />
      ),
    },
  ];

  return (
    <PageContainer>
      <OcurrenceNavbar />
      {newOrUpdate.mode !== 'none' ? (
        <NewOrUpdate
          newOrUpdate={newOrUpdate}
          setNewOrUpdate={setNewOrUpdate}
          handleCreate={handleCreate}
          handleEdit={handleEdit}
          loading={loading}
          validator={validator}
          addTitle="Adicionar novo serviço"
          editTitle="Editar serviço">
          <ServiceCustomForm newOrUpdate={newOrUpdate} setNewOrUpdate={setNewOrUpdate} subjects={subjects} />
        </NewOrUpdate>
      ) : (
        <ConfigTablePage
          loading={loading}
          data={services}
          columns={columns}
          title={'Serviços'}
          newCallback={() => setNewOrUpdate({ mode: 'add' })}
        />
      )}
    </PageContainer>
  );
};

export default Subject;
