import { Box, Button, Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import React from 'react';

export default function AnexoExtras({ data, onEdit, onAdd = () => { }, onDelete = () => { }, mode, ...props }) {
    return (
        <Grid container spacing={3} alignItems={'center'}>
            <Grid item xs={6}>
                <TextField
                    label="Nome"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={data.label}
                    onChange={e => {
                        onEdit(props.position, { ...data, label: e.target.value });
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <FormControlLabel
                    label="Obrigatório"
                    control={
                        <Checkbox
                            checked={data.required}
                            onChange={e => {
                                onEdit(props.position, { ...data, required: e.target.checked });
                            }}
                        />
                    }
                />
            </Grid>

            <Grid item xs={12}>
                <Box display="flex" alignItems="center" justifyContent="flex-end">
                    {mode === 'new' ? (
                        <Button
                            className="btn btn-primary"
                            onClick={() => {
                                onAdd();
                            }}>
                            Adicionar
                        </Button>
                    ) : (
                        <Button
                            className="btn btn-danger"
                            onClick={() => {
                                onDelete(props.position);
                            }}>
                            Remover
                        </Button>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
}
