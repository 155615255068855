import React, { useEffect, useState } from 'react';
import { TextField, FormGroup, FormControlLabel, Checkbox, Button, Grid, Box, FormHelperText } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import moment from 'moment';

const days = [
    { name: 'Domingo', id: 'domingo' },
    { name: 'Segunda', id: 'segunda' },
    { name: 'Terça', id: 'terca' },
    { name: 'Quarta', id: 'quarta' },
    { name: 'Quinta', id: 'quinta' },
    { name: 'Sexta', id: 'sexta' },
    { name: 'Sábado', id: 'sabado' },
    { name: 'Feriados', id: 'feriados' },
];

export default function HorarioEditar({
    mode,
    data,
    onAdd = () => { },
    onEdit = () => { },
    onDelete = () => { },
    position,
    ...props
}) {
    const [permanent, setPermanent] = useState(true);

    useEffect(() => {
        if (data.begin && data.finale) {
            setPermanent(false);
        }
    }, [data]);

    const changeDay = day => {
        onEdit(position, { ...data, [day]: !data[day] });
    };

    return (
        <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid item xs={12}>
                        <h3>Configurações do Horário</h3>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={data.enabled}
                                    onChange={() => {
                                        onEdit(position, { ...data, enabled: !data.enabled });
                                    }}
                                />
                            }
                            label={'Horário ativo'}
                        />
                        <FormHelperText>Marque para ativar este horário</FormHelperText>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Número de vagas"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={data.vagas}
                            onChange={e => {
                                onEdit(position, {
                                    ...data,
                                    vagas: parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value),
                                });
                            }}
                            helperText="Vagas disponíveis para este horário, deixe em branco para ilimitado"
                            InputProps={{ type: 'number', inputProps: { min: 0 } }}
                        />
                    </Grid>
                    <Grid item xs={8}></Grid>
                    <Grid item container xs={6} spacing={3}>
                        <Grid item xs={12}>
                            <Box mt={3}>
                                <h3>Horário a ser reservado</h3>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.periodo ? true : false}
                                        onChange={() => {
                                            onEdit(position, {
                                                ...data,
                                                periodo: data.periodo ? '' : `${data.start} - ${data.end}`,
                                            });
                                        }}
                                    />
                                }
                                label={'Reservar Período'}
                            />
                            <FormHelperText>Marque para mostrar um texto no lugar do horário</FormHelperText>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                label="Período"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                value={data.periodo}
                                disabled={data.periodo ? false : true}
                                onChange={e => {
                                    onEdit(position, { ...data, periodo: e.target.value });
                                }}
                                helperText="Ex: Manhã, Tarde, Noite, etc. (opcional)"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <KeyboardTimePicker
                                margin="normal"
                                fullWidth
                                label="Horário de início"
                                value={moment(data.start, 'HH:mm:ss').toDate()}
                                inputVariant="outlined"
                                ampm={false}
                                onChange={e => onEdit(position, { ...data, start: moment(e).format('HH:mm:ss') })}
                                KeyboardButtonProps={{
                                    'aria-label': 'Mudar horário',
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <KeyboardTimePicker
                                margin="normal"
                                fullWidth
                                label="Horário de término"
                                value={moment(data.end, 'HH:mm:ss').toDate()}
                                inputVariant="outlined"
                                ampm={false}
                                onChange={e => onEdit(position, { ...data, end: moment(e).format('HH:mm:ss') })}
                                KeyboardButtonProps={{
                                    'aria-label': 'Mudar horário',
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={6} spacing={3} justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs={12}>
                            <h3>Dias da semana para agendar</h3>
                        </Grid>
                        <Grid item xs={12}>
                            <FormGroup row>
                                {days.map(day => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data[day.id]}
                                                onChange={() => {
                                                    changeDay(day.id);
                                                }}
                                                name={day.id}
                                            />
                                        }
                                        label={day.name}
                                    />
                                ))}
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Box mt={3}>
                            <h3>Período que o serviço estará disponível</h3>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <KeyboardDatePicker
                            margin="normal"
                            fullWidth
                            format="dd/MM/yyyy"
                            label="Início do serviço"
                            disabled={permanent}
                            value={moment(data.begin, 'YYYY-MM-DD').toDate()}
                            inputVariant="outlined"
                            error={permanent ? false : data.finale < data.begin}
                            onChange={e => onEdit(position, { ...data, begin: moment(e).format('YYYY-MM-DD') })}
                            KeyboardButtonProps={{
                                'aria-label': 'Mudar dia',
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <KeyboardDatePicker
                            margin="normal"
                            fullWidth
                            format="dd/MM/yyyy"
                            label="Fim do serviço"
                            disabled={permanent}
                            value={moment(data.finale, 'YYYY-MM-DD').toDate()}
                            inputVariant="outlined"
                            error={permanent ? false : data.finale < data.begin}
                            onChange={e => onEdit(position, { ...data, finale: moment(e).format('YYYY-MM-DD') })}
                            KeyboardButtonProps={{
                                'aria-label': 'Mudar dia',
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={permanent}
                                    onChange={() => {
                                        setPermanent(!permanent);
                                        onEdit(position, { ...data, begin: '', finale: '' });
                                    }}
                                />
                            }
                            label={'Sempre Aberto'}
                        />
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
            <Box display="flex" justifyContent="end">
                {mode === 'edit' ? (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            onDelete(position);
                        }}>
                        Excluir
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            onAdd();
                        }}>
                        Adicionar
                    </Button>
                )}
            </Box>
        </div>
    );
}
