import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React from 'react';
import ChipBox from './ChipBox';

export default function PerguntasExtras({ data, onEdit, onAdd = () => { }, onDelete = () => { }, mode, ...props }) {
    return (
        <div className="w-100">
            <TextField
                label="Pergunta"
                fullWidth
                margin="normal"
                variant="outlined"
                value={data.name}
                onChange={e => {
                    onEdit(props.position, { ...data, name: e.target.value });
                }}
            />
            <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel>Tipo de Pergunta</InputLabel>
                <Select
                    value={data.type}
                    onChange={e => {
                        onEdit(props.position, { ...data, type: e.target.value });
                    }}>
                    <MenuItem value={'text'}>Campo de Texto Aberto</MenuItem>
                    <MenuItem value={'select'}>Campo de Seleção</MenuItem>
                    <MenuItem value={'upload'}>Envio de Arquivo</MenuItem>
                </Select>
            </FormControl>
            {data.type === 'select' ? (
                <ChipBox
                    value={data?.options ?? []}
                    label="Opções"
                    onChange={value => {
                        onEdit(props.position, { ...data, options: value });
                    }}
                />
            ) : null}
            {mode === 'new' ? (
                <Button
                    className="btn btn-primary"
                    onClick={() => {
                        onAdd();
                    }}>
                    Adicionar
                </Button>
            ) : (
                <Button
                    className="btn btn-danger"
                    onClick={() => {
                        onDelete(props.position);
                    }}>
                    Remover
                </Button>
            )}
        </div>
    );
}
