import React, { ReactNode, useRef, useState } from 'react';
import { Box, Button, IconButton, Paper } from '@material-ui/core';
import MaterialTable, { Column, Action } from '@material-table/core';
import { tableIcons, tableLocalization } from '../@jumbo/constants/TableConstants';
import axios_instance from '../services/auth/jwt/config';
import { Add, ArrowLeft, Edit, Save } from '@material-ui/icons';
import sweetAlert from './utils/sweetAlert';

interface SimpleCrudProps {
    object: any;
    onLoad: (object: any) => void;
    formComponent: ReactNode;
    link: {
        load: string;
        save: string;
        delete: string;
    };
    titles: {
        modal: string;
        table: string;
        success: string;
        loading: string;
        toDelete: string;
    };
    columns: Array<Column<any>>;
    onNew: () => void;
    onFinish: () => void;
    extraActions?: Array<Action<any>>;
    hasFile?: boolean;
    customSave?: (onSave: () => void) => React.ReactNode;
}

export default function SimpleCrud({
    object,
    onLoad,
    formComponent,
    link,
    titles,
    columns,
    onNew,
    onFinish,
    extraActions = [],
    hasFile = false,
    customSave = onSave => {
        return (
            <Box display="flex" justifyContent="flex-end" mt={3}>
                <Button variant="contained" color="primary" onClick={onSave}>
                    <Save />
                    &nbsp;Salvar
                </Button>
            </Box>
        );
    },
    ...props
}: SimpleCrudProps) {
    const [adding, setAdding] = useState(false);
    const [toEdit, setToEdit] = useState(0);
    const [loading, setLoading] = useState(false);

    const tableRef = useRef<any>(null);

    const onSave = () => {
        setLoading(true);
        if (hasFile) {
            let file = [
                {
                    name: 'anexo',
                    file: object.file,
                },
            ];
            let otherData = [];
            for (let key in object) {
                if (key !== 'file') {
                    otherData.push({
                        name: key,
                        value: object[key],
                    });
                }
            }
            let form = new FormData();
            file.forEach(f => {
                form.append(f.name, f.file);
            });
            otherData.forEach(f => {
                form.append(f.name, f.value);
            });
            axios_instance
                .post<any>(`${link.save}${toEdit > 0 ? `/${toEdit}` : ''}`, form, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(response => {
                    let r = response.data;
                    if (r.success) {
                        sweetAlert('success', titles.success);
                    } else {
                        sweetAlert('error', r.message);
                    }
                    onFinish();
                    setLoading(false);
                })
                .catch(() => {
                    sweetAlert('error', 'Erro ao salvar');
                    setLoading(false);
                });
        } else {
            let form = {
                ...object,
            };
            axios_instance
                .post<any>(`${link.save}${toEdit > 0 ? `/${toEdit}` : ''}`, form)
                .then(response => {
                    let r = response.data;
                    if (r.success) {
                        sweetAlert('success', titles.success);
                    } else {
                        sweetAlert('error', r.message);
                    }
                    onFinish();
                    setLoading(false);
                })
                .catch(() => {
                    sweetAlert('error', 'Erro ao salvar');
                    setLoading(false);
                });
        }
    };

    /* const onClose = () => {
        setSuccess(false);
        setError({ has: false, message: '' });
    };

    const onSuccess = () => {
        setSuccess(false);
        setError({ has: false, message: '' });
        setAdding(false);
        setToEdit(0);
        tableRef.current && tableRef.current.onQueryChange();
    };

    const onDelete = (realDelete: string) => {
        setLoading(true);
        setToDelete(0);
        axios_instance
            .post<any>(`${link.delete}/${realDelete}`, {})
            .then(response => {
                let r = response.data;
                if (r.success) {
                    setSuccess(true);
                } else {
                    setError({ has: true, message: r.message });
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setError({ has: true, message: 'Erro ao deletar' });
            });
    }; */

    return (
        <div className="app-wrapper">
            {adding ? (
                <>
                    <Box mb={3}>
                        <Paper>
                            <Box p={3} display="flex" justifyContent="space-between" alignItems="center">
                                <h3>{titles.modal}</h3>
                                <IconButton onClick={() => setAdding(false)}>
                                    <ArrowLeft />
                                </IconButton>
                            </Box>
                        </Paper>
                    </Box>
                    <Paper>
                        <Box p={3}>
                            {formComponent}
                            {customSave(onSave)}
                        </Box>
                    </Paper>
                </>
            ) : (
                <>
                    <MaterialTable
                        title={titles.table}
                        icons={tableIcons}
                        localization={tableLocalization}
                        columns={columns}
                        isLoading={loading}
                        options={{
                            actionsColumnIndex: -1,
                        }}
                        tableRef={tableRef}
                        actions={[
                            ...extraActions,
                            {
                                isFreeAction: true,
                                icon: () => <Add />,
                                tooltip: 'Adicionar',
                                onClick: () => {
                                    setAdding(true);
                                    setToEdit(0);
                                    onNew();
                                },
                            },
                            {
                                icon: () => <Edit />,
                                tooltip: 'Editar',
                                onClick: (_event: Event, rowData: any) => {
                                    setAdding(true);
                                    setToEdit(rowData.id);
                                    onLoad(rowData);
                                },
                            },
                            /* {
                                icon: () => <Delete />,
                                tooltip: 'Excluir',
                                onClick: (_event: Event, rowData: any) => {
                                    setToDelete(rowData.id);
                                },
                            }, */
                        ]}
                        data={(query: any) =>
                            new Promise<any>((resolve, reject) => {
                                let url = `${link.load}`;
                                url += '?per_page=' + query.pageSize;
                                url += '&page=' + (query.page + 1);
                                if (query.orderBy) {
                                    url += '&order_by=' + query.orderBy.field;
                                    url += '&order=' + query.orderDirection;
                                }
                                if (query.search) {
                                    url += '&search=' + query.search;
                                }
                                axios_instance
                                    .get<any>(url)
                                    .then(response => {
                                        let r = response.data;
                                        if (r.success) {
                                            resolve({
                                                data: r.data.data,
                                                page: r.data.current_page - 1,
                                                totalCount: r.data.total,
                                            });
                                        }
                                    })
                                    .catch(() => {
                                        reject();
                                    });
                            })
                        }
                    />
                </>
            )}
        </div>
    );
}
