import { schemaRequesterCPF, schemaRequesterCNPJ, schemaData } from './schemas';

export default function validate(createOcurrenceForm, step) {
    if (step === 'requester') {
        if (createOcurrenceForm.requesterType === 'person') {
            try {
                const valid = schemaRequesterCPF.validateSync(
                    {
                        requesterCpfValue: createOcurrenceForm.requesterCpfValue,
                        personName: createOcurrenceForm.personName,
                        personEmail: createOcurrenceForm.personEmail,
                        personPhone: createOcurrenceForm.personPhone,
                        personCellphone: createOcurrenceForm.personCellphone,
                        personMaritalStatus: createOcurrenceForm.personMaritalStatus,
                        personEducation: createOcurrenceForm.personEducation,
                        personSex: createOcurrenceForm.personSex,
                        personBirthday: createOcurrenceForm.personBirthday,
                        personProfession: createOcurrenceForm.personProfession,
                        requesterCepValue: createOcurrenceForm.requesterCepValue,
                        personAddressNumber: createOcurrenceForm.personAddressNumber,
                        personAddressComplement: createOcurrenceForm.personAddressComplement,
                        personAddressStreet: createOcurrenceForm.personAddressStreet,
                        personAddressDistrict: createOcurrenceForm.personAddressDistrict,
                        personAddressCity: createOcurrenceForm.personAddressCity,
                    },
                    {
                        abortEarly: false,
                    },
                );

                return { isValid: !!valid, errorList: {} };
            } catch (err) {
                const errorList = err.inner.reduce((acc, e) => {
                    if (acc[`${e.path}`] !== 'required') {
                        acc[`${e.path}`] = e.message.includes('required') ? 'required' : 'invalid';
                    }
                    return acc;
                }, {});
                return { isValid: false, errorList };
            }
        } else {
            try {
                const valid = schemaRequesterCNPJ.validateSync(
                    {
                        personName: createOcurrenceForm.personName,
                        personEmail: createOcurrenceForm.personEmail,
                        personPhone: createOcurrenceForm.personPhone,
                        personCellphone: createOcurrenceForm.personCellphone,
                        personMaritalStatus: createOcurrenceForm.personMaritalStatus,
                        personEducation: createOcurrenceForm.personEducation,
                        personSex: createOcurrenceForm.personSex,
                        personBirthday: createOcurrenceForm.personBirthday,
                        personProfession: createOcurrenceForm.personProfession,
                        requesterCepValue: createOcurrenceForm.requesterCepValue,
                        personAddressNumber: createOcurrenceForm.personAddressNumber,
                        personAddressComplement: createOcurrenceForm.personAddressComplement,
                        personAddressStreet: createOcurrenceForm.personAddressStreet,
                        personAddressDistrict: createOcurrenceForm.personAddressDistrict,
                        personAddressCity: createOcurrenceForm.personAddressCity,

                        requesterCnpjValue: createOcurrenceForm.requesterCnpjValue,
                        companyCep: createOcurrenceForm.companyCep,
                        companyName: createOcurrenceForm.companyName,
                        companyEmail: createOcurrenceForm.companyEmail,
                        companyRegister: createOcurrenceForm.companyRegister,
                        companyPhone: createOcurrenceForm.companyPhone,
                        companyAddressNumber: createOcurrenceForm.companyAddressNumber,
                        companyAddressComplement: createOcurrenceForm.companyAddressComplement,
                        companyAddressStreet: createOcurrenceForm.companyAddressStreet,
                        companyAddressDistrict: createOcurrenceForm.companyAddressDistrict,
                        companyAddressCity: createOcurrenceForm.companyAddressCity,
                    },
                    {
                        abortEarly: false,
                    },
                );

                return { isValid: !!valid, errorList: {} };
            } catch (err) {
                const errorList = err.inner.reduce((acc, e) => {
                    if (acc[`${e.path}`] !== 'required') {
                        acc[`${e.path}`] = e.message.includes('required') ? 'required' : 'invalid';
                    }
                    return acc;
                }, {});
                return { isValid: false, errorList };
            }
        }
    } else if (step === 'data') {
        try {
            const valid = schemaData.validateSync(
                {
                    ocurrenceDataCategory: createOcurrenceForm.ocurrenceDataCategory,
                    ocurrenceDataGravity: createOcurrenceForm.ocurrenceDataGravity,
                    ocurrenceDataSector: createOcurrenceForm.ocurrenceDataSector,
                    ocurrenceDataEntry: createOcurrenceForm.ocurrenceDataEntry,
                    ocurrenceDataInputFile: createOcurrenceForm.ocurrenceDataInputFile,
                    ocurrenceDataInputFileData: createOcurrenceForm.ocurrenceDataInputFileData,
                    ocurrenceDataTheme: createOcurrenceForm.ocurrenceDataTheme,
                    ocurrenceDataSubject: createOcurrenceForm.ocurrenceDataSubject,
                    ocurrenceDataService: createOcurrenceForm.ocurrenceDataService,
                    ocurrenceDataDescription: createOcurrenceForm.ocurrenceDataDescription,
                    ocurrenceDataCep: createOcurrenceForm.ocurrenceDataCep,
                    ocurrenceDataAddressNumber: createOcurrenceForm.ocurrenceDataAddressNumber,
                    ocurrenceDataAddressComplement: createOcurrenceForm.ocurrenceDataAddressComplement,
                    ocurrenceDataAddressStreet: createOcurrenceForm.ocurrenceDataAddressStreet,
                    ocurrenceDataAddressDistrict: createOcurrenceForm.ocurrenceDataAddressDistrict,
                },
                {
                    abortEarly: false,
                },
            );

            return { isValid: !!valid, errorList: {} };
        } catch (err) {
            const errorList = err.inner.reduce((acc, e) => {
                if (acc[`${e.path}`] !== 'required') {
                    acc[`${e.path}`] = e.message.includes('required') ? 'required' : 'invalid';
                }
                return acc;
            }, {});
            return { isValid: false, errorList };
        }
    }
}
