import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';

const customMargin = {
    marginTop: 0,
    marginBottom: 20,
};

export default function RadioCompact({ options, updateInfo, name, variable, current, vertical, vazio, disabled, ...props }) {
    const updateThisInfo = (infName, infValue) => {
        updateInfo(infValue.target.value, infName);
    };

    var will = '';
    if (vertical) {
        will = 'd-flex flex-row';
    }
    var dis = false;
    if (disabled) {
        dis = true;
    }
    return (
        <FormControl style={customMargin} fullWidth>
            <FormLabel>{name}</FormLabel>
            <RadioGroup className={will} name={variable} value={current} onChange={evt => updateThisInfo(variable, evt)}>
                {options
                    ? Object.entries(options).map(opt => {
                        var isit = false;
                        if (current && opt[0].toString() === current.toString()) {
                            isit = true;
                        }
                        return (
                            <FormControlLabel
                                disabled={dis}
                                key={opt[0] + variable}
                                value={opt[0]}
                                control={<Radio color="primary" checked={isit} />}
                                label={opt[1]}
                            />
                        );
                    })
                    : null}
                {vazio ? (
                    <FormControlLabel disabled={dis} value="" control={<Radio color="primary" />} label="N/A" />
                ) : (
                    <></>
                )}
            </RadioGroup>
        </FormControl>
    );
}
