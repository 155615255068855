import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import generalService from '../../../services/f5-services/generalService';
import { CircularProgress, Box } from '@material-ui/core';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';

import OcurrenceNavbar from '../../../f5/ocurrences/SharedComponents/OcurrenceNavbar';
import SearchPage from '../../../f5/search/SearchPage';

const Search = () => {
  const [searchData, setSearchData] = useState(null);
  const [departments, setDepartments] = useState(null);

  const query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    const getSearchData = async () => {
      const search_response = await generalService.getSearchData();
      setSearchData(search_response.items);

      const departments_response = await generalService.getDepartments();
      setDepartments(departments_response.data);
    };

    getSearchData();
  }, [setSearchData, setDepartments]);

  const loader = () => {
    return (
      <Box width="100%" height={150} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  };

  return (
    <PageContainer>
      <OcurrenceNavbar />
      {searchData !== null && departments !== null ? (
        <SearchPage
          searchData={searchData}
          departments={departments}
          currentSearch={query.get('p')}
          currentType={query.get('modo')}
        />
      ) : (
        loader()
      )}
    </PageContainer>
  );
};

export default Search;
