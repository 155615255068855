import React, { useState } from 'react';
import {
    Card,
    TextField,
    InputLabel,
    Button,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Tabs,
    Tab,
    Box,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PerguntasExtras from './PerguntasExtras';
import HorarioEditar from './HorarioEditar';
import moment from 'moment';
import TinyEditor from './TinyEditor';
import { Save } from '@material-ui/icons';
import AnexoExtras from './AnexoExtra';

export default function ServicoEditar({ mode, handleSave, servico, onChange, onClose, ...props }) {
    const [newHorario, setNewHorario] = useState({
        start: moment().format('HH:mm:ss'),
        end: moment().format('HH:mm:ss'),
        begin: moment().format('YYYY-MM-DD'),
        finale: moment()
            .add(1, 'month')
            .format('YYYY-MM-DD'),
        domingo: false,
        segunda: false,
        terca: false,
        quarta: false,
        quinta: false,
        sexta: false,
        sabado: false,
        feriados: false,
        enabled: true,
        id: -1,
        periodo: '',
        vagas: 1,
    });
    const [newPergunta, setNewPergunta] = useState({
        type: 'text',
        name: '',
    });
    const [newAnexo, setNewAnexo] = useState({
        required: false,
        label: '',
    });
    const [open, setOpen] = useState(-1);
    const [area, setArea] = useState('horario');
    let title = 'Adicionar novo Serviço';
    switch (mode) {
        case 'edit':
            title = 'Editar serviço';
            break;
        case 'new':
        default:
    }
    const addPergunta = () => {
        onChange({ ...servico, template: [...servico.template, newPergunta] });
        setNewPergunta({ type: 'text', name: '' });
    };
    const editPergunta = (pos, pergunta) => {
        if (pos < 0) {
            setNewPergunta(pergunta);
        } else {
            let template = servico.template;
            template[pos] = pergunta;
            onChange({ ...servico, template });
        }
    };
    const deletePergunta = pos => {
        let template = servico.template;
        template.splice(pos, 1);
        onChange({ ...servico, template });
    };

    const addAnexo = () => {
        onChange({ ...servico, anexos: [...servico.anexos, newAnexo] });
        setNewAnexo({ required: false, label: '' });
    };
    const editAnexo = (pos, anexo) => {
        if (pos < 0) {
            setNewAnexo(anexo);
        } else {
            let anexos = servico.anexos;
            anexos[pos] = anexo;
            onChange({ ...servico, anexos });
        }
    };
    const deleteAnexo = pos => {
        let anexos = servico.anexos;
        anexos.splice(pos, 1);
        onChange({ ...servico, anexos });
    };

    const addHorario = () => {
        onChange({ ...servico, disponibilidades: [...servico.disponibilidades, newHorario] });
        setNewHorario({
            start: moment().format('HH:mm:ss'),
            end: moment().format('HH:mm:ss'),
            begin: moment().format('YYYY-MM-DD'),
            finale: moment()
                .add(1, 'month')
                .format('YYYY-MM-DD'),
            domingo: false,
            segunda: false,
            terca: false,
            quarta: false,
            quinta: false,
            sexta: false,
            sabado: false,
            feriados: false,
            enabled: true,
            id: -1,
            periodo: '',
            vagas: 1,
        });
    };
    const saveHorario = (pos, horario) => {
        if (pos < 0) {
            setNewHorario(horario);
        } else {
            let hold = [...servico.disponibilidades];
            hold[pos] = horario;
            onChange({ ...servico, disponibilidades: hold });
        }
    };
    const deleteHorario = pos => {
        let hold = [...servico.disponibilidades];
        hold.splice(pos, 1);
        onChange({ ...servico, disponibilidades: hold });
    };

    return (
        <Card>
            <Box p={3}>
                <Box display="flex" justifyContent="space-between">
                    <h3 className="card-title">{title}</h3>
                    <Button onClick={onClose} variant="contained" color="secondary">
                        Cancelar
                    </Button>
                </Box>
                <TextField
                    label="Nome do Serviço"
                    fullWidth
                    className="mb-3"
                    value={servico.nome}
                    onChange={e => onChange({ ...servico, nome: e.target.value })}
                />
                <InputLabel className="pb-1">Descrição</InputLabel>
                <TinyEditor
                    value={servico.descricao}
                    onChange={content => {
                        onChange({ ...servico, descricao: content });
                    }}
                />
            </Box>
            <Tabs
                onChange={(e, value) => {
                    setArea(value);
                    setOpen(-1);
                }}
                value={area}
                variant="fullWidth">
                <Tab value="horario" label="Horários" />
                <Tab value="pergunta" label="Informações Extras" />
                <Tab value="anexo" label="Anexos" />
            </Tabs>

            {area === 'horario' ? (
                <div>
                    {servico.disponibilidades.map((disponibilidade, pos) => (
                        <Accordion
                            expanded={open === pos}
                            onChange={() => {
                                setOpen(open !== pos ? pos : -1);
                            }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>
                                    Horário #{pos + 1} ({disponibilidade.start} - {disponibilidade.end})
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <HorarioEditar
                                    key={pos}
                                    data={disponibilidade}
                                    position={pos}
                                    mode="edit"
                                    onEdit={saveHorario}
                                    onDelete={deleteHorario}
                                />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    <Accordion
                        expanded={open === -2}
                        onChange={() => {
                            setOpen(open !== -2 ? -2 : -1);
                        }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Novo horário</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <HorarioEditar
                                mode="add"
                                position={-1}
                                data={newHorario}
                                onAdd={addHorario}
                                onEdit={saveHorario}
                            />
                        </AccordionDetails>
                    </Accordion>
                </div>
            ) : null}
            {area === 'pergunta' ? (
                <div>
                    <Box p={3}>
                        <h4>Perguntas adicionais que serão exibidas no formulário de agendamento</h4>
                    </Box>
                    {servico.template.map((pergunta, pos) => (
                        <Accordion
                            expanded={open === pos}
                            onChange={() => {
                                setOpen(open !== pos ? pos : -1);
                            }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>
                                    Pergunta #{pos + 1}: {pergunta.name}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <PerguntasExtras
                                    data={pergunta}
                                    position={pos}
                                    mode="edit"
                                    onEdit={editPergunta}
                                    onDelete={deletePergunta}
                                />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    <Accordion
                        expanded={open === -2}
                        onChange={() => {
                            setOpen(open !== -2 ? -2 : -1);
                        }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Nova Pergunta</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <PerguntasExtras
                                mode="new"
                                position={-1}
                                data={newPergunta}
                                onAdd={addPergunta}
                                onEdit={editPergunta}
                            />
                        </AccordionDetails>
                    </Accordion>
                </div>
            ) : null}
            {area === 'anexo' ? (
                <div>
                    <Box p={3}>
                        <h4>Arquivos que devem ser anexados para o agendamento</h4>
                    </Box>
                    {servico?.anexos?.map((anexo, pos) => (
                        <Accordion
                            expanded={open === pos}
                            onChange={() => {
                                setOpen(open !== pos ? pos : -1);
                            }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>
                                    Anexo #{pos + 1}: {anexo.name}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <AnexoExtras
                                    data={anexo}
                                    position={pos}
                                    mode="edit"
                                    onEdit={editAnexo}
                                    onDelete={deleteAnexo}
                                />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    <Accordion
                        expanded={open === -2}
                        onChange={() => {
                            setOpen(open !== -2 ? -2 : -1);
                        }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Novo Anexo</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AnexoExtras mode="new" position={-1} data={newAnexo} onAdd={addAnexo} onEdit={editAnexo} />
                        </AccordionDetails>
                    </Accordion>
                </div>
            ) : null}
            <Box p={3} display="flex" justifyContent="end">
                <Button className="bg-success text-white" variant="contained" startIcon={<Save />} onClick={handleSave}>
                    Salvar
                </Button>
            </Box>
        </Card>
    );
}
