import React, { useState } from 'react';
import { Box, TextField, Button } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ocurrencesActionService from '../../../../services/f5-services/ocurrencesActionService';

const ServiceFields = ({ formFieldReference, createOcurrenceForm, handleFormChange, validateMe }) => {
    const [relatedSubjects, setRelatedSubjects] = useState([]);
    const [relatedServices, setRelatedServices] = useState([]);
    const [subjectsLoading, setSubjectsLoading] = useState(false);
    const [servicesLoading, setServicesLoading] = useState(false);

    const handleTheme = async (e, newValue) => {
        if (newValue && newValue.id > 0) {
            setSubjectsLoading(true);
            const subjectsData = await ocurrencesActionService.getRelatedSubjects(newValue.id);
            setRelatedSubjects(subjectsData.items);
            handleFormChange(null, {
                ocurrenceDataTheme: newValue.id,
                ocurrenceDataSubject: '',
                ocurrenceDataService: '',
            });
            setRelatedServices([]);
            setSubjectsLoading(false);
        } else {
            setRelatedSubjects([]);
            setRelatedServices([]);
            handleFormChange(null, {
                ocurrenceDataTheme: '',
                ocurrenceDataSubject: '',
                ocurrenceDataService: '',
            });
        }
    };

    const handleSubject = async (e, newValue) => {
        if (newValue && newValue.id > 0) {
            setServicesLoading(true);
            const servicesData = await ocurrencesActionService.getRelatedServices(newValue.id);
            setRelatedServices(servicesData.items);
            handleFormChange(null, {
                ocurrenceDataSubject: newValue.id,
                ocurrenceDataService: '',
            });
            setServicesLoading(false);
        } else {
            setRelatedServices([]);
            handleFormChange(null, {
                ocurrenceDataService: '',
            });
        }
    };

    const handleService = async (e, newValue) => {
        if (newValue && newValue.id > 0) {
            handleFormChange(null, {
                ocurrenceDataService: newValue.id,
            });
        }
    };

    const handleFileChange = event => {
        handleFormChange(null, {
            ocurrenceDataInputFile: event.target.files[0].name,
            ocurrenceDataInputFileData: event.target.files[0],
        });
    };

    return (
        <Box>
            <Box display="flex" mb={4} mt={2}>
                <Box flex={1} pr={8}>
                    <Autocomplete
                        openText="Abrir"
                        clearText="Limpar"
                        noOptionsText="Nenhuma opção"
                        fullWidth
                        options={formFieldReference.temas}
                        getOptionLabel={option => option.nome}
                        renderInput={params => (
                            <TextField
                                required
                                id="ocurrence-theme-input"
                                inputProps={{ tabIndex: 204 }}
                                {...params}
                                label="Tema"
                                error={validateMe('ocurrenceDataTheme').isError}
                                helperText={validateMe('ocurrenceDataTheme').message}
                            />
                        )}
                        onChange={handleTheme}
                    />
                </Box>
                <Box flex={1} pr={8}>
                    <Autocomplete
                        openText="Abrir"
                        clearText="Limpar"
                        noOptionsText="Nenhuma opção"
                        disabled={!createOcurrenceForm.ocurrenceDataTheme}
                        fullWidth
                        options={relatedSubjects}
                        getOptionLabel={option => option.nome}
                        renderInput={params => (
                            <TextField
                                required
                                id="ocurrence-subject-input"
                                inputProps={{ tabIndex: 205 }}
                                {...params}
                                label="Assunto"
                                error={validateMe('ocurrenceDataSubject').isError}
                                helperText={validateMe('ocurrenceDataSubject').message}
                            />
                        )}
                        onChange={handleSubject}
                    />
                    {subjectsLoading && <LinearProgress />}
                </Box>
                <Box flex={1} pr={8}>
                    <Autocomplete
                        openText="Abrir"
                        clearText="Limpar"
                        noOptionsText="Nenhuma opção"
                        disabled={!createOcurrenceForm.ocurrenceDataSubject}
                        fullWidth
                        options={relatedServices}
                        getOptionLabel={option => option.nome}
                        renderInput={params => (
                            <TextField
                                required
                                id="ocurrence-service-input"
                                inputProps={{ tabIndex: 206 }}
                                {...params}
                                label="Serviço"
                                error={validateMe('ocurrenceDataService').isError}
                                helperText={validateMe('ocurrenceDataService').message}
                            />
                        )}
                        onChange={handleService}
                    />
                    {servicesLoading && <LinearProgress />}
                </Box>
            </Box>
            <Box mb={4}>
                <TextField
                    error={validateMe('ocurrenceDataDescription').isError}
                    helperText={validateMe('ocurrenceDataDescription').message}
                    inputProps={{ tabIndex: 207 }}
                    label="Descrição"
                    required
                    multiline
                    minRows={4}
                    variant="outlined"
                    fullWidth
                    name="ocurrenceDataDescription"
                    value={createOcurrenceForm.ocurrenceDataDescription}
                    onChange={handleFormChange}
                />
            </Box>
            <Box mb={4} display="flex" alignItems="center">
                <Button variant="contained" component="label">
                    Anexar Arquivo
                    <input id="quick-answer-file" type="file" hidden onChange={handleFileChange} />
                </Button>
                <Box ml={2} fontWeight={700} fontSize={12}>
                    {createOcurrenceForm.ocurrenceDataInputFile}
                </Box>
            </Box>
        </Box>
    );
};

export default ServiceFields;
