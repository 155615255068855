import { Document, Page, StyleSheet, Text } from '@react-pdf/renderer';
import moment from 'moment';
import React from 'react';

const diasSemana = {
    1: 'Segunda',
    2: 'Terça',
    3: 'Quarta',
    4: 'Quinta',
    5: 'Sexta',
    6: 'Sábado',
    7: 'Domingo',
};

export default function InscricaoPdf({ inscricao, ...props }) {
    return (
        <Document>
            <Page size="A4" style={{ padding: 5, marginHorizontal: 10 }}>
                <Text style={styles.title}>Dados do Aluno</Text>
                <Text>
                    <Text style={styles.label}>Nome: </Text>
                    <Text style={styles.text}>{inscricao.nome}</Text>
                </Text>
                <Text>
                    <Text style={styles.label}>Data de Nascimento: </Text>
                    <Text style={styles.text}>{moment(inscricao.nascimento, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Text>
                </Text>
                <Text>
                    <Text style={styles.label}>Mãe: </Text>
                    <Text style={styles.text}>{inscricao.mae}</Text>
                </Text>
                <Text>
                    <Text style={styles.label}>Pai: </Text>
                    <Text style={styles.text}>{inscricao.pai}</Text>
                </Text>
                <Text>
                    <Text style={styles.label}>Documento: </Text>
                    <Text style={styles.text}>{inscricao.document}</Text>
                </Text>
                <Text>
                    <Text style={styles.label}>Telefone: </Text>
                    <Text style={styles.text}>{inscricao.telefone}</Text>
                </Text>
                <Text>
                    <Text style={styles.label}>Email: </Text>
                    <Text style={styles.text}>{inscricao.email}</Text>
                </Text>
                <Text style={styles.title}>Endereço</Text>
                <Text>
                    <Text style={styles.label}>Endereço: </Text>
                    <Text style={styles.text}>{inscricao.endereco}</Text>
                </Text>
                <Text>
                    <Text style={styles.label}>Número: </Text>
                    <Text style={styles.text}>{inscricao.numero}</Text>
                </Text>
                <Text>
                    <Text style={styles.label}>Bairro: </Text>
                    <Text style={styles.text}>{inscricao.bairro}</Text>
                </Text>
                <Text>
                    <Text style={styles.label}>CEP: </Text>
                    <Text style={styles.text}>{inscricao.cep}</Text>
                </Text>
                <Text style={styles.title}>Outro Dados</Text>
                <Text>
                    <Text style={styles.label}>Escola: </Text>
                    <Text style={styles.text}>{inscricao.escola}</Text>
                </Text>
                <Text>
                    <Text style={styles.label}>Perído: </Text>
                    <Text style={styles.text}>{inscricao.periodo}</Text>
                </Text>
                <Text>
                    <Text style={styles.label}>Tem Problema de Saúde? </Text>
                    <Text style={styles.text}>{inscricao.tem_problema ? 'Sim' : 'Não'}</Text>
                </Text>
                {inscricao.tem_problema ? (
                    <Text>
                        <Text style={styles.label}>Qual? </Text>
                        <Text style={styles.text}>{inscricao.problema_saude}</Text>
                    </Text>
                ) : null}
                <Text>
                    <Text style={styles.label}>Para quem ligar em emergência: </Text>
                    <Text style={styles.text}>{inscricao.urgencia}</Text>
                </Text>
                <Text style={styles.title}>Aula</Text>
                <Text>
                    <Text style={styles.label}>Aula: </Text>
                    <Text style={styles.text}>{inscricao.horario.aula.nome}</Text>
                </Text>
                <Text>
                    <Text style={styles.label}>Turma: </Text>
                    <Text style={styles.text}>{inscricao.horario.nome}</Text>
                </Text>
                <Text>
                    <Text style={styles.label}>Dias: </Text>
                    {inscricao.horario.semana.map((dia, index) => (
                        <Text key={index} style={styles.text}>{`${diasSemana[dia]} (${inscricao.horario.hora[index]} - ${inscricao.horario.fim[index]})`}</Text>
                    ))}
                </Text>
            </Page>
        </Document>
    );
}

const styles = StyleSheet.create({
    title: {
        fontSize: 24,
        textAlign: 'center',
        marginBottom: 20,
        fontWeight: 'bold',
    },
    label: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    text: {
        fontSize: 12,
    },
});
