import React, { useState, useEffect } from 'react';
import { Paper, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import axios_instance, { appURL } from '../../../../services/auth/jwt/config';

export default function RelatorioAgendamento({ start, end, onClose, local, ...props }) {
    const [initialDate, setInitialDate] = useState(new Date());
    const [finalDate, setFinalDate] = useState(new Date());
    const [places, setPlaces] = useState([]);
    const [place, setPlace] = useState({ id: 0 });
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setInitialDate(start);
        setFinalDate(end);
    }, [start, end]);

    useEffect(() => {
        axios_instance.get(`api/locais/list/from/${local.modo}/${local.id}`).then(res => {
            let r = res.data;
            if (r.success) {
                setPlaces(r.items);
            }
        });
    }, [local]);

    const gerarRelatorio = () => {
        setLoading(true);
        fetch(
            `${appURL}api/agendamento/${local.modo}/${local.id
            }/relatorio/agendas?start=${initialDate.toISOString()}&end=${finalDate.toISOString()}${status ? `&status=${status}` : ''
            }${place.id > 0 ? `&local=${place.id}` : ''}`,
            {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('client_key')}`,
                    Accept: 'application/json',
                },
            },
        )
            .then(res => res.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `relatorio_agendamentos_${moment(initialDate).format('DD_MM_YYYY')}_ate_${moment(
                    finalDate,
                ).format('DD_MM_YYYY')}.xlsx`;
                a.click();
                setLoading(false);
            });
    };

    return (
        <div className="app-wrapper container">
            <Paper className="p-3">
                <div>
                    <h3>
                        <b>Gerar Relatório</b>
                    </h3>

                    <FormControl fullWidth margin="normal">
                        <InputLabel>Local de Atendimento</InputLabel>
                        <Select value={place?.id} onChange={e => setPlace({ id: e.target.value })}>
                            {places.map(item => (
                                <MenuItem value={item.id}>{item.nome}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Status do Agendamento</InputLabel>
                        <Select value={status} onChange={e => setStatus(e.target.value)}>
                            <MenuItem value={'pendente'}>Pendentes</MenuItem>
                            <MenuItem value={'aceito'}>Confirmados</MenuItem>
                            <MenuItem value={'completo'}>Completo</MenuItem>
                            <MenuItem value={'falta'}>Faltas</MenuItem>
                            <MenuItem value={'cancelado'}>Cancelados</MenuItem>
                        </Select>
                    </FormControl>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                        <KeyboardDatePicker
                            margin="normal"
                            fullWidth
                            value={initialDate}
                            onChange={e => setInitialDate(e)}
                            emptyLabel="É preciso inserir uma data"
                            invalidDateMessage="A data é inválida"
                            minDateMessage={'A data tem que ser no futuro'}
                            label="Data inicial"
                            format="dd/MM/yyyy"
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                        <KeyboardDatePicker
                            margin="normal"
                            fullWidth
                            value={finalDate}
                            onChange={e => setFinalDate(e)}
                            emptyLabel="É preciso inserir uma data"
                            invalidDateMessage="A data é inválida"
                            minDateMessage={'A data tem que ser no futuro'}
                            label="Data final"
                            format="dd/MM/yyyy"
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <Button onClick={() => onClose()} className="jr-btn bg-danger text-white">
                                Cancelar
                            </Button>
                        </div>
                        <div>
                            <Button
                                onClick={() => gerarRelatorio()}
                                disabled={loading}
                                className="jr-btn bg-success text-white">
                                Gerar Relatório
                            </Button>
                        </div>
                    </div>
                </div>
            </Paper>
        </div>
    );
}
