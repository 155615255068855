import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Dialog } from '@material-ui/core';
import CursoEdit from './CursoEdit';
import { tableIcons, tableLocalization } from '../../../@jumbo/constants/TableConstants';
import axios_instance from '../../../services/auth/jwt/config';
import sweetAlert from '../../../f5/utils/sweetAlert';
import { Add, CloudDownload, Delete, Edit, List } from '@material-ui/icons';
import MaterialTable from '@material-table/core';

export default function CursoList({ ...props }) {
    const [cursos, setCursos] = useState([]);
    const [loading, setLoading] = useState(false);

    const [modal, setModal] = useState('none');
    const [toEdit, setToEdit] = useState(0);

    const history = useHistory();

    const loadData = useCallback(() => {
        setLoading(true);
        axios_instance.get(`curso`).then(res => {
            let r = res.data;
            if (r.success) {
                setCursos(r.data);
            }
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const onCloseModal = (reload = false) => {
        setModal('none');
        if (reload) {
            loadData();
        }
    };

    const onDelete = toDelete => {
        setLoading(true);
        axios_instance
            .post(`curso/delete/${toDelete}`, {})
            .then(res => {
                let r = res.data;
                if (r.success) {
                    sweetAlert('success', 'Sucesso', r.message);
                    loadData();
                } else {
                    sweetAlert('error', 'Erro', r.message);
                }
            })
            .catch(() => {
                sweetAlert('error', 'Erro', 'Ocorreu um erro ao excluir o curso!');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onDownload = curso => {
        axios_instance
            .get(`curso/relatorio/inscritos/${curso.id}`, { responseType: 'blob' })
            .then(res => res.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${curso.nome}_inscritos.xlsx`;
                a.click();
            });
    };

    return (
        <div className="app-wrapper">
            <MaterialTable
                localization={tableLocalization}
                icons={tableIcons}
                data={cursos}
                title="Cursos"
                isLoading={loading}
                columns={[
                    { title: 'Nome', field: 'nome' },
                    { title: 'Realizado Por', field: 'dono' },
                    {
                        title: 'Data de Início',
                        field: 'inicio',
                        render: rowData => {
                            if (!rowData.inicio) return 'Não definido';
                            return new Date(rowData.inicio).toLocaleDateString();
                        },
                    },
                    {
                        title: 'Data de Fim',
                        field: 'fim',
                        render: rowData => {
                            if (!rowData.fim) return 'Não definido';
                            return new Date(rowData.fim).toLocaleDateString();
                        },
                    },
                    {
                        title: 'Limite de Cadstro',
                        field: 'limite_cadastro',
                        render: rowData => {
                            if (!rowData.limite_cadastro) return 'Não definido';
                            return new Date(rowData.limite_cadastro).toLocaleDateString();
                        },
                    },
                    {
                        title: 'Vagas',
                        field: 'vagas',
                        render: rowData => {
                            if (rowData.ilimitado) return 'Ilimitado';
                            return rowData.vagas;
                        },
                    },
                ]}
                actions={[
                    {
                        isFreeAction: true,
                        icon: () => <Add />,
                        tooltip: 'Adicionar Curso',
                        onClick: () => {
                            setModal('add');
                        },
                    },
                    {
                        icon: () => <CloudDownload />,
                        tooltip: 'Baixar Relatório',
                        onClick: (_event, rowData) => {
                            onDownload(rowData);
                        },
                    },
                    {
                        icon: () => <List />,
                        tooltip: 'Visualizar Inscrições',
                        onClick: (_event, rowData) => {
                            history.push(`/cursos/inscricoes/${rowData.id}`);
                        },
                    },
                    {
                        icon: () => <Edit />,
                        tooltip: 'Editar Curso',
                        onClick: (_event, rowData) => {
                            setModal('edit');
                            setToEdit(rowData.id);
                        },
                    },
                    {
                        icon: () => <Delete />,
                        tooltip: 'Excluir Curso',
                        onClick: (_event, rowData) => {
                            sweetAlert('warning', 'Atenção', 'Tem certeza que deseja excluir o curso?', () => {
                                onDelete(rowData.id);
                            });
                        },
                    },
                ]}
                options={{
                    actionsColumnIndex: -1,
                }}
            />
            <Dialog open={modal !== 'none'} onClose={() => setModal('none')} fullWidth maxWidth="lg">
                <CursoEdit onClose={onCloseModal} id={toEdit} mode={modal} />
            </Dialog>
        </div>
    );
}
