import axios from 'axios';

export const appURL =
    process.env.NODE_ENV === 'production' || true
        ? 'https://ribasdigital.cidadeinteligente.app.br'
        : 'http://localhost:8000';

const axios_instance = axios.create({
    baseURL: `${appURL}/api/`,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

export const config = {
    appURL,
};

export default axios_instance;
