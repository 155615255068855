import React, { forwardRef, useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import MaterialTable from '@material-table/core';
import MaskedInput from 'react-text-mask';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { useParams } from 'react-router-dom';
import axios_instance from '../../../../services/auth/jwt/config';
import { tableIcons, tableLocalization } from '../../../../@jumbo/constants/TableConstants';

const CPFMask = props => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => inputRef(ref ? ref.inputElement : null)}
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
        />
    );
};

export default function FindUsuario({ nome, cpf, updateData, handleAdd, ...props }) {
    const [usuarios, setUsuarios] = useState([]);
    const [searching, setSearching] = useState(false);

    const { modo, local } = useParams();

    const onSearch = () => {
        setSearching(true);
        const form = {
            cpf: cpf,
        };
        axios_instance
            .post(`api/agendamento/${modo}/${local}/usuario/busca`, form)
            .then(res => {
                let r = res.data;
                if (r.success) {
                    setUsuarios(r.items);
                    if (r.items.length <= 0) {
                        handleAdd();
                    }
                }
                setSearching(false);
            })
            .catch(() => { });
    };
    return (
        <div>
            <h3>Busca Usuário</h3>
            <TextField
                label="CPF *"
                name="cpf"
                onChange={evt => updateData({ cpf: evt.target.value })}
                fullWidth
                value={cpf}
                InputProps={{ inputComponent: CPFMask }}
                margin="normal"
            />

            <div className="text-center">
                <Button
                    className={`${!cpf ? 'bg-grey' : 'bg-primary'} text-white`}
                    onClick={onSearch}
                    disabled={!cpf}
                    size="large">
                    Buscar
                </Button>
                {false ? (
                    <Button className="jr-btn bg-success text-white" onClick={handleAdd}>
                        Adicionar Novo Usuário
                    </Button>
                ) : null}
            </div>
            {usuarios.length > 0 ? (
                <MaterialTable
                    localization={tableLocalization}
                    icons={tableIcons}
                    components={{ Container: forwardRef((props, ref) => <div {...props} ref={ref} />) }}
                    title="Usuários"
                    data={usuarios}
                    isLoading={searching}
                    options={{
                        pageSize: 10,
                        exportButton: true,
                    }}
                    columns={[
                        { title: 'Nome', field: 'nome' },
                        { title: 'CPF', field: 'cpf' },
                    ]}
                    actions={[
                        {
                            tooltip: 'Selecionar',
                            icon: RadioButtonUncheckedIcon,
                            onClick: (_e, row) => {
                                updateData({
                                    newuser: true,
                                    usuario_id: row.id,
                                    nome: row.nome,
                                    cpf: row.cpf,
                                    nasc: new Date(row.nascimento),
                                    sexo: row.sexo,
                                    rua: row.rua,
                                    numero: row.numero,
                                    bairro: row.bairro,
                                    cidade: row.cidade,
                                    email: row.email,
                                    phone: row.telefone,
                                    pai: row.pai,
                                    mae: row.mae,
                                });
                            },
                        },
                    ]}
                />
            ) : null}
        </div>
    );
}
