import DateFnsUtils from '@date-io/date-fns';
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import sweetAlert from '../../../../f5/utils/sweetAlert';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import axios_instance from '../../../../services/auth/jwt/config';
import ptLocale from 'date-fns/locale/pt-BR';

function PhoneMask(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => inputRef(ref ? ref.inputElement : null)}
            mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
        />
    );
}
const CPFMask = props => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => inputRef(ref ? ref.inputElement : null)}
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
        />
    );
};

function CEPMask(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => inputRef(ref ? ref.inputElement : null)}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
        />
    );
}

export default function NovaInscricao({ open, onClose, aula, id = 0, edit = {}, ...props }) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        nome: '',
        nascimento: '',
        document: '',
        mae: '',
        pai: '',
        endereco: '',
        numero: '',
        cep: '',
        complemento: '',
        bairro: '',
        telefone: '',
        urgencia: '',
        tem_problema: false,
        problema_saude: '',
        escola: '',
        periodo: '',
        horario_id: '',
        obs: '',
    });

    useEffect(() => {
        if (edit.id && id) {
            setData(edit);
        } else {
            setData({
                nome: '',
                nascimento: '',
                document: '',
                mae: '',
                pai: '',
                endereco: '',
                numero: '',
                cep: '',
                complemento: '',
                bairro: '',
                telefone: '',
                urgencia: '',
                tem_problema: false,
                problema_saude: '',
                escola: '',
                periodo: '',
                horario_id: '',
                obs: '',
            });
        }
    }, [id, edit]);

    const onSave = () => {
        setLoading(true);
        let form = {
            ...data,
        };
        axios_instance
            .post(`aula/aula/inscricoes/${aula.id}/update${id ? `/${id}` : ''}`, form)
            .then(res => {
                let r = res.data;
                if (r.success) {
                    sweetAlert('success', 'Sucesso', 'Inscricao realizada com sucesso!');
                    onClose();
                    setData({
                        nome: '',
                        nascimento: '',
                        document: '',
                        mae: '',
                        pai: '',
                        endereco: '',
                        numero: '',
                        cep: '',
                        complemento: '',
                        bairro: '',
                        telefone: '',
                        urgencia: '',
                        tem_problema: false,
                        problema_saude: '',
                        escola: '',
                        periodo: '',
                        horario_id: '',
                        obs: '',
                    });
                } else {
                    sweetAlert('error', 'Erro', r.message);
                }
            })
            .catch(err => {
                sweetAlert('error', 'Erro', err.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    if (loading) {
        return (
            <Dialog open={open}>
                <DialogTitle>{id ? 'Editar' : 'Nova'} Inscrição</DialogTitle>
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
            <DialogTitle>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={11} sm={11}>
                        {id ? 'Editar' : 'Nova'} Inscrição
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <IconButton onClick={onClose}>
                            <i className="fas fa-times" />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <InputLabel id="horario-label">Horário</InputLabel>
                            <Select
                                fullWidth
                                name="horario_id"
                                labelId="horario-label"
                                onChange={e => setData({ ...data, horario_id: e.target.value })}
                                required
                                value={data.horario_id}
                                variant="outlined">
                                {aula.horarios.map(horario => (
                                    <MenuItem key={horario.id} value={horario.id}>
                                        {horario.nome}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label="Nome"
                            name="nome"
                            onChange={e => setData({ ...data, nome: e.target.value })}
                            required
                            value={data.nome}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                            <KeyboardDatePicker
                                margin="normal"
                                fullWidth
                                format="dd/MM/yyyy"
                                label="Nascimento"
                                name="nascimento"
                                value={moment(data.nascimento).toDate()}
                                onChange={e => setData({ ...data, nascimento: moment(e).format('YYYY-MM-DD') })}
                                KeyboardButtonProps={{
                                    'aria-label': 'Mudar dia',
                                }}
                                inputVariant="outlined"
                                InputProps={{
                                    margin: 'dense',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="CPF"
                            name="document"
                            onChange={e => setData({ ...data, document: e.target.value })}
                            required
                            value={data.document}
                            variant="outlined"
                            InputProps={{ inputComponent: CPFMask }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Nome da Mãe"
                            name="mae"
                            onChange={e => setData({ ...data, mae: e.target.value })}
                            required
                            value={data.mae}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Nome do Pai"
                            name="pai"
                            onChange={e => setData({ ...data, pai: e.target.value })}
                            required
                            value={data.pai}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            fullWidth
                            label="Endereço"
                            name="endereco"
                            onChange={e => setData({ ...data, endereco: e.target.value })}
                            required
                            value={data.endereco}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label="Número"
                            name="numero"
                            onChange={e => setData({ ...data, numero: e.target.value })}
                            required
                            value={data.numero}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="CEP"
                            name="cep"
                            onChange={e => setData({ ...data, cep: e.target.value })}
                            required
                            value={data.cep}
                            variant="outlined"
                            InputProps={{ inputComponent: CEPMask }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Bairro"
                            name="bairro"
                            onChange={e => setData({ ...data, bairro: e.target.value })}
                            required
                            value={data.bairro}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label="Complemento"
                            name="complemento"
                            onChange={e => setData({ ...data, complemento: e.target.value })}
                            value={data.complemento}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Telefone"
                            name="telefone"
                            onChange={e => setData({ ...data, telefone: e.target.value })}
                            required
                            value={data.telefone}
                            variant="outlined"
                            InputProps={{ inputComponent: PhoneMask }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Telefone de Urgência"
                            name="urgencia"
                            onChange={e => setData({ ...data, urgencia: e.target.value })}
                            value={data.urgencia}
                            variant="outlined"
                            InputProps={{ inputComponent: PhoneMask }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={data.tem_problema}
                                    onChange={e => setData({ ...data, tem_problema: e.target.checked })}
                                    name="tem_problema"
                                />
                            }
                            label="Tem algum problema de saúde?"
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            fullWidth
                            label="Qual?"
                            name="problema_saude"
                            onChange={e => setData({ ...data, problema_saude: e.target.value })}
                            value={data.problema_saude}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Escola"
                            name="escola"
                            onChange={e => setData({ ...data, escola: e.target.value })}
                            value={data.escola}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Período"
                            name="periodo"
                            onChange={e => setData({ ...data, periodo: e.target.value })}
                            value={data.periodo}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Observação"
                            name="obs"
                            onChange={e => setData({ ...data, obs: e.target.value })}
                            value={data.obs}
                            variant="outlined"
                            multiline
                            minRows={3}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <div className="flex flex-1 justify-end">
                    <Button style={{ backgroundColor: 'limegreen' }} variant="contained" onClick={onSave}>
                        Salvar
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}
