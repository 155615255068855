import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField } from '@material-ui/core';
import React from 'react';
import { Auxilio } from './AuxlioTransporte';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
/* @ts-ignore */
import MaskedInput from 'react-text-mask';

function PhoneMask(props: any) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref: any) => inputRef(ref ? ref.inputElement : null)}
            mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
        />
    );
}
const CPFMask = (props: any) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref: any) => inputRef(ref ? ref.inputElement : null)}
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
        />
    );
};

interface AuxilioEditProps {
    data: Auxilio;
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    onChange: (data: Auxilio) => void;
}

export default function AuxilioEdit({ data, open, onClose, onSave, onChange, ...props }: AuxilioEditProps) {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
            <DialogTitle>Editar Auxílio</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <h4>Dados Pessoais</h4>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={data.nome}
                            onChange={e => onChange({ ...data, nome: e.target.value })}
                            label="Nome"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                            <KeyboardDatePicker
                                value={moment(data.nascimento, 'DD/MM/YYYY').toDate()}
                                onChange={e => onChange({ ...data, nascimento: moment(e).format('DD/MM/YYYY') })}
                                label="Data de Nascimento"
                                fullWidth
                                inputVariant="outlined"
                                disableFuture
                                format="dd/MM/yyyy"
                                mask="__/__/____"
                                error={moment(data.nascimento, 'DD/MM/YYYY').isAfter(moment())}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={data.cpf}
                            onChange={e => onChange({ ...data, cpf: e.target.value })}
                            label="CPF"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                inputComponent: CPFMask,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={data.rg}
                            onChange={e => onChange({ ...data, rg: e.target.value })}
                            label="RG"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={data.endereco}
                            onChange={e => onChange({ ...data, endereco: e.target.value })}
                            label="Endereço"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={data.email}
                            onChange={e => onChange({ ...data, email: e.target.value })}
                            label="E-mail"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={data.telefone}
                            onChange={e => onChange({ ...data, telefone: e.target.value })}
                            label="Telefone"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                inputComponent: PhoneMask,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={data.celular}
                            onChange={e => onChange({ ...data, celular: e.target.value })}
                            label="Celular"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                inputComponent: PhoneMask,
                            }}
                        />
                    </Grid>
                    <Divider />
                    <Grid item xs={12}>
                        <h4>Dados da Instituição</h4>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={data.instituicao}
                            onChange={e => onChange({ ...data, instituicao: e.target.value })}
                            label="Instituição"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={data.curso}
                            onChange={e => onChange({ ...data, curso: e.target.value })}
                            label="Curso"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={data.rm}
                            onChange={e => onChange({ ...data, rm: e.target.value })}
                            label="RM/RA"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={data.enderecoInstituicao}
                            onChange={e => onChange({ ...data, enderecoInstituicao: e.target.value })}
                            label="Endereço da Instituição"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained" color="secondary">
                    Cancelar
                </Button>
                <Button onClick={onSave} variant="contained" color="primary">
                    Adicionar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
