import React, { useState } from 'react';
import { Box, Card, Grid } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import ServicosLocal from './ServicosLocal';
import { CalendarToday, Settings } from '@material-ui/icons';

export default function IndexAgendamento() {
    const [modo, setModo] = useState(1);
    const history = useHistory();
    const params = useParams();

    let counters = [
        {
            color: 'dodgerblue',
            icon: <Settings fontSize="large" />,
            name: 'Serviços',
            count: 0,
            id: 1,
            addText: 'Adicionar novo Serviço',
            active: true,
            action: () => setModo(1),
        },
        {
            color: 'forestgreen',
            icon: <CalendarToday fontSize="large" />,
            name: 'Agenda',
            count: -1,
            id: 3,
            active: true,
            action: () => history.push(`/agendamento/calendario/${params.mode}/${params.local}`),
        },
    ];

    return (
        <div className="app-wrapper">
            <Grid container spacing={3}>
                {counters.map(item => (
                    <Grid
                        key={item.id}
                        xs={6}
                        item
                        className={`col-4 rounded-top`}
                        style={{ backgroundColor: item.id === modo ? '#FF751888' : '' }}>
                        <Card
                            onClick={item.action}
                            style={{ cursor: 'pointer', background: item.active ? item.color : 'grey' }}>
                            <Box display="flex" alignItems="center" p={3} color="white">
                                <Box mr={3}>{item.icon}</Box>
                                {item.count > -1 ? (
                                    <Box display="flex" flexDirection="column" width="100%">
                                        <h1>{item.count}</h1>
                                        <p>{item.name}</p>
                                    </Box>
                                ) : (
                                    <Box display="flex" width="100%">
                                        <h1>{item.name}</h1>
                                    </Box>
                                )}
                            </Box>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Box mt={3}>
                <ServicosLocal />
            </Box>
        </div>
    );
}
