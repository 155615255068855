import React, { useCallback, useEffect } from 'react';
import axios_instance from '../services/auth/jwt/config';

interface MainContextType {
    loadDashboard: () => void;
    dashboard: any;
    getData: (link: LinkOptions) => Promise<any>;
}

let MainContext = React.createContext<MainContextType>({
    loadDashboard: () => {},
    dashboard: false,
    getData: async () => {},
});

interface ContextProviderProps {
    children: React.ReactNode;
}

export function useMainContext() {
    return React.useContext(MainContext);
}

type LinkOptions = 'dashboard';

let links = {
    dashboard: 'ouvidoria/dashboard',
};

export default function ContextProvider({ children, ...props }: ContextProviderProps) {
    const [dashboard, setDashboard] = React.useState<any>(false);

    const loadDashboard = useCallback(async () => {
        try {
            let response = await axios_instance.get(links.dashboard);
            setDashboard(response.data);
        } catch (error) {}
    }, []);

    useEffect(() => {
        loadDashboard();
    }, [loadDashboard]);

    const getData = useCallback(async (link: LinkOptions) => {
        try {
            let response = await axios_instance.get<{ success: boolean; data: any }>(links[link]);
            if (response?.data?.success) {
                setDashboard(response.data);
                return response.data;
            }
        } catch (error) {}
    }, []);

    let value = { loadDashboard, dashboard, getData };

    return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
}
